<template>
  <div class="vi-switcher">
    <input
      :checked="isChecked"
      :name="label"
      type="checkbox"
      @click="onToggle"
    />
    <i class="vi-switcher--icon" />
    <span class="vi-switcher--label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    name: {},
    onUpdateToggle: {}
  },
  data() {
    return {
      isChecked: true
    };
  },
  beforeMount() {
    this.isChecked = this.value;
  },
  methods: {
    onToggle(e) {
      this.onUpdateToggle({
        value: e.target.checked,
        label: e.target.name,
        name: this.name || null
      });
    }
  }
};
</script>

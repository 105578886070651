var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vi-main-wrapper--inner"},[_c('div',{staticClass:"vi-gratuity"},[_c('div',{staticClass:"vi-gratuity--amount"},[_c('div',{staticClass:"vi-gratuity--amount-price"},[(
            _vm.selectedPaymentVariant && _vm.selectedPaymentVariant === 'deposit'
          )?_c('span',[_vm._v("\n          "+_vm._s(_vm._f("priceFormat")(_vm.deposit,_vm.getPriceFormat, _vm.getChosenServices))+"\n        ")]):_c('span',[_vm._v("\n          "+_vm._s(_vm._f("priceFormat")(_vm.gratuity.selected.type === "percent"
              ? _vm.getTotalServicesPrice +
                (_vm.getTotalServicesPrice / 100) * _vm.gratuity.selected.value
              : (_vm.getTotalServicesPrice + Number(_vm.gratuity.selected.value)),_vm.getPriceFormat, _vm.getChosenServices))+"\n        ")])]),_c('div',{staticClass:"vi-gratuity--amount-label"},[(_vm.gratuity.selected.value > 0)?_c('span',[_vm._v("\n          "+_vm._s(_vm._f("priceFormat")(_vm.getTotalServicesPrice,_vm.getPriceFormat, _vm.getChosenServices))+_vm._s(_vm.gratuity.selected.value > 0
              ? (" + " + (_vm.printPriceGratuity(
                  _vm.gratuity.selected.value,
                  _vm.getTotalServicesPrice,
                  _vm.gratuity.selected.type,
                  _vm.getPriceFormat
                )) + " " + (_vm.$t("gratuity")))
              : "")+"\n        ")]):_c('span',[_vm._v(_vm._s(_vm.$t("amount_due")))])])]),(_vm.isLoading)?_c('div',{staticClass:"vi-loader-static"},[_c('b-spinner',{staticClass:"vi-spinner",attrs:{"variant":"success"}})],1):_c('div',[_c('div',{staticClass:"vi-gratuity--method"},[_c('b-container',[_c('payment-method',{attrs:{"data":_vm.paymentMethod ? _vm.paymentMethod : null},on:{"open":_vm.showAddCardModal}})],1)],1),(_vm.selectedPaymentVariant && _vm.selectedPaymentVariant === 'full')?_c('div',{staticClass:"vi-gratuity--variants"}):_vm._e()]),_c('b-container',[_c('div',{staticClass:"vi-appointment-controls",class:_vm.controlsOverlay ? 'inside-controls' : ''},[(!_vm.isLoading)?_c('div',{staticClass:"vi-appointment-controls--fix-width"},[_c('div',{staticClass:"row justify-content-center"},[(_vm.paymentMethod)?_c('div',{staticClass:"col-md-12"},[_c('vi-button',{attrs:{"vi-full":""},on:{"click":_vm.openConfirmPaymentModal}},[_vm._v("\n                "+_vm._s(_vm.$t("confirm_payment"))+"\n              ")])],1):_c('div',{staticClass:"col-md-12"},[_c('vi-button',{attrs:{"vi-full":""},on:{"click":_vm.showAddCardModal}},[_vm._v("\n                "+_vm._s(_vm.$t("add_card"))+"\n              ")])],1)]),(_vm.paymentMethod)?_c('div',{staticClass:"text-center vi-text-primary"},[(_vm.selectedPaymentVariant === 'deposit')?_c('p',{staticClass:"pt-3 mb-0 fz-12"},[_vm._v("\n              "+_vm._s(_vm.$t("i_authorize_you_to_charge_deposit_to_my_card_ending", {
                  deposit: _vm.depositPriceFormat,
                  card: _vm.paymentMethod.last4
                }))+"\n            ")]):_c('p',{staticClass:"pt-3 mb-0 fz-12"},[_vm._v("\n              "+_vm._s(_vm.$t("i_authorize_you_to_charge_deposit_to_my_card_ending", {
                  deposit:
                    _vm.gratuity.selected.type === "percent"
                      ? _vm.getTotalServicesPrice +
                        (_vm.getTotalServicesPrice / 100) *
                          _vm.gratuity.selected.value
                      : (_vm.getTotalServicesPrice +
                          Number(_vm.gratuity.selected.value))
                        | _vm.priceFormat(_vm.getPriceFormat, _vm.getChosenServices),
                  card: _vm.paymentMethod.last4
                }))+"\n            ")])]):_vm._e()]):_vm._e()])])],1),_c('Modal',{attrs:{"id":_vm.idModalAddCard,"title":_vm.titleModalAddCard}},[_c('create-payment-method',{on:{"success":_vm.successfullyAddedMethod}})],1),_c('Modal',{attrs:{"id":_vm.idModalIframeRedirect,"title":''},on:{"close":_vm.closeConfirmPaymentModal}},[_c('iframe',{ref:"iframeSDK",attrs:{"src":_vm.SDKRedirect,"frameborder":"0","width":"100%","height":"100%"}})]),_c('modal-confirm',{attrs:{"id":_vm.idModalGratuity}},[_c('div',{staticClass:"vi-confirm-message"},[_c('div',{staticClass:"vi-confirm-message--title"},[_c('h4',{staticClass:"fw-bold"},[_vm._v(_vm._s(_vm.$t("add_custom_gratuity")))])]),_c('div',{staticClass:"vi-customer-gratuity"},[_c('span',{staticClass:"vi-form-label"},[_vm._v("\n          "+_vm._s(!_vm.gratuity.custom.isPercent
              ? _vm.$t("custom_percent")
              : _vm.$t("custom_amout"))+"\n        ")]),_c('div',{staticClass:"vi-form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gratuity.custom.value),expression:"gratuity.custom.value"}],staticClass:"vi-form-control",attrs:{"placeholder":!_vm.gratuity.custom.isPercent ? '0%' : '$0.00',"type":"text"},domProps:{"value":(_vm.gratuity.custom.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.gratuity.custom, "value", $event.target.value)}}})]),_c('div',{staticClass:"vi-customer-gratuity--switcher"},[_c('label',{staticClass:"vi-switcher vi-switcher-which",attrs:{"for":"percentPrice"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gratuity.custom.isPercent),expression:"gratuity.custom.isPercent"}],attrs:{"id":"percentPrice","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.gratuity.custom.isPercent)?_vm._i(_vm.gratuity.custom.isPercent,null)>-1:(_vm.gratuity.custom.isPercent)},on:{"change":function($event){var $$a=_vm.gratuity.custom.isPercent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.gratuity.custom, "isPercent", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.gratuity.custom, "isPercent", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.gratuity.custom, "isPercent", $$c)}}}}),_c('span',{staticClass:"vi-switcher--label first-variant"},[_vm._v("%")]),_c('span',{staticClass:"vi-switcher--icon"}),_c('span',{staticClass:"vi-switcher--label second-variant"},[_vm._v("$")])])]),_c('vi-button',{attrs:{"vi-class":'mb-2',"vi-full":true,"vi-disable":!_vm.gratuity.custom.value},on:{"click":function($event){return _vm.selectGratuity({
              id: 'custom',
              type: !_vm.gratuity.custom.isPercent ? 'percent' : 'amount',
              value: _vm.gratuity.custom.value
            })}}},[_vm._v(_vm._s(_vm.$t("add"))+"\n        ")]),_c('vi-button',{attrs:{"vi-variant":'light',"vi-full":true},on:{"click":function($event){return _vm.$bvModal.hide(_vm.idModalGratuity)}}},[_vm._v(_vm._s(_vm.$t("close"))+"\n        ")])],1)])]),_c('modal-confirm',{attrs:{"id":_vm.idModalConfirmPayment}},[_c('div',{staticClass:"vi-confirm-message"},[(_vm.isPaymentLoading)?_c('div',{staticClass:"vi-loader-content"},[_c('b-spinner',{staticClass:"vi-spinner",attrs:{"variant":"success"}})],1):_vm._e(),_c('div',{staticClass:"vi-confirm-message--image"},[_c('img',{attrs:{"src":_vm.imageUrlWrapper(_vm.warning),"alt":"Warning Icon"}})]),_c('div',{staticClass:"vi-confirm-message--title"},[_c('h4',{staticClass:"fw-bold"},[_vm._v("\n          "+_vm._s(_vm.$t("are_you_sure_you_want_to_make_a_payment"))+"\n        ")])]),_c('div',{staticClass:"vi-confirm-message--btn"},[_c('vi-button',{attrs:{"vi-variant":'danger',"vi-class":'btn-w-75'},on:{"click":_vm.closeConfirmPaymentModal}},[_vm._v("\n          "+_vm._s(_vm.$t("no"))+"\n        ")]),_c('vi-button',{attrs:{"vi-class":'btn-w-75'},on:{"click":function($event){return _vm.sendPaymentMethod()}}},[_vm._v("\n          "+_vm._s(_vm.$t("yes"))+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-container>
    <div class="vi-timeout">
      <div class="text-center">
        <div class="vi-timeout--image mt-5 mb-4">
          <img
            v-if="salonIsPremium && salonLogo && !mainLogoIsHide"
            :src="salonLogo"
            alt="imgSalon"
          />
        </div>
        <h3 class="mb-5">{{ salonName }}</h3>
        <div class="mb-5">
          <img :src="imageUrlWrapper(imgTime)" alt="imgTime" />
        </div>
        <div class="mb-5">
          <p>{{ $t("timeout_text_1") }}</p>
          <p>{{ $t("timeout_text_2") }}</p>
        </div>
      </div>

      <div
        :class="controlsOverlay ? 'inside-controls' : ''"
        class="vi-appointment-controls"
      >
        <div class="vi-appointment-controls--fix-width">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <button
                type="button"
                class="vi-btn btn-primary btn-full"
                @click="redirect()"
              >
                {{ $t("try_again") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { imageWrapper, isKeepServices } from "../../utils/common/common.js";
import imgTime from "../../assets/img/time.svg";
import imgSalon from "../../assets/img/salon.svg";

export default {
  name: "Timeout",
  data() {
    return {
      imgTime,
      imgSalon
    };
  },
  computed: {
    ...mapGetters([
      "salonName",
      "salonLogo",
      "salonIsPremium",
      "mainLogoIsHide",
      "controlsOverlay"
    ])
  },
  methods: {
    ...mapMutations(["clearChosenServices"]),
    redirect() {
      this.$router.push({ name: "home" });
      if (isKeepServices() === 0) {
        this.clearChosenServices();
      }
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

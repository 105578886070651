<template>
  <div class="vi-fragment">
    <div v-if="ViIcon" class="vi-fragment__icon">
      <img :src="ViIcon" :alt="ViTitle ? ViTitle : 'Icon Alert'" />
    </div>
    <div class="vi-fragment__body">
      <h5 v-if="ViTitle" class="vi-fragment__title">{{ ViTitle }}</h5>
      <div
        v-if="ViContent"
        class="vi-fragment__content"
        v-html="ViContent"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViFragmentIconText",
  props: {
    ViIcon: {
      type: String,
      default: null
    },
    ViTitle: {
      type: String,
      default: null
    },
    ViContent: {
      type: String,
      default: null
    }
  }
};
</script>
<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <Authorization />
    </div>
  </b-container>
</template>

<script>
import Authorization from "../../components/Authorization/Authorization";

export default {
  name: "Auth",
  components: {
    Authorization
  }
};
</script>
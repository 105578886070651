var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vi-card-accordion"},[_c('div',{staticClass:"vi-card-accordion--heading",class:_vm.isShow ? null : 'collapsed',attrs:{"aria-expanded":_vm.isShow ? 'true' : 'false',"aria-controls":"collapse-4"},on:{"click":function($event){return _vm.onToggle(_vm.id)}}},[_c('h6',{staticClass:"title-heading"},[_vm._v(_vm._s(_vm.title))]),_c('i',{staticClass:"ic-vi-arr-bottom",class:_vm.isShow ? 'collapsed' : ''})]),_c('div',{staticClass:"vi-card-accordion--body"},[_c('b-collapse',{attrs:{"id":"collapse-4"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},_vm._l((_vm.services),function(service){return _c('div',{key:service.id,ref:("service" + (service.id)),refInFor:true,staticClass:"vi-card-accordion--item",class:service.booking === 'bookable_phone'
            ? 'vi-card-accordion-disabled'
            : ''},[(service.serviceType === 'multiple')?_c('div',[_c('div',{staticClass:"vi-card-accordion--item-inner",class:service.isShowVariants ? null : 'collapsed',attrs:{"aria-expanded":service.isShowVariants ? 'true' : 'false',"aria-controls":"collapse-4"}},[_c('div',{staticClass:"vi-card-accordion--item-inner--toggle",on:{"click":function($event){return _vm.onToggleVariants(service.service_id)}}}),_c('div',{staticClass:"vi-card-accordion--item-caption-btn",class:_vm.getChosenServices.find(function (item) { return _vm.getIdVariant(item.variants[0].id, service.variants); }
                )
                  ? 'active'
                  : ''},[_c('span',{class:service.isShowVariants
                    ? 'vi-btn-arrow rotate-up'
                    : 'vi-btn-arrow'})]),_c('div',{staticClass:"vi-card-accordion--item-caption"},[_c('div',{staticClass:"vi-card-accordion--item-caption--head"},[_c('h5',[_vm._v(_vm._s(service.title))]),_c('p',{staticClass:"vi-price"},[_vm._v("\n                  "+_vm._s(_vm._f("filteredSmallPrice")(_vm.$t("from"),
                        service.variants,
                        service.variants[0].price_formatted,
                        service.booking,
                        _vm.getPriceFormat
                      ))+"\n                ")])]),_c('div',{staticClass:"vi-card-accordion--item-caption--body"},[_c('div',[_c('p',{staticClass:"vi-card-accordion--item-caption--time"},[_vm._v("\n                    "+_vm._s(service.variants[0].duration)+"\n                    "+_vm._s(_vm.$t("minute_short"))+" -\n                    "+_vm._s(service.variants[service.variants.length - 1].duration)+"\n                    "+_vm._s(_vm.$t("minute_short"))+"\n                  ")]),(service.description)?_c('span',{staticClass:"vi-card-accordion--item-caption--more",on:{"click":function($event){_vm.showDetails(Object.assign({}, service,
                        {price: _vm.methodSmallPrice(
                          _vm.$t('from'),
                          service.variants,
                          service.variants[0]
                            ? service.variants[0].price_formatted
                            : '',
                          service.booking,
                          _vm.getPriceFormat
                        ),
                        time: ((service.variants[0]
                            ? service.variants[0].duration
                            : '') + " " + (_vm.$t('minute_short')) + " - " + (service.variants[service.variants.length - 1]
                            .duration) + " " + (_vm.$t('minute_short')))}))}}},[_vm._v(_vm._s(_vm.$t("show_details")))]):_vm._e()]),(service.booking === 'bookable_phone')?_c('span',{staticClass:"light-label"},[_vm._v(_vm._s(_vm.$t("book_by_phone_only")))]):_vm._e()])])]),_c('b-collapse',{attrs:{"id":"collapse-4"},model:{value:(service.isShowVariants),callback:function ($$v) {_vm.$set(service, "isShowVariants", $$v)},expression:"service.isShowVariants"}},_vm._l((service.variants),function(variant){return _c('div',{key:variant.id,ref:("variant" + (variant.id)),refInFor:true,staticClass:"vi-card-accordion--item"},[_c('div',{staticClass:"vi-card-accordion--item-inner",on:{"click":function($event){return _vm.selectVariant(service, variant, _vm.title, _vm.getChosenServices)}}},[_c('div',{staticClass:"vi-card-accordion--item-caption-btn"},[(
                      _vm.getChosenServices.find(
                        function (item) { return item.variants[0].id === variant.id; }
                      )
                    )?_c('span',{staticClass:"vi-btn-add btn-add-line added"}):_c('span',{staticClass:"vi-btn-add btn-add-line"})]),_c('div',{staticClass:"vi-card-accordion--item-caption"},[_c('div',{staticClass:"vi-card-accordion--item-caption--head"},[_c('h5',[_vm._v(_vm._s(variant.caption))]),_c('span',{staticClass:"vi-price"},[_vm._v("\n                      "+_vm._s(variant.price_formatted)+"\n                    ")])]),_c('div',{staticClass:"vi-card-accordion--item-caption--body"},[_c('p',{staticClass:"vi-card-accordion--item-caption--time"},[_vm._v("\n                      "+_vm._s(variant.duration)+" "+_vm._s(_vm.$t("minute_short"))+"\n                    ")]),(service.booking === 'bookable_phone')?_c('span',{staticClass:"light-label"},[_vm._v(_vm._s(_vm.$t("book_by_phone_only")))]):_vm._e()])])])])}),0)],1):_c('div',{staticClass:"vi-card-accordion--item-inner",class:service.booking === 'bookable_phone'
              ? 'vi-card-accordion-brake-event'
              : ''},[_c('div',{staticClass:"vi-card-accordion--item-inner--toggle",on:{"click":function($event){return _vm.selectService(service, _vm.title, _vm.getChosenServices)}}}),_c('div',{staticClass:"vi-card-accordion--item-caption-btn"},[(
                _vm.getChosenServices.find(
                  function (item) { return item.id === service.id &&
                    item.variants[0].id === service.variants[0].id &&
                    !item.package_id; }
                )
              )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})]),_c('div',{staticClass:"vi-card-accordion--item-caption"},[_c('div',{staticClass:"vi-card-accordion--item-caption--head"},[_c('h5',[_vm._v(_vm._s(service.title))]),_c('span',{staticClass:"vi-price"},[_vm._v("\n                "+_vm._s(service.variants[0]
                    ? service.variants[0].price_formatted
                    : "")+"\n              ")])]),_c('div',{staticClass:"vi-card-accordion--item-caption--body"},[_c('div',[_c('p',{staticClass:"vi-card-accordion--item-caption--time"},[_vm._v("\n                  "+_vm._s(service.variants[0] ? service.variants[0].duration : "")+"\n                  "+_vm._s(_vm.$t("minute_short"))+" |\n                  "+_vm._s(service.group_title)+"\n                ")]),(service.description || service.service.image)?_c('span',{staticClass:"vi-card-accordion--item-caption--more",on:{"click":function($event){_vm.showDetails(Object.assign({}, service,
                      {price: service.variants[0]
                        ? service.variants[0].price_formatted
                        : '',
                      time: ((service.variants[0]
                          ? service.variants[0].duration
                          : '') + " " + (_vm.$t('minute_short')))}))}}},[_vm._v(_vm._s(_vm.$t("show_details")))]):_vm._e()]),(service.booking === 'bookable_phone')?_c('span',{staticClass:"light-label"},[_vm._v(_vm._s(_vm.$t("book_by_phone_only")))]):_vm._e()])])])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
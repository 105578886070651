<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div class="vi-returning-client">
        <div v-if="loading" class="vi-loader-static">
          <b-spinner variant="success" class="vi-spinner" />
        </div>
        <div v-else class="vi-returning-client--inner">
          <h4 class="vi-returning-client--caption">
            {{ $t("upcoming_appointment") }}
          </h4>
          <div
            v-if="
              !getLastServices.future ||
              (getLastServices.future.packages.length === 0 &&
                getLastServices.future.services.length === 0)
            "
            class="vi-main-placeholder"
          >
            <div class="vi-main-placeholder--content">
              <p>{{ $t("you_have_no_upcoming_appointment") }}</p>
            </div>
            <div class="vi-main-placeholder--btn">
              <div class="text-center">
                <button
                  class="vi-btn btn-primary"
                  @click="redirect('services')"
                >
                  {{ $t("new_booking") }}
                </button>
              </div>
            </div>
          </div>
          <div v-else class="vi-appointments-list">
            <Appointment
              :data="getLastServices.future.services"
              :packages="getLastServices.future.packages"
              :cancel="onClickCancel"
              is-future
            />
          </div>

          <div>
            <h4 class="vi-returning-client--caption">
              {{ $t("past_appointment") }}
            </h4>
            <div
              v-if="
                !getLastServices.past ||
                (getLastServices.past.packages.length === 0 &&
                  getLastServices.past.services.length === 0)
              "
              class="vi-main-placeholder"
            >
              <div class="vi-main-placeholder--content">
                <p>{{ $t("you_have_no_past_appointment") }}</p>
              </div>
            </div>
            <div v-else class="vi-appointments-list">
              <Appointment
                :data="getLastServices.past.services"
                :packages="getLastServices.past.packages"
                :re-book="reBook"
              />
            </div>
          </div>
        </div>

        <div
          :class="controlsOverlay ? 'inside-controls' : ''"
          class="vi-appointment-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row">
              <div class="col-6">
                <button
                  class="vi-btn btn-primary-outline btn-full"
                  @click="redirect('your-appointments')"
                >
                  {{ $t("all_appointments") }}
                </button>
              </div>
              <div class="col-6">
                <button
                  class="vi-btn btn-primary btn-full"
                  @click="redirect('services')"
                >
                  {{ $t("new_booking") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Modals  -->
    <ModalConfirm :id="idModal">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(error)" alt="Remove Icon" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>{{ $t("are_you_sure_you_want_to_cancel_the_booking") }}</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <vi-button class="btn-w-75" @click="$bvModal.hide(idModal)">
            {{ $t("no") }}
          </vi-button>
          <vi-button
            :vi-loader="isLoading"
            vi-variant="danger"
            class="btn-w-75"
            @click="cancelRequest"
          >
            {{ $t("yes") }}
          </vi-button>
        </div>
      </div>
    </ModalConfirm>
    <ModalConfirm :id="idModalSuccess">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(success)" alt="Remove Icon" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>{{ $t("appointment_has_been_cancelled") }}</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <button
            class="vi-btn btn-primary btn-w-75"
            @click="$bvModal.hide(idModalSuccess)"
          >
            {{ $t("ok") }}
          </button>
        </div>
      </div>
    </ModalConfirm>
    <ModalSpecialInfo :id="idModalSpecialInfo" :content="specialInfo" />
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgLink from "../../assets/img/salon.svg";
import error from "../../assets/img/error.svg";
import success from "../../assets/img/error.svg";
import { apiUrl, imageWrapper, getToken } from "../../utils/common/common.js";
import Appointment from "../../components/Appointment/Appointment";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import ModalSpecialInfo from "../../components/Modals/ModalSpecialInfo";
import ViButton from "../../components/common/ViButton";

export default {
  components: { ViButton, Appointment, ModalConfirm, ModalSpecialInfo },
  data() {
    return {
      canceledItemId: null,
      isLoading: false,
      idModal: "confirmRemovePopup",
      idModalSuccess: "confirmSuccessPopup",
      idModalSpecialInfo: "specialInfoPopup",
      img: imgLink,
      error,
      success,
      value: ""
    };
  },
  computed: {
    ...mapGetters([
      "loading",
      "salonId",
      "getCustomer",
      "salonName",
      "isSpecialInfo",
      "specialInfo",
      "authHeaderReady",
      "controlsOverlay"
    ]),
    getLastServices() {
      let newService = {
        future: {
          packages: [],
          services: []
        },
        past: {
          packages: [],
          services: []
        }
      };

      const services = this.$store.getters.getLastServices;

      // Future services
      const packagesFutureFiltered = services.future.filter(
        (i) => i.package_id
      );

      let packagesFutureId = [
        ...new Set(packagesFutureFiltered.map((i) => i.package_id))
      ];
      const packagesFuture = packagesFutureId.map((packageId) => {
        const foundPackage = packagesFutureFiltered.find(
          (i) => i.package_id === packageId
        );

        return {
          id: packageId,
          title: foundPackage && foundPackage.package_title,
          total_price: foundPackage && foundPackage.total_price,
          total_duration: foundPackage && foundPackage.total_duration,
          services: [
            ...packagesFutureFiltered.filter((i) => i.package_id === packageId)
          ]
        };
      });
      newService.future.packages = [...packagesFuture];
      newService.future.services = [...services.future];

      // Past services
      const packagesPastFiltered = services.past.filter((i) => i.package_id);
      let packagesPastId = [
        ...new Set(packagesPastFiltered.map((i) => i.package_id))
      ];
      const packagesPast = packagesPastId.map((packageId) => {
        const foundPackage = packagesPastFiltered.find(
          (i) => i.package_id === packageId
        );

        return {
          id: packageId,
          title: foundPackage && foundPackage.package_title,
          total_price: foundPackage && foundPackage.total_price,
          total_duration: foundPackage && foundPackage.total_duration,
          services: [
            ...packagesPastFiltered.filter((i) => i.package_id === packageId)
          ]
        };
      });

      newService.past.packages = [...packagesPast];
      newService.past.services = [...services.past];

      return newService;
    }
  },
  watch: {
    authHeaderReady(value, oldValue) {
      if (value && value !== oldValue) {
        this.loadServices();
      }
    }
  },
  created() {
    if (!getToken()) {
      this.redirect("home");
    } else {
      localStorage.setItem("returning_client", "1");
    }
  },
  mounted() {
    this.loadServices();

    // this.setLoginFromPath("returning-client");
    if (
      this.isSpecialInfo &&
      this.isSpecialInfo !== 0 &&
      this.specialInfo.length > 0
    ) {
      this.$bvModal.show(this.idModalSpecialInfo);
    }
  },
  methods: {
    ...mapMutations([
      "setChosenReturningServices",
      "setChosenReturningPackage",
      "setLoginFromPath"
    ]),
    debug(event) {
      console.log("Returning Client", event);
    },
    loader(value) {
      this.isLoading = value;
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    async loadServices() {
      if (this.salonId && this.authHeaderReady) {
        await this.$store.dispatch("loadCustomerLastVisit", {
          salon: this.salonId
        });
      }
    },
    choosePackage(array) {
      array.forEach((packageItem) => {
        packageItem.services.forEach((service) =>
          this.setChosenReturningPackage(service)
        );
      });
    },
    reBook() {
      this.setChosenReturningServices(this.getLastServices.past.services);
      this.choosePackage(this.getLastServices.past.packages);
      this.redirect("staff");
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    onClickCancel(id) {
      this.$bvModal.show(this.idModal);
      this.canceledItemId = id;
    },
    async cancelRequest() {
      await this.loader(true);

      return new Promise(async (resolve, reject) => {
        const id = this.canceledItemId;
        try {
          await this.axios.post(
            `${apiUrl("booking/cancel-appointment")}/${this.salonId}`,
            { id }
          );
          await this.removeItem(this.canceledItemId);
          await this.$bvModal.hide(this.idModal);
          await this.$bvModal.show(this.idModalSuccess);
          await this.loader(false);
          resolve();
        } catch (e) {
          await this.loader(false);
          reject(e);
        }
      });
    },
    removeItem(id) {
      this.getLastServices.future.services =
        this.getLastServices.future.services.map((item) => {
          if (item.appt_group_id === id) {
            return {
              ...item,
              cancel_possibility: false,
              status: this.$t("cancelled")
            };
          }
        });
      this.getLastServices.future.packages =
        this.getLastServices.future.packages.map((item) => {
          if (item.appt_group_id === id) {
            return {
              ...item,
              cancel_possibility: false,
              status: this.$t("cancelled")
            };
          }
        });
    }
  }
};
</script>

<template>
  <div
    :class="
      salonIsPremium ||
      (previewInformation.settings && !!previewInformation.settings.is_premium)
        ? 'vi-filters-top'
        : ''
    "
    class="row vi-filters"
  >
    <div v-if="!getServiceError" class="col-6 vi-filters--item">
      <div
        class="vi-filters--btn"
        @click="!widgetOptions.previewVersion && openServicesFilters()"
      >
        <i
          :class="getFilteredServices.length > 0 ? 'filtered' : ''"
          class="ic-vi-options"
        />
        <span class="vi-filters--btn-text">
          {{ $t("filter_by_service") }}
        </span>
      </div>
    </div>
    <div v-if="!getServiceError" class="col-6 vi-filters--item">
      <div
        class="vi-filters--btn"
        @click="!widgetOptions.previewVersion && openEmployeesFilters()"
      >
        <i
          :class="getFilteredEmployees.length > 0 ? 'filtered' : ''"
          class="ic-vi-options"
        />
        <span class="vi-filters--btn-text">{{ $t("filter_by_staff") }}</span>
      </div>
    </div>

    <Modal :id="idModal" :title="titleFilters">
      <div v-if="typeFilters === 'services'" class="vi-filters-inner">
        <div class="row justify-content-center vi-filters-inner--header">
          <div class="col-6">
            <button
              type="button"
              class="vi-btn btn-info btn-full"
              @click="resetService"
            >
              {{ $t("clear") }}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="vi-btn btn-primary btn-full"
              @click="applyService"
            >
              {{ $t("apply") }}
            </button>
          </div>
        </div>
        <div class="vi-filters-inner--list">
          <div
            v-for="(item, index) in getServices"
            v-if="index !== 0"
            :key="index + item.group_title"
            class="vi-filters-inner--list-item"
          >
            <label class="vi-checkbox-default">
              <input
                :value="item"
                v-model="localFilteredServices"
                type="checkbox"
              />
              <span class="vi-checkbox-default--title">{{
                item.group_title
              }}</span>
              <span class="vi-checkbox-default--icon" />
            </label>
          </div>
        </div>
      </div>
      <div v-if="typeFilters === 'employees'" class="vi-filters-inner">
        <div class="row justify-content-center vi-filters-inner--header">
          <div class="col-6">
            <button
              type="button"
              class="vi-btn btn-info btn-full"
              @click="resetEmployees"
            >
              {{ $t("clear") }}
            </button>
          </div>
          <div class="col-6">
            <button
              type="button"
              class="vi-btn btn-primary btn-full"
              @click="applyEmployees"
            >
              {{ $t("apply") }}
            </button>
          </div>
        </div>
        <div class="vi-filters-inner--list">
          <div
            v-for="(item, index) in employees"
            :key="index"
            class="vi-filters-inner--list-item"
          >
            <label class="vi-checkbox-default">
              <input
                :value="item"
                v-model="localFilteredEmployees"
                type="checkbox"
              />
              <span class="vi-checkbox-default--title">
                {{ item.first_name }} {{ item.last_name }} <br />
                <span class="position">{{ item.type }}</span>
              </span>
              <span class="vi-checkbox-default--icon" />
            </label>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ServiceError from "@/components/ServiceError/ServiceError.vue";
import Modal from "@/components/Modal/Modal.vue";

export default {
  components: {
    Modal,
    ServiceError
  },
  props: ["employees"],
  data() {
    return {
      isShowFilters: false,
      idModal: "filterPopup",
      typeFilters: "",
      titleFilters: "",

      localFilteredServices: [],
      localFilteredEmployees: []
    };
  },
  computed: {
    ...mapGetters([
      "getServices",
      "widgetOptions",
      "getServiceError",
      "getFilteredServices",
      "salonIsPremium",
      "getFilteredEmployees",
      "previewInformation"
    ])
  },
  watch: {
    employees: {
      deep: true,
      handler(newEmployees) {
        if (newEmployees) {
          if (this.$route.query.employee) {
            this.filterEmployeesByUrl(newEmployees, this.$route.query.employee);
          }
        }
      }
    },
    getFilteredServices: {
      deep: true,
      handler(newGetFilteredServices) {
        if (newGetFilteredServices) {
          this.localFilteredServices = newGetFilteredServices;
        }
      }
    },
    getFilteredEmployees: {
      deep: true,
      handler(newGetFilteredEmployees) {
        if (newGetFilteredEmployees) {
          this.localFilteredEmployees = newGetFilteredEmployees;
        }
      }
    }
  },
  created() {
    this.localFilteredServices = this.getFilteredServices;
    this.localFilteredEmployees = this.getFilteredEmployees;
  },
  mounted() {
    if (this.$route.query.employee) {
      this.filterEmployeesByUrl(this.employees, this.$route.query.employee);
    }
  },
  methods: {
    ...mapMutations([
      "setFilteredServices",
      "setFilteredEmployees",
      "resetFilterServices",
      "resetFilterEmployees"
    ]),
    debug(event) {
      console.log("Filters buttons", event);
    },
    openServicesFilters() {
      this.$bvModal.show(this.idModal);
      this.typeFilters = "services";
      this.titleFilters = this.$t("filter_by_service");
    },
    openEmployeesFilters() {
      this.$bvModal.show(this.idModal);
      this.typeFilters = "employees";
      this.titleFilters = this.$t("filter_by_staff");
    },

    // Service Filter
    applyService() {
      this.setFilteredServices(this.localFilteredServices);
      this.$bvModal.hide(this.idModal);
    },
    resetService() {
      this.localFilteredServices = [];
      this.resetFilterServices();
      this.$bvModal.hide(this.idModal);
    },

    // Employees filter
    applyEmployees() {
      this.setFilteredEmployees(this.localFilteredEmployees);
      this.$bvModal.hide(this.idModal);
    },
    resetEmployees() {
      this.localFilteredEmployees = [];
      this.resetFilterEmployees();
      this.$bvModal.hide(this.idModal);
    },

    filterEmployeesByUrl(employees, id) {
      let num = Number(id);
      this.setFilteredEmployees(employees.filter((item) => item.id === num));
    }
  }
};
</script>

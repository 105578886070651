<template>
  <div class="vi-main-wrapper--inner">
    <b-container>
      <div
        :class="salonIsPremium ? 'vi-filters-top' : ''"
        class="row vi-filters text-wrap"
      >
        <div class="col-6 vi-filters--item">
          <div
            :class="`${
              activeAppointmentTab === futureAppointmentTab && 'active'
            }`"
            class="vi-filters--btn"
            @click="onChangeTab(futureAppointmentTab)"
          >
            <span class="vi-filters--btn-text">{{
              $t("future_appointments")
            }}</span>
          </div>
        </div>
        <div class="col-6 vi-filters--item">
          <div
            :class="`${
              activeAppointmentTab === pastAppointmentTab && 'active'
            }`"
            class="vi-filters--btn"
            @click="onChangeTab(pastAppointmentTab)"
          >
            <span class="vi-filters--btn-text">{{
              $t("past_appointments")
            }}</span>
          </div>
        </div>
      </div>

      <div v-if="loading" class="vi-loader-static">
        <b-spinner variant="success" class="vi-spinner" />
      </div>

      <div v-else class="vi-appointments-list pt-3">
        <div
          v-if="appointmentsData && appointmentsData.length === 0"
          class="vi-main-placeholder"
        >
          <div class="vi-main-placeholder--content">
            <p v-if="activeAppointmentTab === 'future'">
              {{ $t("no_future_appointments") }}
            </p>
            <p v-else>{{ $t("no_past_appointments") }}</p>
          </div>
          <div
            v-if="activeAppointmentTab === 'future'"
            class="vi-main-placeholder--btn"
          >
            <div class="text-center">
              <button class="vi-btn btn-primary" @click="redirect('services')">
                {{ $t("new_booking") }}
              </button>
            </div>
          </div>
        </div>
        <Appointment
          v-for="(item, index) in appointmentsData"
          v-else
          :key="`appointment` + index"
          :is-future="activeAppointmentTab === futureAppointmentTab"
          :data="item.data"
          :packages="item.packages"
          :deposit="item.deposit ? item.deposit : ''"
          :refund="item.refund ? item.refund : ''"
          :cancel="onClickCancel"
          :total-formatted="item.total_formatted"
          :is-re-book="item.can_rebook"
          :re-book="reBook"
        />

        <div v-if="showLoadMore || loadingMore" class="text-center py-4">
          <b-spinner v-if="loadingMore" variant="success" class="vi-spinner" />
          <span v-else class="vi-link-primary" @click.prevent="loadMore()">{{
            $t("more")
          }}</span>
        </div>
      </div>

      <ModalConfirm :id="idModal">
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(error)" alt="Remove Icon" />
          </div>
          <div class="vi-confirm-message--title">
            <h4>{{ $t("are_you_sure_you_want_to_cancel_the_booking") }}</h4>
          </div>
          <div class="vi-confirm-message--btn">
            <vi-button class="btn-w-75" @click="$bvModal.hide(idModal)">
              {{ $t("no") }}
            </vi-button>
            <vi-button
              :vi-loader="isLoading"
              vi-variant="danger"
              class="btn-w-75"
              @click="cancelRequest"
            >
              {{ $t("yes") }}
            </vi-button>
          </div>
        </div>
      </ModalConfirm>
      <ModalConfirm :id="idModalSuccess">
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(success)" alt="Remove Icon" />
          </div>
          <div class="vi-confirm-message--title">
            <h4>{{ $t("appointment_has_been_cancelled") }}</h4>
          </div>
          <div class="vi-confirm-message--btn w-100">
            <button
              class="vi-btn btn-primary"
              @click="$bvModal.hide(idModalSuccess)"
            >
              {{ $t("ok") }}
            </button>
          </div>
        </div>
      </ModalConfirm>
    </b-container>
  </div>
</template>

<script>
import StepNavigation from "../../components/StepNavigation/StepNavigation";
import AppointmentList from "../../components/AppointmentList/AppointmentList";
import { mapGetters, mapMutations } from "vuex";
import Appointment from "../../components/Appointment/Appointment";
import {
  apiUrl,
  getCustomerData,
  imageWrapper
} from "../../utils/common/common";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import error from "../../assets/img/error.svg";
import success from "../../assets/img/success.svg";
import ViButton from "../../components/common/ViButton";

const FUTURE_APPOINTMENTS = "future";
const PAST_APPOINTMENTS = "past";

export default {
  components: {
    ViButton,
    StepNavigation,
    AppointmentList,
    ModalConfirm,
    Appointment
  },
  data() {
    return {
      isLoading: false,
      canceledItemId: null,
      idModal: "confirmRemovePopup",
      idModalSuccess: "confirmSuccessPopup",
      page: 1,
      error,
      success,
      appointmentsData: null,
      activeAppointmentTab: FUTURE_APPOINTMENTS
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "salonName",
      "loading",
      "loadingMore",
      "getCustomer",
      "getChosenServices",
      "getChosenServicesData",
      "authHeaderReady",
      "isPastAppointment",
      "salonIsPremium",
      "getFutureAppointmentsData",
      "getFutureAppointments",
      "getPastAppointments"
    ]),
    futureAppointmentTab() {
      return FUTURE_APPOINTMENTS;
    },
    pastAppointmentTab() {
      return PAST_APPOINTMENTS;
    },
    appointments() {
      return this.activeAppointmentTab === this.futureAppointmentTab
        ? this.getFutureAppointments
        : this.getPastAppointments;
    },
    showLoadMore() {
      return (
        this.appointments &&
        this.appointments.data &&
        this.appointments.last_page > this.page
      );
    }
  },
  watch: {
    authHeaderReady(value, oldValue) {
      if (value && value !== oldValue) {
        this.loadAppointments();
      }
    }
  },
  created() {
    if (!getCustomerData()) {
      this.redirect("auth");
    }
  },
  mounted() {
    if (this.isPastAppointment) {
      this.activeAppointmentTab = PAST_APPOINTMENTS;
    }
    this.loadAppointments();
    this.clearChosenServices();
  },
  methods: {
    ...mapMutations([
      "setChosenReturningPackage",
      "setChosenReturningServices",
      "setLoginFromPath",
      "clearChosenServices",
      "setChosenEmployees",
      "setChosenEmployeesByVariant",
      "setFutureAppointmentData",
      "setTabPastAppointment"
    ]),
    debug(event) {
      console.log(event);
    },
    loader(value) {
      this.isLoading = value;
    },
    goToPrev() {
      this.$router.push({ name: "home" });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    onChangeTab(tab) {
      this.page = 1;
      this.activeAppointmentTab = tab;
      this.loadAppointments();
      this.setTabPastAppointment(false);
    },
    async loadAppointments() {
      if (this.salonId && this.authHeaderReady) {
        let appointments = await this.$store.dispatch("loadAppointments", {
          tab: this.activeAppointmentTab,
          page: this.page,
          salon: this.salonId
        });

        appointments = appointments.map((appointment) => {
          return {
            ...appointment,
            packages: [...new Set(appointment.data.map((i) => i.package_id))]
              .map((packageId) => {
                if (packageId) {
                  return {
                    id: packageId,
                    title: appointment.data.filter(
                      (i) => i.package_id === packageId
                    )[0].package_title,
                    total_price: appointment.data.filter(
                      (i) => i.package_id === packageId
                    )[0].total_price,
                    total_duration: appointment.data.filter(
                      (i) => i.package_id === packageId
                    )[0].total_duration,
                    services: [
                      ...appointment.data.filter(
                        (i) => i.package_id === packageId
                      )
                    ]
                  };
                }
              })
              .filter((i) => i)
          };
        });

        this.appointmentsData = appointments;
      }
    },
    loadMore() {
      this.page++;
      this.loadAppointments();
    },
    onClickCancel(id) {
      this.$bvModal.show(this.idModal);
      this.canceledItemId = id;
    },
    async cancelRequest() {
      await this.loader(true);

      return new Promise(async (resolve, reject) => {
        const id = this.canceledItemId;
        try {
          await this.axios.post(
            `${apiUrl("booking/cancel-appointment")}/${this.salonId}`,
            { id }
          );
          await this.removeItem(this.canceledItemId);
          await this.$bvModal.hide(this.idModal);
          await this.$bvModal.show(this.idModalSuccess);
          await this.loader(false);
          resolve();
        } catch (e) {
          await this.loader(false);
          reject(e);
        }
      });
    },
    removeItem(id) {
      let updatedData = this.appointmentsData.map((item) => {
        return {
          ...item,
          data: item.data.map((i) => {
            if (i.appt_group_id === id) {
              return {
                ...i,
                cancel_possibility: false,
                status: this.$t("cancelled")
              };
            } else {
              return {
                ...i
              };
            }
          })
        };
      });
      this.appointmentsData = updatedData;
      this.setFutureAppointmentData(updatedData);
    },

    checkAloneEmployees(arr, arrData, setArr, setArrVariant) {
      let flag = false;
      let flagVariant = false;

      arr.map((item) => {
        if (item.employees.length === 1) {
          flag = true;
          arrData.map((k) => {
            if (item.variants[0].id === k.variant_id) {
              flagVariant = true;
            }
          });
          if (flagVariant) {
            setArrVariant({
              variant_id: item.variants[0].id,
              employee_id: item.employees[0].id,
              employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
            });
          } else {
            setArr({
              service_id: item.id,
              employee_id: item.employees[0].id,
              employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
            });
          }
        } else {
          this.$router.push({ name: "staff" });
        }
        if (flag) {
          if (arr.every((i) => i.employees.length === 1)) {
            arrData.map((k) => {
              if (item.variants[0].id === k.variant_id) {
                flagVariant = true;
              }
            });
            if (flagVariant) {
              setArrVariant({
                variant_id: item.variants[0].id,
                employee_id: item.employees[0].id,
                employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
              });
            } else {
              setArr({
                service_id: item.id,
                employee_id: item.employees[0].id,
                employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
              });
            }
            this.$router.push({ name: "slots" });
          } else {
            this.$router.push({ name: "staff" });
          }
        }
      });
    },

    choosePackage(array) {
      array.forEach((packageItem) => {
        packageItem.services.forEach((service) =>
          this.setChosenReturningPackage(service)
        );
      });
    },

    async reBook(services, packages) {
      await this.setLoginFromPath("your-appointments");
      await this.setTabPastAppointment(true);
      await this.setChosenReturningServices(
        services.filter((i) => !i.package_id)
      );
      await this.choosePackage(packages);
      await this.checkAloneEmployees(
        this.getChosenServices,
        this.getChosenServicesData,
        this.setChosenEmployees,
        this.setChosenEmployeesByVariant
      );
    }
  }
};
</script>

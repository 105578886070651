<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <BasketCard
        v-if="timer.status"
        :date="getTimeOrder.date | filterDate"
        :time="getTimeOrder.time"
        :salon="salonName"
        :address="salonAddress"
      />

      <div v-if="isLoggedOut" class="vi-main-placeholder pb-4">
        <div class="vi-main-placeholder--image mb-4">
          <img :src="imageUrlWrapper(icon)" alt="Success" />
        </div>
        <div class="vi-main-placeholder--content">
          <h3>{{ $t("wl_have_been_logged_out") }}</h3>
        </div>
      </div>

      <form
        :class="!isLoggedOut ? 'mt-4' : ''"
        class="login-form"
        @submit.prevent="loginUser"
      >
        <form-input
          v-model="values.email"
          :error="errors.email"
          :last-letter-red="'*'"
          :label="$t('your_email')"
          type="email"
          name="email"
          @validate="validate('email')"
        />
        <form-input
          v-model="values.password"
          :error="errors.password"
          :last-letter-red="'*'"
          :label="$t('password')"
          type="password"
          name="password"
          @validate="validatePassword('password')"
        />
        <vi-button
          :vi-type="'submit'"
          :vi-full="true"
          :vi-loader="loadingButton"
          :vi-class="'mt-2 mb-3'"
        >
          {{ $t("login") }}
        </vi-button>
        <vi-button
          :vi-full="true"
          :vi-variant="'outline'"
          :vi-class="'mb-4'"
          @click="goToPrev"
        >
          {{ $t("cancel") }}
        </vi-button>
        <div class="text-center vi-text-primary">{{ $t("or") }}</div>
        <div v-if="showGoogleLoginButton" class="pt-2">
          <GoogleLogin :btn-text="$t('login_with_google')" />
        </div>
        <div class="mt-4 mb-3 text-center vi-text-primary">
          <span class="vi-link-primary" @click="isShowModal">
            {{ $t("forgot") }}
          </span>
        </div>
        <div v-if="!isLoggedOut" class="mt-3 text-center vi-text-primary">
          {{ $t("first_time_there") }}
          <span class="vi-link-primary" @click="redirectToRegistration">{{
            $t("sign_up")
          }}</span>
        </div>
      </form>
    </div>

    <ForgotPasswordModal />
  </b-container>
</template>

<script>
import { object, string } from "yup";
import { mapGetters, mapMutations } from "vuex";
import StepNavigation from "@/components/StepNavigation/StepNavigation.vue";
import ForgotPasswordModal from "@/components/ForgotPasswordModal/ForgotPasswordModal.vue";
import FormInput from "@/components/FormInput/FormInput.vue";
import GoogleLogin from "@/components/GoogleLogin/GoogleLogin.vue";
import BasketCard from "@/components/BasketCard/BasketCard.vue";
import { apiUrl } from "@/utils/common/common";
import moment from "moment";
import "moment-duration-format";
import success from "../../assets/img/success.svg";
import error from "../../assets/img/error.svg";
import { getCustomerData, imageWrapper } from "../../utils/common/common";
import ViButton from "../../components/common/ViButton";

export default {
  name: "Login",
  components: {
    ViButton,
    "form-input": FormInput,
    StepNavigation,
    BasketCard,
    GoogleLogin,
    ForgotPasswordModal
  },
  filters: {
    filterDate(value) {
      return moment(value).format("DD MMM, YYYY");
    }
  },
  props: {
    isBasket: {
      default: false
    },
    isReturnClient: {
      default: false
    }
  },
  data() {
    return {
      icon: success,
      forgotPasswordModal: false,
      values: {
        email: "",
        password: ""
      },
      errors: {
        email: "",
        password: ""
      },
      hasPrevious: true,
      isLoggedOut: false
    };
  },
  computed: {
    ...mapGetters([
      "loadingButton",
      "salonId",
      "salonName",
      "salonAddress",
      "getTimeOrder",
      "timer",
      "pastUrl",
      "getCustomer",
      "loginFromPath",
      "showGoogleLoginButton"
    ]),
    loginFormSchema() {
      return object().shape({
        email: string()
          .email(this.$t("email_must_be_valid"))
          .required(this.$t("this_field_is_required")),
        password: string()
          .min(
            8,
            this.$t("password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required"))
      });
    }
  },
  watch: {
    getCustomer: {
      deep: true,
      handler(newGetCustomer) {
        if (newGetCustomer) {
          if (this.loginFromPath) {
            this.redirect(this.loginFromPath);
          } else {
            this.redirect("home");
          }
        }
      }
    },
    $route(to) {
      this.routerCheck(to.path);
    }
  },
  created() {
    if (getCustomerData()) {
      if (this.loginFromPath) {
        this.redirect(this.loginFromPath);
      } else {
        this.redirect("home");
      }
    }
  },
  mounted() {
    this.routerCheck(this.$route.path);
  },
  methods: {
    ...mapMutations(["setLoginFromPath", "setShowPasswordForgotModal"]),
    isShowModal() {
      this.setShowPasswordForgotModal(true);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    loginUser() {
      this.loginFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          await this.login();

          if (this.loginFromPath === "services") {
            this.redirect("home");
          } else if (this.loginFromPath) {
            this.redirect(this.loginFromPath);
          } else {
            this.redirect("home");
          }

          this.$store.commit("setLoggedCustomer", true);
          this.setLoginFromPath("");
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    login() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("isLoadingButton");

        try {
          const data = {
            email: this.values.email,
            password: this.values.password
          };

          let response = await this.axios.post(
            apiUrl(`booking/login/${this.salonId}`),
            data
          );

          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "expires_in",
            Math.round(+new Date() / 1000 + response.data.expires_in)
          );

          this.axios.defaults.headers.common = {
            ...this.axios.defaults.headers.common,
            Authorization: `Bearer ${response.data.access_token}`
          };

          this.$store.commit("authHeaderReady", true);

          this.$store.dispatch("loadCustomer", {
            salon: this.salonId
          });

          this.$store.commit("isLoadedButton");

          resolve();
        } catch (e) {
          this.$store.commit("isLoadedButton");

          if (e.response) {
            if (e.response.data.error) {
              this.errors.email = e.response.data.error;
            } else if (e.response.data.email) {
              this.errors.email = e.response.data.email[0];
            }
          }

          reject(e);
        }
      });
    },
    validate(field) {
      this.loginFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    validatePassword(value) {
      if (this.values[value].length === 0) {
        this.errors[value] = this.$t("this_field_is_required");
      } else if (
        this.values[value].length > 0 &&
        this.values[value].length < 9
      ) {
        this.errors[value] = this.$t(
          "password_must_be_at_least_characters_long",
          { num: 8 }
        );
      } else {
        return (this.errors[value] = "");
      }
    },
    goToPrev() {
      this.$router.push({
        name:
          this.loginFromPath === "returning-client"
            ? "home"
            : this.loginFromPath
            ? this.loginFromPath
            : this.pastUrl && this.pastUrl.name === "your-appointments"
            ? "home"
            : this.pastUrl && this.pastUrl.name === "data-preferences"
            ? "home"
            : this.pastUrl && this.pastUrl.name === "your-details"
            ? "home"
            : this.pastUrl && this.pastUrl.name === "returning-client"
            ? "home"
            : this.pastUrl && this.pastUrl.name === "thank-you"
            ? "home"
            : this.pastUrl
            ? this.pastUrl.name
            : "home"
      });
    },
    redirectToRegistration() {
      if (this.isReturnClient) {
        this.$router.push({
          name: "registration",
          params: { isReturnClient: true }
        });
        return;
      }
      this.$router.push({ name: "registration" });
    },
    routerCheck(path) {
      if (path === "/logged-out") {
        this.isLoggedOut = true;
      } else if (path === "/logged-out-now") {
        this.isLoggedOut = true;
        this.icon = error;
      }
    }
  }
};
</script>

<template>
  <div class="vi-section-default vi-auth text-center">
    <button
      class="vi-btn btn-primary btn-full mb-3"
      type="button"
      @click="redirect('registration')"
    >
      {{ $t("sign_up_with_email") }}
    </button>
    <GoogleLogin :btn-text="$t('continue_with_google')" />
    <div class="vi-text-primary pt-4">
      {{ $t("have_account") }}
      <span class="vi-link-primary" @click="redirect('login')">{{
        $t("login")
      }}</span>
    </div>
  </div>
</template>

<script>
import GoogleLogin from "@/components/GoogleLogin/GoogleLogin.vue";
import imgClock from "../../assets/img/clock.svg";
import { getCustomerData, imageWrapper } from "../../utils/common/common";
import imgPlus from "../../assets/img/plus-round.svg";
import { mapGetters } from "vuex";

export default {
  components: {
    GoogleLogin
  },
  data() {
    return {
      imgPlus,
      imgClock,
      showGoogleAccountCreate: false,
      showNonePremium: false
    };
  },
  computed: {
    ...mapGetters(["loading", "getCustomer", "loginFromPath"])
  },
  watch: {
    getCustomer: {
      deep: true,
      handler(newGetCustomer) {
        if (newGetCustomer) {
          if (this.loginFromPath) {
            this.redirect(this.loginFromPath);
          } else {
            this.redirect("home");
          }
        }
      }
    }
  },
  created() {
    if (getCustomerData()) {
      if (this.loginFromPath) {
        this.redirect(this.loginFromPath);
      } else {
        this.redirect("home");
      }
    }
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    redirect(url) {
      this.$router.push({ name: url });
    }
  }
};
</script>
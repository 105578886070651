<template>
  <div class="vi-card-accordion">
    <div
      :class="isShow ? null : 'collapsed'"
      :aria-expanded="isShow ? 'true' : 'false'"
      class="vi-card-accordion--heading"
      aria-controls="collapse-4"
    >
      <h6 class="title-heading">{{ title }}</h6>
      <i :class="isShow ? 'collapsed' : ''" class="ic-vi-arr-bottom" />
    </div>
    <div class="vi-card-accordion--body">
      <b-collapse id="collapse-4" v-model="isShow">
        <div
          v-for="(service, index) in services"
          :key="service.id"
          :ref="`service${service.id}`"
          class="vi-card-accordion--item"
        >
          <div class="vi-card-accordion--item-inner">
            <div class="vi-card-accordion--item-caption-btn">
              <span v-if="index === 1" class="vi-btn-add added" />
              <span v-else class="vi-btn-add" />
            </div>
            <div class="vi-card-accordion--item-caption">
              <div class="vi-card-accordion--item-caption--head">
                <h5>{{ service.title }}</h5>
                <span class="vi-price">
                  {{ service.price_formatted }}
                </span>
              </div>
              <div class="vi-card-accordion--item-caption--body">
                <p class="vi-card-accordion--item-caption--time">
                  {{ service.duration_formatted }} {{ $t("minute_short") }} |
                  {{ service.group_title }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id", "title", "isShow", "services"]
};
</script>

<template>
  <div>
    <form class="vi-form" @submit.prevent="newEmail">
      <p class="vi-text-secondary pt-3">
        {{ $t("change_email_text") }}
      </p>
      <form-input
        v-model="values.email"
        :error="errors.email"
        :last-letter-red="'*'"
        :label="$t('your_email')"
        type="email"
        name="email"
        @validate="validate('email')"
      />
      <button
        :class="loadingBtn ? 'btn-loader' : ''"
        :disabled="loadingBtn"
        class="vi-btn btn-primary btn-full mt-2"
        type="submit"
      >
        {{ $t("send_me_instructions") }}
        <b-spinner v-if="loadingBtn" small />
      </button>
    </form>

    <ModalConfirm :id="idModal">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(imgLink)" alt="success" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>{{ $t("change_email_instruction") }}</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <button class="vi-btn btn-primary" type="button" @click="closeModal">
            {{ $t("ok") }}
          </button>
        </div>
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput/FormInput.vue";
import * as Yup from "yup";
import imgLink from "../../assets/img/success.svg";
import { imageWrapper, apiUrl } from "@/utils/common/common";
import { mapGetters } from "vuex";
import { string } from "yup";
import { i18n } from "@/i18n/index";
import ModalConfirm from "../Modal/ModalConfirm";

const emailFormSchema = Yup.object().shape({
  email: string()
    .email(i18n.t("email_must_be_valid"))
    .required(i18n.t("this_field_is_required"))
});

export default {
  components: {
    ModalConfirm,
    "form-input": FormInput
  },
  props: ["initEmail"],
  data() {
    return {
      loadingBtn: false,
      idModal: "changedEmailModal",
      values: {
        email: ""
      },
      errors: {
        email: ""
      },
      imgLink
    };
  },
  computed: {
    ...mapGetters(["salonId"])
  },
  created() {
    this.values.email = this.initEmail;
  },
  methods: {
    newEmail() {
      emailFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.formValid = true;

          this.loadingBtn = true;

          try {
            const email = this.values.email;

            await this.axios.post(
              `${apiUrl("booking/new-email")}/${this.salonId}`,
              {
                email,
                url: location.href.split("#")[0]
              }
            );

            this.loadingBtn = false;
            this.$bvModal.show(this.idModal);

            localStorage.setItem("new-email", this.email);
          } catch (e) {
            if (e.response) {
              if (e.response.data.error) {
                this.errors.email = e.response.data.error;
              } else if (e.response.data.email) {
                this.errors.email = e.response.data.email[0];
              }
            }

            this.loadingBtn = false;
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validate(field) {
      emailFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    closeModal() {
      this.$bvModal.hide(this.idModal);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

<template>
  <b-modal
    :id="id"
    :title="title"
    scrollable
    centered
    hide-header-close
    no-close-on-backdrop
    no-enforce-focus
    hide-footer
    modal-class="vi-main-modal vi-b-modal"
    header-class="vi-b-modal--header"
    body-class="vi-b-modal--body"
    footer-class="vi-b-modal--footer"
  >
    <form :style="styleObject" class="vi-form" @submit.prevent="save">
      <p class="vi-text-secondary">
        {{ $t("wl_add_your_mobile_number") }}
      </p>
      <div class="vi-form--body">
        <div class="form-group">
          <span class="vi-form-label" data-end="*">{{
            $t("reg_customer_phone")
          }}</span>
          <vue-tel-input
            v-model="values.phone"
            :input-options="{ placeholder: $t('phone') }"
            :class="`vi-form-control ${
              !!errors.phoneIsNotValid && ' vi-form-control--error'
            }`"
            name="phone"
            @input="validatePhone"
          />
          <p
            v-if="!!errors.phoneIsNotValid"
            class="vi-form-control--text-error"
          >
            {{ errors.phoneIsNotValid }}
          </p>
        </div>

        <div v-if="enablePolicy > 0" class="pt-2 pb-2">
          <div class="vi-form-group">
            <Checkbox
              :checked="values.checkboxLeagalAge"
              :text="$t('confirm_age')"
              :class="`${
                !!errors.checkboxLeagalAge && ' vi-checkbox-default-error'
              }`"
              class="checkbox-secondary"
              @change="(val) => (values.checkboxLeagalAge = val)"
              @validate="validate('checkboxLeagalAge')"
            />
          </div>

          <span class="vi-link-primary mb-3" @click="openPrivacyPolice">{{
            $t("privacy_policy")
          }}</span>

          <div class="vi-form-group">
            <Checkbox
              :checked="checkboxEmailContact"
              :text="$t('i_agree_contact_by_email')"
              class="checkbox-secondary"
              @change="(val) => (checkboxEmailContact = val)"
            />
          </div>
          <div class="vi-form-group">
            <Checkbox
              v-if="marketSms"
              :checked="checkboxSmsContact"
              :text="$t('i_agree_contact_by_sms')"
              class="checkbox-secondary"
              @change="(val) => (checkboxSmsContact = val)"
            />
          </div>
          <div class="vi-form-group">
            <Checkbox
              v-if="medicalConsent"
              :checked="checkboxMedicalContact"
              :text="$t('i_agree_to_medical_related_data_to_be_stored')"
              class="checkbox-secondary"
              @change="(val) => (checkboxMedicalContact = val)"
            />
          </div>
        </div>
      </div>

      <div class="vi-form--footer">
        <button
          :class="
            loadingButton
              ? 'btn-loader'
              : values.phone.length < 4
              ? 'btn-disabled'
              : ''
          "
          :disabled="loadingButton"
          type="submit"
          class="vi-btn btn-primary btn-full"
        >
          {{ $t("save") }}

          <b-spinner v-if="loadingButton" small />
        </button>
      </div>
    </form>
    <Modal :id="idModalPolice" :title="titleModalPolice">
      <div class="vi-typography pt-2 pb-2">
        <p>{{ privacyPolicy }}</p>
      </div>
    </Modal>
  </b-modal>
</template>

<script>
var checkboxEmailContact;

import { mapGetters } from "vuex";
import Checkbox from "../Checkbox/Checkbox";
import Modal from "./Modal";
import { apiUrl } from "@/utils/common/common";
import { TheMask } from "vue-the-mask";
import { VueTelInput } from "vue-tel-input";
import { boolean, object, string } from "yup";
import { hexToRgb } from "../../utils/common/common";

export default {
  components: {
    Checkbox,
    TheMask,
    VueTelInput,
    Modal
  },
  props: ["id", "title"],
  data() {
    return {
      idModalPolice: "policeModalFromModalConfirmPhone",
      titleModalPolice: "Privacy Police",
      values: {
        phone: "",
        phoneIsNotValid: true,
        checkboxLeagalAge: false
      },
      errors: {
        phone: "",
        phoneIsNotValid: "",
        checkboxLeagalAge: ""
      },
      checkboxEmailContact: false,
      checkboxSmsContact: false,
      checkboxMedicalContact: false
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "loadingButton",
      "privacyPolicy",
      "enablePolicy",
      "marketEmails",
      "marketSms",
      "medicalConsent",
      "widgetOptions"
    ]),
    RegistrationFormSchema() {
      const params = {
        phone: string().required(this.$t("this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("phone_is_not_valid"))
      };

      if (this.enablePolicy > 0) {
        params.checkboxLeagalAge = boolean()
          .oneOf([true], this.$t("field_is_required"))
          .required();
      }

      return object().shape(params);
    },
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(
          this.widgetOptions.buttonBackground
        ),
        "--vi-main-primary-background": this.widgetOptions.buttonBackground,
        "--vi-main-primary-background-hover":
          this.widgetOptions.buttonBackgroundHover,
        "--vi-main-primary-color": this.widgetOptions.buttonColor
      };
    }
  },
  mounted() {
    this.titleModalPolice = this.$t("privacy_policy");
  },
  methods: {
    openPrivacyPolice() {
      this.$bvModal.show(this.idModalPolice);
    },
    registrationPhoneNumber() {
      return new Promise(async (resolve, reject) => {
        this.$store.commit("isLoadingButton");
        try {
          const data = {
            phone: this.values.phone,
            age: this.values.checkboxLeagalAge ? 1 : 0,
            market_email: this.checkboxEmailContact,
            market_sms: this.checkboxSmsContact,
            medical: this.checkboxMedicalContact
          };

          await this.axios.post(
            apiUrl(`booking/update-phone/${this.salonId}`),
            data
          );

          await this.$store.dispatch("loadCustomer", {
            salon: this.salonId
          });

          this.$store.commit("isLoadedButton");

          resolve();
        } catch (e) {
          this.$store.commit("isLoadedButton");

          reject(e);
        }
      });
    },
    save() {
      this.RegistrationFormSchema.validate(this.values, { abortEarly: false })
        .then(async () => {
          await this.registrationPhoneNumber();
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validate(field) {
      this.RegistrationFormSchema.validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    validatePhone(_number, obj) {
      if (obj.valid) {
        this.values.phone = obj.number;
      }

      if (String(_number).length > 2) {
        this.values.phoneIsNotValid = !obj.valid;
        this.validate("phoneIsNotValid");
      } else {
        this.values.phoneIsNotValid = obj.valid;
        this.validate("phoneIsNotValid");
      }
    }
  }
};
</script>
<style src="../../../node_modules/vue-tel-input/dist/vue-tel-input.css"></style>

<template>
  <b-container>
    <div class="vi-section-default">
      <form class="vi-form" @submit.prevent="editDetails">
        <form-input
          v-model="values.firstname"
          :error="errors.firstname"
          :last-letter-red="'*'"
          :label="$t('first_name')"
          type="text"
          name="firstname"
          @validate="validate('firstname')"
        />
        <form-input
          v-model="values.lastname"
          :error="errors.lastname"
          :last-letter-red="'*'"
          :label="$t('last_name')"
          type="text"
          name="lastname"
          @validate="validate('lastname')"
        />
        <div class="form-group">
          <span class="vi-form-label" data-end="*">{{
            $t("reg_customer_phone")
          }}</span>
          <vue-tel-input
            v-model="values.phone"
            :input-options="{ placeholder: $t('phone') }"
            :class="`vi-form-control ${
              !!errors.phoneIsNotValid && ' vi-form-control--error'
            }`"
            name="cellPhone"
            @input="validatePhone"
          ></vue-tel-input>
          <span
            v-if="!!errors.phoneIsNotValid"
            class="vi-form-control--text-error"
            >{{ errors.phoneIsNotValid }}</span
          >
        </div>
        <div class="pt-2">
          <button
            :class="loadingBtn ? 'btn-loader' : ''"
            :disabled="loadingBtn"
            type="submit"
            class="vi-btn btn-primary btn-full"
          >
            {{ $t("save") }}
            <b-spinner v-if="loadingBtn" small />
          </button>
        </div>
      </form>

      <hr class="mt-4" />

      <YourDetailsEmail v-if="getCustomer" :init-email="getCustomer.email" />

      <hr class="mt-4" />

      <YourDetailsPassword />

      <ModalConfirm :id="idModal">
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(imgLink)" alt="success" />
          </div>
          <div class="vi-confirm-message--title">
            <h4>{{ $t("details_have_been_updated") }}</h4>
          </div>
          <div class="vi-confirm-message--btn">
            <button
              class="vi-btn btn-primary"
              type="button"
              @click="closeModal"
            >
              {{ $t("ok") }}
            </button>
          </div>
        </div>
      </ModalConfirm>
    </div>
  </b-container>
</template>

<script>
import StepNavigation from "../StepNavigation/StepNavigation";
import FormInput from "@/components/FormInput/FormInput.vue";
import YourDetailsEmail from "@/components/YourDetailsEmail/YourDetailsEmail.vue";
import YourDetailsPassword from "@/components/YourDetailsPassword/YourDetailsPassword.vue";
import { object, string, email, boolean } from "yup";
import * as Yup from "yup";
import { VueTelInput } from "vue-tel-input";
import imgLink from "../../assets/img/success.svg";
import {
  imageWrapper,
  apiUrl,
  getCustomerData
} from "../../utils/common/common.js";
import { mapGetters } from "vuex";
import { i18n } from "../../i18n";
import ModalConfirm from "../Modal/ModalConfirm";

const DetailsFormSchema = object().shape({
  firstname: string().required(i18n.t("this_field_is_required")),
  lastname: string().required(i18n.t("this_field_is_required")),
  phone: string().required(i18n.t("this_field_is_required")),
  phoneIsNotValid: boolean().oneOf([false], i18n.t("phone_is_not_valid"))
});

export default {
  components: {
    YourDetailsPassword,
    YourDetailsEmail,
    StepNavigation,
    ModalConfirm,
    VueTelInput,
    "form-input": FormInput
  },
  props: {},
  data() {
    return {
      loadingBtn: false,
      idModal: "changedPersonalInfoModal",
      values: {
        firstname: "",
        lastname: "",
        phone: "",
        phoneIsNotValid: true,
        checkboxLeagalAge: false
      },
      errors: {
        firstname: "",
        lastname: "",
        phone: "",
        phoneIsNotValid: "",
        checkboxLeagalAge: ""
      },
      imgLink
    };
  },
  computed: {
    ...mapGetters(["getCustomer", "salonId", "salonName"]),
    passwordFormSchema() {
      return Yup.object().shape({
        password: Yup.string()
          .min(
            8,
            i18n.t("password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        newPassword: Yup.string()
          .min(
            8,
            i18n.t("new_password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("newPassword"), null],
          i18n.t("both_passwords_must_match")
        )
      });
    }
  },
  watch: {
    getCustomer(customer) {
      if (customer) {
        this.initCustomer();
      }
    }
  },
  mounted() {
    this.initCustomer();
  },
  methods: {
    initCustomer() {
      if (this.getCustomer) {
        this.values.firstname = this.getCustomer.first_name;
        this.values.lastname = this.getCustomer.last_name;
        this.values.phone = this.getCustomer.phone;
      }

      if (!getCustomerData()) {
        this.redirect("auth");
      }
    },
    editDetails() {
      DetailsFormSchema.validate(this.values, { abortEarly: false })
        .then(async () => {
          this.loadingBtn = true;

          try {
            const data = {
              first_name: this.values.firstname,
              last_name: this.values.lastname,
              phone: this.values.phone
            };

            await this.axios.post(
              apiUrl(`booking/customer-data/${this.salonId}`),
              data
            );

            await this.$store.dispatch("loadCustomer", { salon: this.salonId });

            localStorage.setItem(
              "customer",
              JSON.stringify({ ...getCustomerData(), ...data })
            );

            this.$bvModal.show(this.idModal);
            this.loadingBtn = false;
          } catch (e) {
            console.log(e);
            this.loadingBtn = false;
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validate(field) {
      DetailsFormSchema.validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    validatePhone(_number, obj) {
      if (obj.valid) {
        this.values.phone = obj.number;
      }

      this.values.phoneIsNotValid = !obj.valid;
      this.validate("phoneIsNotValid");
    },
    closeModal() {
      this.$bvModal.hide(this.idModal);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    redirect(url) {
      this.$router.push({ name: url });
    }
  }
};
</script>

<template>
  <div class="horizontal-calendar">
    <div class="horizontal-calendar--arrow">
      <div
        :class="property.addedWeek === 0 ? 'disable' : ''"
        class="horizontal-calendar--arrow-btn horizontal-calendar--prev"
        @click="prevWeek"
      >
        <i class="ic-vi-arr-line" />
      </div>
      <div
        class="horizontal-calendar--arrow-btn horizontal-calendar--next"
        @click="nextWeek"
      >
        <i class="ic-vi-arr-line-right" />
      </div>
    </div>
    <div v-if="days || days.length > 0" class="horizontal-calendar--days">
      <div
        v-for="day in days"
        :key="day.dateFormat"
        :class="
          day.isActive && day.isToday
            ? 'today active'
            : day.isToday
            ? 'today'
            : day.isActive
            ? 'active'
            : day.disabled
            ? 'disable'
            : ''
        "
        class="horizontal-calendar--days-item"
        @click="selectDate(day.dateFormat)"
      >
        <span class="horizontal-calendar--days-item--label">
          {{
            day.day === 0
              ? $t(`weekday_short_7`)
              : $t(`weekday_short_${day.day}`)
          }}
        </span>
        <span class="horizontal-calendar--days-item--number">{{
          day.number
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "HorizontalCalendar",
  filters: {
    dateConvert(date) {
      return moment(date).format("DD MMM, YYYY");
    }
  },
  props: {
    chosenDate: {},
    date: {},
    minDate: {}
  },
  data() {
    return {
      today: moment(),
      month: moment().format("MMM, YYYY"),
      property: {
        addedWeek: 0
      },
      days: []
    };
  },
  computed: {
    ...mapGetters(["startOfWeek"])
  },
  watch: {
    chosenDate: {
      deep: true,
      handler(newChosenDate) {
        if (newChosenDate) {
          this.selectDate(newChosenDate);
          if (moment(newChosenDate).week() >= moment(this.minDate).week()) {
            this.property.addedWeek =
              moment(newChosenDate).week() - moment(this.minDate).week();
            this.getDays(
              moment(newChosenDate).week() - moment(this.minDate).week()
            );
          }
        }
      }
    }
  },
  mounted() {
    this.getDays(moment(this.chosenDate).week() - moment(this.minDate).week());
  },
  methods: {
    getDays(week) {
      this.property.addedWeek = week;

      let weekStart = "";

      if (week || week > 0) {
        weekStart = moment(this.minDate)
          .clone()
          .startOf("week")
          .add(week, "week");
      } else {
        weekStart = moment(this.minDate).clone().startOf("week");
      }

      let days = [];

      let amount = Number(this.startOfWeek) === 6 ? 0 : 1;
      let begin = weekStart.isoWeekday(Number(this.startOfWeek));

      for (let i = 0; i < 7; i++) {
        begin.add(amount, "d");
      }

      for (let i = 0; i < 7; i++) {
        days.push({
          number: moment(weekStart).format("D"),
          day: Number(moment(weekStart).format("d")),
          dateFormat: moment(weekStart).format(),
          isToday:
            moment().format("YYYY-MM-DD") ===
            moment(weekStart).format("YYYY-MM-DD"),
          isActive: this.date === moment(weekStart).format("YYYY-MM-DD"),
          disabled: this.today.format() > moment(weekStart).format()
        });
        begin.add(1, "d");
      }
      this.days = days;
    },
    prevWeek() {
      if (this.property.addedWeek > 0) {
        this.getDays(this.property.addedWeek - 1);
      }
    },
    nextWeek() {
      this.getDays(this.property.addedWeek + 1);
    },
    selectDate(date) {
      this.$emit("change", moment(date).format());
      this.selectInner(date);
    },
    selectInner(date) {
      this.days = this.days.map((item) => {
        return {
          ...item,
          isActive: item.dateFormat === moment(date).format()
        };
      });
    }
  }
};
</script>

<template>
  <div class="vi-card-basket">
    <div class="vi-card-basket--header">
      <div class="vi-card-basket--header-date">
        <span> {{ date }}, {{ time }} </span>
      </div>
      <div class="vi-card-basket--header-time">
        <vi-timer />
      </div>
      <div class="vi-card-basket--header-address">
        <span>{{ salon }}, {{ address }}</span>
      </div>
    </div>
    <div v-if="!isLoggedCustomer" class="vi-card-basket--body">
      <div
        v-for="packageItem in packages ? packages : getStableServices.packages"
        :key="packageItem.id"
        class="vi-card-basket-package"
      >
        <span
          class="vi-card-basket-finish--info-remove"
          @click="
            removePackage({
              package_id: packageItem.id,
              total_price: packageItem.total_price,
              total_duration: packageItem.total_duration
            })
          "
          ><i class="ic-close"
        /></span>
        <div class="vi-card-basket-package__header">
          <span class="vi-card-basket-package__header-title">{{
            packageItem.title
          }}</span>
          <div class="vi-card-basket-package__header-prices">
            <span class="duration">{{
              packageItem.total_duration | timeFormat
            }}</span>
            <span class="price">{{
              packageItem.total_price | defaultPriceFormat(getPriceFormat)
            }}</span>
          </div>
        </div>
        <div class="vi-card-basket-package__body">
          <div
            v-for="service in packageItem.services"
            :key="service.id"
            class="vi-card-basket-finish"
          >
            <div class="vi-card-basket-finish--caption">
              <h3>{{ service.title }}</h3>
              <div class="prices">
                <span
                  v-if="
                    service.variants[0].custom_price &&
                    service.variants[0].custom_price !==
                      service.variants[0].price
                  "
                  class="old-price"
                  >{{ service.variants[0].price_formatted }}</span
                >
                <span class="price">{{
                  service.variants[0].custom_price
                    ? service.variants[0].custom_price
                    : service.variants[0].price
                      | defaultPriceFormat(getPriceFormat)
                }}</span>
              </div>
            </div>
            <div class="vi-card-basket-finish--info">
              <div>
                <span class="vi-card-basket-finish--info-employee">
                  {{ service.employee.first_name }}
                  {{ service.employee.last_name }}
                </span>
                <span class="vi-card-basket-finish--info-time">
                  <i class="ic-vi-clock"></i>{{ service.time_formatted }} ({{
                    service.variants[0].custom_duration
                      ? service.variants[0].custom_duration
                      : service.variants[0].duration | timeFormat
                  }})
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="item in data ? data : getStableServices.services"
        v-if="!item.package_id"
        :key="item.id"
        class="vi-card-basket--body-item"
      >
        <div class="d-flex justify-content-between">
          <h3 class="title">{{ item.title }}</h3>
          <span class="price">{{ item.variants[0].price_formatted }}</span>
        </div>
        <span class="title-group">{{ item.group_title }}</span>
        <div class="d-flex justify-content-between">
          <p class="time">
            {{ item.variants[0].duration | timeFormat }} |
            {{ item.employee.first_name }} {{ item.employee.last_name }}
          </p>
          <span class="vi-card-basket--delete" @click="removeService(item)">
            <i class="ic-close"></i>
          </span>
        </div>
      </div>
    </div>
    <div v-if="!isLoggedCustomer" class="vi-card-basket--footer">
      <span class="vi-card-basket--footer-label">{{ $t("total_to_pay") }}</span>
      <span class="vi-card-basket--footer-price">
        {{
          getTotalServicesPrice | priceFormat(getPriceFormat, getChosenServices)
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { priceFormatter, timeFormatter } from "../../utils/common/common";
import { mapGetters, mapMutations } from "vuex";
import ViTimer from "../common/ViTimer";

export default {
  name: "BasketCard",
  components: { ViTimer },
  filters: {
    defaultPriceFormat(amount, format) {
      return priceFormatter(amount, format);
    },
    priceFormat: function (data, format, containsArray) {
      let arrHide = containsArray.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      let arrBookable = containsArray.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (arrHide.length > 0 && arrBookable.length > 0) {
        return `from ${priceFormatter(data, format)}`;
      } else if (arrHide.length === 0) {
        return priceFormatter(data, format);
      } else {
        return arrHide[0].variants[0].price_formatted;
      }
    },
    timeFormat(data) {
      return timeFormatter(data);
    }
  },
  props: ["date", "time", "salon", "address", "data", "packages"],
  data() {
    return {
      basket: [],
      savedServices: []
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "isLoggedCustomer",
      "getTotalServicesPrice",
      "getChosenServices",
      "getPriceFormat"
    ]),
    getStableServices() {
      let arr1 = this.$store.getters.getChosenServices;
      let arr2 = this.$store.getters.getSlotsFromBasket;
      let arr3 = this.$store.getters.getServicesBasket;
      let arr4 = this.$store.getters.getSalonEmployees;

      let services = [];

      let getEmployeeId = (serviceId) => {
        const service = arr3.filter((time) => time.variant_id === serviceId)[0];
        return service ? service.employee_id : 0;
      };

      if (arr1.length !== 0) {
        const fitToLocalBasket = [];
        const fitToLocalServices = [];

        services = arr1.map((item) => {
          const employee = arr4.filter(
            (i) => i.id === getEmployeeId(item.variants[0].id)
          )[0];
          const basket = arr3.filter(
            (i) => i.variant_id === item.variants[0].id
          )[0];
          const time = arr2.filter(
            (i) => i.variant_id === item.variants[0].id
          )[0];

          if (employee && basket && time) {
            if (item.package_id) {
              fitToLocalBasket.push({
                employee_id: employee.id,
                package_id: item.package_id,
                service_id: item.id,
                variant_id: item.variants[0].id,
                basket_id: basket.basket_id
              });
              fitToLocalServices.push({
                id: item.id,
                package_id: item.package_id,
                titleGroup: item.titleGroup,
                total_price: item.total_price,
                total_duration: item.total_duration,
                title: item.title,
                price: item.variants[0].price,
                custom_price: item.variants[0].custom_price
                  ? item.variants[0].custom_price
                  : null,
                custom_duration: item.variants[0].custom_duration
                  ? item.variants[0].custom_duration
                  : null,
                priceFormatted: item.variants[0].price_formatted,
                time: time.time,
                time_formatted: time.time_formatted,
                duration: item.variants[0].duration,
                employee: `${employee.first_name} ${employee.last_name}`
              });
            } else {
              fitToLocalBasket.push({
                employee_id: employee.id,
                service_id: item.id,
                variant_id: item.variants[0].id,
                basket_id: basket.basket_id
              });
              fitToLocalServices.push({
                id: item.id,
                title: item.title,
                price: item.variants[0].price,
                priceFormatted: item.variants[0].price_formatted,
                time: time.time,
                time_formatted: time.time_formatted,
                duration: item.variants[0].duration,
                employee: employee
                  ? `${employee.first_name} ${employee.last_name}`
                  : ""
              });
            }
          }

          return {
            ...item,
            employee: employee,
            time: arr2.filter((i) => i.variant_id === item.variants[0].id)[0]
              .time,
            time_formatted: arr2.filter(
              (i) => i.variant_id === item.variants[0].id
            )[0].time_formatted,
            basket_id: arr3.filter((time) => time.service_id === item.id)[0]
              .basket_id,
            token: arr3.filter((time) => time.service_id === item.id)[0].token
          };
        });

        this.addToLocalBasket(fitToLocalBasket);
        this.saveToLocalServices(fitToLocalServices);

        let packagesFiltered = services.filter((i) => i.package_id);
        let packagesId = [
          ...new Set(packagesFiltered.map((i) => i.package_id))
        ];
        const packages = packagesId.map((packageId) => {
          return {
            id: packageId,
            title: services.filter((i) => i.package_id === packageId)[0]
              .titleGroup,
            total_price: services.filter((i) => i.package_id === packageId)[0]
              .total_price,
            total_duration: services.filter(
              (i) => i.package_id === packageId
            )[0].total_duration,
            services: [...services.filter((i) => i.package_id === packageId)]
          };
        });

        return { services, packages };
      } else {
        this.runTimer(false);
        this.redirect("services");
        return { services: [], packages: [] };
      }
    }
  },
  methods: {
    ...mapMutations([
      "runTimer",
      "removeChosenServices",
      "removeChosenPackage",
      "removeFromBasketServicesByVariant"
    ]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    clearBasket() {
      this.basket = [];
      this.savedServices = [];
    },
    removeService(item) {
      if (item.serviceType === "single") {
        this.removeChosenServices(item);
      } else {
        this.removeFromBasketServicesByVariant(item);
      }
      this.$store.dispatch("removeServicesFromBasket", {
        salon: this.salonId,
        token: item.token,
        basket_id: item.basket_id
      });
    },

    removePackage(payload) {
      this.removeChosenPackage(payload);
    },

    addToLocalBasket(data) {
      this.basket = [...data];
    },
    saveToLocalServices(data) {
      this.savedServices = [...data];
    }
  }
};
</script>

<template>
  <b-modal
    :id="id"
    :no-close-on-backdrop="noCloseOnBackdrop"
    hide-header
    hide-footer
    centered
    no-enforce-focus
    modal-class="vi-main-modal vi-b-modal-confirm"
    body-class="vi-b-modal-confirm--body"
    @hide="handlerHide"
  >
    <div :style="styleObject">
      <slot />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { hexToRgb } from "../../utils/common/common";

export default {
  props: ["id", "title", "model", "noCloseOnBackdrop"],
  computed: {
    ...mapGetters(["widgetOptions"]),
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(
          this.widgetOptions.buttonBackground
        ),
        "--vi-main-primary-background": this.widgetOptions.buttonBackground,
        "--vi-main-primary-background-hover":
          this.widgetOptions.buttonBackgroundHover,
        "--vi-main-primary-color": this.widgetOptions.buttonColor
      };
    }
  },
  methods: {
    handlerHide() {
      this.$emit("hide");
    }
  }
};
</script>

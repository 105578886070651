import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// let dsgdsg = (lang) => {
//   console.log(lang)
//   return lang
// }

const locale = document.querySelector("vue-widget").getAttribute("lang");

const messages = {
  [locale]: require(`./${locale}.json`)
};

export const i18n = new VueI18n({ locale, messages });

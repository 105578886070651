<template>
  <div
    :class="
      ViType === 'small'
        ? 'vi-spinner-sm'
        : ViType === 'large'
        ? 'vi-spinner-lg'
        : ''
    "
    class="vi-spinner"
  />
</template>

<script>
export default {
  name: "ViSpinner",
  props: {
    ViType: {
      type: String,
      required: false,
      default: "medium"
    }
  }
};
</script>
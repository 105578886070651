<template>
  <label :for="id" class="vi-checkbox-default">
    <input
      :id="id"
      :checked="checked"
      type="checkbox"
      @change="$emit('change', $event.target.checked)"
    />
    <i class="vi-checkbox-default--icon" />
    <span class="vi-checkbox-default--title" v-html="text" />
  </label>
</template>

<script>
export default {
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    id: {
      type: [String, Number]
    },
    text: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

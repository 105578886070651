<template>
  <div class="vi-card-appointment-primary">
    <div class="vi-card-appointment-primary--body">
      <div
        v-for="packageItem in packages"
        :key="packageItem.id"
        class="vi-card-appointment-package"
      >
        <div class="vi-card-appointment-package__header">
          <span class="vi-card-appointment-package__header-title">{{
            packageItem.title
          }}</span>
          <div class="vi-card-appointment-package__header-prices">
            <span class="duration">{{
              packageItem.total_duration | timeFormat
            }}</span>
            <span class="price">{{
              packageItem.total_price | defaultPriceFormat(getPriceFormat)
            }}</span>
          </div>
        </div>
        <div class="vi-card-appointment-package__body">
          <div
            v-for="item in packageItem.services"
            :key="item.variant_id"
            class="vi-card-appointment-primary--item"
          >
            <div class="d-flex justify-content-between mb-1">
              <span class="vi-card-appointment-primary--date">{{
                item.date
              }}</span>
              <span
                v-if="isFuture"
                class="vi-card-appointment-primary--price"
                >{{ item.price_formatted }}</span
              >
            </div>
            <h4 class="vi-card-appointment-primary--title">
              {{ item.service_title }}
            </h4>
            <span class="vi-card-appointment-primary--title-group">{{
              item.group_title
            }}</span>
            <p class="vi-card-appointment-primary--time">
              {{ item.duration | timeFormat }} | {{ item.employee }}
            </p>
            <span
              v-if="!cancelPossibility(data) && isFuture"
              class="vi-card-appointment-primary--status"
              >{{ item.status }}</span
            >
          </div>
        </div>
      </div>
      <div
        v-for="(item, i) in data"
        v-if="!item.package_id"
        :key="item.variant_id"
        class="vi-card-appointment-primary--item"
      >
        <div class="d-flex justify-content-between mb-1">
          <span class="vi-card-appointment-primary--date">{{ item.date }}</span>
          <span v-if="isFuture" class="vi-card-appointment-primary--price">{{
            item.price_formatted
          }}</span>
        </div>
        <div
          v-if="isFuture && item.deposit"
          class="d-flex justify-content-between mb-1"
        >
          <span class="vi-card-appointment-primary--date">{{
            $t("deposit")
          }}</span>
          <span class="vi-card-appointment-primary--price">{{
            item.deposit
          }}</span>
        </div>
        <h4 class="vi-card-appointment-primary--title">
          {{ item.service_title }}
        </h4>
        <span class="vi-card-appointment-primary--title-group">{{
          item.group_title
        }}</span>
        <p class="vi-card-appointment-primary--time">
          {{ item.duration | timeFormat }} | {{ item.employee }}
        </p>
        <span
          v-if="!cancelPossibility(data) && isFuture"
          class="vi-card-appointment-primary--status"
          >{{ item.status }}</span
        >

        <div
          v-if="deposit && i === data.length - 1"
          class="vi-card-appointment-primary--deposit"
        >
          <span class="vi-card-appointment-primary--deposit-title">{{
            $t("deposit")
          }}</span>
          <span class="vi-card-appointment-primary--deposit-price">{{
            deposit
          }}</span>
        </div>
        <div
          v-if="refund && i === data.length - 1"
          class="vi-card-appointment-primary--deposit"
        >
          <span class="vi-card-appointment-primary--deposit-title">{{
            $t("refund")
          }}</span>
          <span class="vi-card-appointment-primary--deposit-price">{{
            refund
          }}</span>
        </div>
      </div>
    </div>
    <div v-if="isFuture">
      <div
        v-if="cancelPossibility(data)"
        class="vi-card-appointment-primary--option"
      >
        <button
          class="vi-btn btn-danger-outline btn-sm"
          @click="cancel(data[0].appt_group_id)"
        >
          {{ $t("cancel_appointment") }}
        </button>
      </div>
    </div>
    <div v-else>
      <div v-if="isReBook" class="vi-card-appointment-primary--option">
        <button
          class="vi-btn btn-primary-outline btn-sm"
          @click="reBook(data, packages)"
        >
          {{ $t("rebook") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import calendar from "../../assets/img/calendar.svg";
import {
  imageWrapper,
  priceFormatter,
  timeFormatter
} from "../../utils/common/common";

export default {
  filters: {
    priceFormat: function (data, format) {
      let price = 0;

      data.map((item) => {
        price += item.price;
      });

      return priceFormatter(price, format);
    },
    timeFormat: function (data) {
      return timeFormatter(data);
    },
    defaultPriceFormat(amount, format) {
      return priceFormatter(amount, format);
    }
  },
  props: {
    data: Array,
    packages: Array,
    isFuture: {
      type: Boolean,
      default: false
    },
    isReBook: {
      type: Boolean,
      default: true
    },
    totalFormatted: String,
    deposit: String,
    refund: String,
    reBook: Function,
    cancel: Function
  },
  data() {
    return {
      calendar
    };
  },
  computed: {
    ...mapGetters(["getPriceFormat"])
  },
  methods: {
    ...mapMutations(["setChosenReturningServices"]),
    debug(event) {
      console.log(event);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    cancelPossibility(data) {
      const even = (element) => element.cancel_possibility === true;
      return data.every(even);
    }
  }
};
</script>

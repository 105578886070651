<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <!--For one selected-->
      <div
        v-if="
          getStableChosenServices.length === 1 &&
          !getStableChosenServices[0].selectedEmployee
        "
        class="vi-employees-list"
      >
        <div
          class="vi-card-employee"
          @click="
            getChosenServicesData[0].employee_id !== 0
              ? chooseEmployee(
                  getChosenServices[0].id,
                  0,
                  $t('first_available_specialist')
                )
              : removeEmployee(getChosenServices[0].service_id, 0)
          "
        >
          <div class="vi-card-employee--inner">
            <div class="vi-card-employee--info">
              <div class="vi-card-employee--info-img">
                <img :src="imageUrlWrapper(firstImgLink)" alt="staff-avatar" />
              </div>
              <div class="vi-card-employee--info-wrap">
                <p class="name">{{ $t("first_available_specialist") }}</p>
                <span class="position">{{ $t("maximum_availability") }}</span>
              </div>
            </div>
            <div class="vi-card-employee--btn">
              <span
                v-if="getChosenServicesData[0].employee_id !== 0"
                class="vi-btn-add"
              />
              <span v-else class="vi-btn-add added" />
            </div>
          </div>
        </div>
        <div
          v-for="staff in getStableChosenServices[0].employees"
          :key="staff.id"
          class="vi-card-employee"
          @click="
            getChosenServicesData[0].employee_id !== staff.id
              ? chooseEmployee(
                  getChosenServices[0].id,
                  staff.id,
                  staff.first_name + ' ' + staff.last_name
                )
              : removeEmployee(getChosenServices[0].service_id, staff.id)
          "
        >
          <div class="vi-card-employee--inner">
            <div class="vi-card-employee--info">
              <div class="vi-card-employee--info-img">
                <img
                  v-if="staff.img === ''"
                  :src="imageUrlWrapper(firstImgLink)"
                  alt="staff-avatar"
                />
                <img v-else :src="`${staff.img}`" alt="staff-avatar" />
              </div>
              <div class="vi-card-employee--info-wrap">
                <p class="name">{{ staff.first_name }} {{ staff.last_name }}</p>
                <span class="position">{{
                  getSalonEmployeeTypes.find(
                    (x) => Number(x.id) === Number(staff.employee_type)
                  ).title
                }}</span>
              </div>
            </div>
            <div class="vi-card-employee--btn">
              <span
                v-if="getChosenServicesData[0].employee_id !== staff.id"
                class="vi-btn-add"
              />
              <span v-else class="vi-btn-add added" />
            </div>
          </div>
        </div>
      </div>

      <!--For several selected-->
      <div v-else class="vi-appointment">
        <div class="vi-appointments-list">
          <div
            v-for="packageItem in getStableChosenPackages"
            :key="packageItem.id"
            class="vi-group-package"
          >
            <div class="vi-group-package__header">
              <span class="vi-group-package__header-title">{{
                packageItem.title
              }}</span>
              <span
                class="vi-card-appointment--wrap-close"
                @click="
                  removePackage({
                    package_id: packageItem.id,
                    total_price: packageItem.total_price,
                    total_duration: packageItem.total_duration
                  })
                "
                ><i class="ic-close"
              /></span>
            </div>
            <div class="vi-group-package__body">
              <div
                v-for="item in packageItem.services"
                :key="item.id + item.variants[0].id"
                class="vi-card-appointment"
              >
                <div class="vi-card-appointment--wrap">
                  <h6 class="vi-card-appointment--wrap-title">
                    {{ item.title }}
                    <small v-if="item.variants[0].caption"
                      >({{ item.variants[0].caption }})</small
                    >
                  </h6>
                  <p class="vi-card-appointment--wrap-subtitle">
                    {{ item.group_title }}
                  </p>
                  <p
                    class="vi-card-appointment--wrap-form"
                    @click="
                      addSelectedEmployees(
                        item.id,
                        item.employees,
                        item.variants[0].id,
                        item.variants.length === 1 ? 'multiple' : 'single'
                      )
                    "
                  >
                    <span class="vi-card-appointment--wrap-form--input">
                      <span v-if="!item.selectedEmployee" class="placeholder">{{
                        $t("choose_staff")
                      }}</span>
                      <span v-else>{{ item.selectedEmployee }}</span>
                    </span>
                    <img
                      :src="imageUrlWrapper(arrowRightImg)"
                      alt="arrowRightImg"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="item in getStableChosenServices"
            v-if="!item.package_id"
            :key="item.id + item.variants[0].id"
            class="vi-card-appointment"
          >
            <div class="vi-card-appointment--wrap">
              <span
                class="vi-card-appointment--wrap-close"
                @click="removeServices(item)"
                ><i class="ic-close"
              /></span>

              <h6 class="vi-card-appointment--wrap-title">
                {{ item.title }}
                <small v-if="item.variants[0].caption"
                  >({{ item.variants[0].caption }})</small
                >
              </h6>
              <p class="vi-card-appointment--wrap-subtitle">
                {{ item.group_title }}
              </p>
              <p
                class="vi-card-appointment--wrap-form"
                @click="
                  addSelectedEmployees(
                    item.id,
                    item.employees,
                    item.variants[0].id,
                    item.variants.length === 1 ? 'multiple' : 'single'
                  )
                "
              >
                <span class="vi-card-appointment--wrap-form--input">
                  <span v-if="!item.selectedEmployee" class="placeholder">{{
                    $t("choose_staff")
                  }}</span>
                  <span v-else>{{ item.selectedEmployee }}</span>
                </span>
                <img
                  :src="imageUrlWrapper(arrowRightImg)"
                  alt="arrowRightImg"
                />
              </p>
            </div>
          </div>
        </div>
        <div
          :class="controlsOverlay ? 'inside-controls' : ''"
          class="vi-appointment-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <button
                  :disabled="
                    getChosenServicesData.find(
                      (item) => item.employee_id === undefined
                    )
                  "
                  :class="
                    getChosenServicesData.find(
                      (item) => item.employee_id === undefined
                    )
                      ? 'btn-disabled'
                      : ''
                  "
                  type="button"
                  class="vi-btn btn-primary btn-full"
                  @click="getThirdStep(getChosenServicesData)"
                >
                  {{ $t("choose_date_and_time") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal :id="idModal" :title="titleModal">
        <div class="vi-employees-list">
          <div class="vi-card-employee">
            <span
              v-if="pricingType === 'multiple'"
              class="vi-card-employee--click"
              @click="
                getChosenServicesData.find(
                  (item) =>
                    item.variant_id === selectedVariantId &&
                    item.employee_id === 0
                )
                  ? removeEmployeeByVariant(selectedVariantId, 0)
                  : chooseMultiEmployeeByVariant(
                      selectedVariantId,
                      0,
                      $t('first_available_specialist')
                    )
              "
            />
            <span
              v-else
              class="vi-card-employee--click"
              @click="
                getChosenServicesData.find(
                  (item) =>
                    item.service_id === selectedServiceId &&
                    item.employee_id === 0
                )
                  ? removeEmployee(selectedServiceId, 0)
                  : chooseMultiEmployee(
                      selectedServiceId,
                      0,
                      $t('first_available_specialist')
                    )
              "
            />
            <div class="vi-card-employee--inner">
              <div class="vi-card-employee--info">
                <div class="vi-card-employee--info-img">
                  <img
                    :src="imageUrlWrapper(firstImgLink)"
                    alt="staff-avatar"
                  />
                </div>
                <div class="vi-card-employee--info-wrap">
                  <p class="name">{{ $t("first_available_specialist") }}</p>
                  <span class="position">{{ $t("maximum_availability") }}</span>
                </div>
              </div>
              <div
                v-if="pricingType === 'multiple'"
                class="vi-card-employee--btn"
              >
                <span
                  v-if="
                    getChosenServicesData.find(
                      (item) =>
                        item.variant_id === selectedVariantId &&
                        item.employee_id === 0
                    )
                  "
                  class="vi-btn-add added"
                />
                <span v-else class="vi-btn-add" />
              </div>
              <div v-else class="vi-card-employee--btn">
                <span
                  v-if="
                    getChosenServicesData.find(
                      (item) =>
                        item.service_id === selectedServiceId &&
                        item.employee_id === 0
                    )
                  "
                  class="vi-btn-add added"
                />
                <span v-else class="vi-btn-add" />
              </div>
            </div>
          </div>
          <div
            v-for="staff in selectedStaff"
            :key="staff.id"
            class="vi-card-employee"
          >
            <span
              v-if="pricingType === 'multiple'"
              class="vi-card-employee--click"
              @click="
                getChosenServicesData.find(
                  (item) =>
                    item.variant_id === selectedVariantId &&
                    item.employee_id === staff.id
                )
                  ? removeEmployeeByVariant(selectedVariantId, staff.id)
                  : chooseMultiEmployeeByVariant(
                      selectedVariantId,
                      staff.id,
                      stickName(staff.first_name, staff.last_name)
                    )
              "
            />
            <span
              v-else
              class="vi-card-employee--click"
              @click="
                getChosenServicesData.find(
                  (item) =>
                    item.service_id === selectedServiceId &&
                    item.employee_id === staff.id
                )
                  ? removeEmployee(selectedServiceId, staff.id)
                  : chooseMultiEmployee(
                      selectedServiceId,
                      staff.id,
                      stickName(staff.first_name, staff.last_name)
                    )
              "
            />
            <div class="vi-card-employee--inner">
              <div class="vi-card-employee--info">
                <div class="vi-card-employee--info-img">
                  <img
                    v-if="staff.img === ''"
                    :src="imageUrlWrapper(firstImgLink)"
                    alt="staff-avatar"
                  />
                  <img v-else :src="`${staff.img}`" alt="staff-avatar" />
                </div>
                <div class="vi-card-employee--info-wrap">
                  <p class="name">
                    {{ staff.first_name }} {{ staff.last_name }}
                  </p>
                  <span class="position">{{
                    getSalonEmployeeTypes.find(
                      (x) => Number(x.id) === Number(staff.employee_type)
                    ).title
                  }}</span>
                </div>
              </div>
              <div
                v-if="pricingType === 'multiple'"
                class="vi-card-employee--btn"
              >
                <span
                  v-if="
                    getChosenServicesData.find(
                      (item) =>
                        item.variant_id === selectedVariantId &&
                        item.employee_id === staff.id
                    )
                  "
                  class="vi-btn-add added"
                />
                <span v-else class="vi-btn-add" />
              </div>
              <div v-else class="vi-card-employee--btn">
                <span
                  v-if="
                    getChosenServicesData.find(
                      (item) =>
                        item.service_id === selectedServiceId &&
                        item.employee_id === staff.id
                    )
                  "
                  class="vi-btn-add added"
                />
                <span v-else class="vi-btn-add" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  imageUrl,
  imageWrapper,
  timeFormatter
} from "../../utils/common/common.js";
import Modal from "@/components/Modal/Modal.vue";
import firstspecialist from "../../assets/img/firstspecialist.svg";
import arrowright from "../../assets/img/arrow-right.svg";

export default {
  components: {
    Modal
  },
  filters: {
    timeFormat: function (data) {
      return timeFormatter(data);
    }
  },
  data() {
    return {
      firstImgLink: firstspecialist,
      arrowRightImg: arrowright,
      isRebook: false,
      imageUrl,
      idModal: "employeesPopup",
      titleModal: "",

      pricingType: null,
      selectedVariantId: null,
      selectedServiceId: null,
      selectedStaff: {}
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "salonName",
      "getChosenServices",
      "getChosenServicesData",
      "controlsOverlay",
      "loginFromPath",
      "selectedEmployeeByQuery",
      "getSalonEmployees",
      "getSalonEmployeeTypes"
    ]),
    getStableChosenServices() {
      return this.$store.getters.getChosenServices;
    },
    getStableChosenPackages() {
      const services = this.$store.getters.getChosenServices.filter(
        (i) => i.package_id
      );
      const packagesId = [...new Set(services.map((i) => i.package_id))];
      const packages = packagesId.map((packageId) => {
        return {
          id: packageId,
          title: services.filter((i) => i.package_id === packageId)[0]
            .titleGroup,
          total_price: services.filter((i) => i.package_id === packageId)[0]
            .total_price,
          total_duration: services.filter((i) => i.package_id === packageId)[0]
            .total_duration,
          services: [...services.filter((i) => i.package_id === packageId)]
        };
      });
      return packages;
    }
  },
  watch: {
    getStableChosenServices: {
      deep: true,
      handler(newStateGetChosenServices) {
        if (newStateGetChosenServices.length === 0) {
          this.redirect(this.loginFromPath);
        }
      }
    }
  },
  mounted() {
    this.titleModal = this.$t("choose_staff");
    if (this.getStableChosenServices.length === 0) {
      this.redirect("services");
    }

    if (this.selectedEmployeeByQuery) {
      this.getChosenServices.forEach((service) => {
        this.setChosenEmployees({
          service_id: service.id,
          employee_id: this.selectedEmployeeByQuery.id,
          employeeName:
            this.selectedEmployeeByQuery.first_name +
            " " +
            this.selectedEmployeeByQuery.last_name
        });
      });
    }
  },
  methods: {
    ...mapMutations([
      "setChosenEmployees",
      "removeChosenEmployees",
      "setChosenEmployeesByVariant",
      "removeChosenEmployeesByVariant",
      "removeChosenServices",
      "removeChosenPackage",
      "removeFromBasketServicesByVariant"
    ]),
    debug(event) {
      console.log("Step 2", event);
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    stickName(firstName, lastName) {
      return `${firstName} ${lastName}`;
    },

    addSelectedEmployees(service_id, data, variant_id, pricingType) {
      this.$bvModal.show(this.idModal);
      this.pricingType = pricingType;
      this.selectedVariantId = variant_id;
      this.selectedServiceId = service_id;
      this.selectedStaff = data;
    },

    chooseEmployee(service_id, employee_id, employeeName) {
      this.setChosenEmployees({ service_id, employee_id, employeeName });
      this.redirect("slots");
    },
    chooseMultiEmployee(service_id, employee_id, employeeName) {
      this.setChosenEmployees({ service_id, employee_id, employeeName });
      this.$bvModal.hide(this.idModal);
    },
    removeEmployee(service_id, employee_id) {
      this.removeChosenEmployees({ service_id, employee_id });
    },

    chooseMultiEmployeeByVariant(variant_id, employee_id, employeeName) {
      this.setChosenEmployeesByVariant({
        variant_id,
        employee_id,
        employeeName
      });
      this.$bvModal.hide(this.idModal);
    },
    removeEmployeeByVariant(variant_id, employee_id) {
      this.removeChosenEmployeesByVariant({ variant_id, employee_id });
    },

    removeServices(item) {
      if (item.serviceType === "single") {
        this.removeChosenServices(item);
      } else {
        this.removeFromBasketServicesByVariant(item);
      }
    },
    removePackage(payload) {
      this.removeChosenPackage(payload);
    },

    getThirdStep() {
      this.redirect("slots");
    }
  }
};
</script>

<template>
  <div class="w-100">
    <div class="vi-horizontal-calendar">
      <div class="vi-horizontal-calendar--head">
        <span class="vi-horizontal-calendar--head-date" @click="handlerModal">
          {{ transformDate() }}
          <i class="ic-vi-arr-bottom" />
        </span>
      </div>
      <div class="vi-horizontal-calendar--body">
        <HorizontalCalendar
          :chosen-date="date"
          :date="date"
          :min-date="minDate"
          @change="dateChange"
        />
        <!--        <VueHorizontalCalendar-->
        <!--          :show-border-top="false"-->
        <!--          :choosed-date-pos="'center'"-->
        <!--          :resizeable="false"-->
        <!--          :choosed-date="date"-->
        <!--          :key="date"-->
        <!--          :highlighted-dates="datesArray(lastWeekDays, dateToday, 1)"-->
        <!--          :min-date="minDate"-->
        <!--          lang="en"-->
        <!--          style="width: 410px; margin: 0 auto"-->
        <!--          swipe-space="7"-->
        <!--        >-->
        <!--          <i slot="leftIcon" class="ic-vi-arr-line" />-->
        <!--          <i slot="rightIcon" class="ic-vi-arr-line-right" />-->
        <!--        </VueHorizontalCalendar>-->
      </div>
    </div>

    <Modal :id="idModal" :title="titleModal">
      <div class="vi-calendar">
        <b-calendar
          v-model="date"
          :locale="locale"
          :min="dateToday"
          :start-weekday="startOfWeek"
          class="vi-b-calendar"
          @selected="dateSelected"
        />

        <!--        <Datepicker-->
        <!--          :value="date"-->
        <!--          :format="customFormatter"-->
        <!--          :disabled-dates="{-->
        <!--            to: disabledDate-->
        <!--          }"-->
        <!--          inline-->
        <!--          @selected="dateSelected"-->
        <!--        />-->
      </div>
    </Modal>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueHorizontalCalendar from "vue-horizontal-calendar";
import Modal from "@/components/Modal/Modal.vue";
import moment from "moment-timezone";
import { mapGetters, mapMutations } from "vuex";
import { BCalendar } from "bootstrap-vue";
import HorizontalCalendar from "../HorizontalCalendar/HorizontalCalendar";

export default {
  name: "CalendarPicker",
  components: {
    Modal,
    Datepicker,
    BCalendar,
    VueHorizontalCalendar,
    HorizontalCalendar
  },
  props: {
    text: {}
  },
  data() {
    return {
      currentLang: null,
      idModal: "calendarPopup",
      titleModal: "",
      date: "",
      dateToday: "",
      minDate: "",
      disabledDate: "",
      lastWeekDays: "",
      highlightedDates: "",
      locale: "en"
    };
  },
  computed: {
    ...mapGetters([
      "getGlobalDateSlots",
      "salonTimeZone",
      "language",
      "startOfWeek"
    ])
  },
  watch: {
    getGlobalDateSlots: {
      deep: true,
      handler(newGetGlobalDateSlots) {
        if (newGetGlobalDateSlots) {
          this.date = newGetGlobalDateSlots;
        }
      }
    }
  },
  created() {
    this.locale = this.language;
    this.titleModal = this.selectDate();
  },
  mounted() {
    this.setDates();
  },
  methods: {
    ...mapMutations(["setGlobalDateSlots"]),
    debug(event) {
      console.log("Calendar", event);
    },
    setDates() {
      if (this.salonTimeZone) {
        this.date = moment().tz(this.salonTimeZone).format("YYYY-MM-DD");
        this.$emit("input", this.date);
        this.dateToday = moment().tz(this.salonTimeZone).format("YYYY-MM-DD");
        this.minDate = moment()
          .tz(this.salonTimeZone)
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        this.disabledDate = new Date(
          moment().tz(this.salonTimeZone).format("YYYY"),
          moment().tz(this.salonTimeZone).format("M") - 1,
          moment().tz(this.salonTimeZone).format("D")
        );
        this.lastWeekDays = moment()
          .tz(this.salonTimeZone)
          .day(-7)
          .format("YYYY-MM-DD");
        this.highlightedDates = moment()
          .tz(this.salonTimeZone)
          .subtract(1, "days")
          .format("YYYY/MM/DD");
      }
    },
    selectDate() {
      return this.$t("select_date");
    },
    customFormatter(date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss z ZZ");
    },
    datesArray(from, to, interval) {
      let ret = [];
      const fromDate = moment(from, "YYYY-MM-DD");
      const toDate = moment(to, "YYYY-MM-DD");
      let date = fromDate.add(interval, "days");
      while (toDate > date) {
        ret.push(date.format("YYYY/MM/DD"));
        date = moment(date).add(interval, "days");
      }
      return ret;
    },
    handlerModal() {
      this.$bvModal.show(this.idModal);
    },
    dateSelected(data) {
      this.date = moment(data).format("YYYY-MM-DD");
      this.$bvModal.hide(this.idModal);
      this.setGlobalDateSlots = null;
    },
    dateChange(date) {
      // const selectedDate = new Date(date.dateFormat);
      const selectedDate = new Date(date);

      const today = new Date(this.minDate);
      // const today = moment(this.minDate).format();

      this.setGlobalDateSlots = null;

      // Validate date
      if (selectedDate < today) {
        this.date = moment().tz(this.salonTimeZone).format("YYYY-MM-DD");
        return;
      } else {
        this.date = moment(date).format("YYYY-MM-DD");
      }
      this.$emit("input", moment(date).format("YYYY-MM-DD"));
    },
    transformDate() {
      const monthArray = [
        this.$t("month_short1"),
        this.$t("month_short2"),
        this.$t("month_short3"),
        this.$t("month_short4"),
        this.$t("month_short5"),
        this.$t("month_short6"),
        this.$t("month_short7"),
        this.$t("month_short8"),
        this.$t("month_short9"),
        this.$t("month_short10"),
        this.$t("month_short11"),
        this.$t("month_short12")
      ];
      const currMonth = new Date(this.date).getMonth();
      return `${new Date(this.date).getDate()} ${
        monthArray[currMonth]
      } ${new Date(this.date).getFullYear()}`;
    }
  }
};
</script>

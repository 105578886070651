<template>
  <div class="vi-payment-method">
    <div class="vi-payment-method__caption">
      {{ $t("payment_method") }}

      <span v-if="!data" class="vi-link-primary" @click="$emit('open')">{{
        $t("add_card")
      }}</span>
    </div>
    <div v-if="data" class="vi-payment-method__data" @click="$emit('open')">
      <div class="vi-payment-method__data-card">
        <div class="card-type">
          <img
            :src="
              imageUrlWrapper(
                types[data.brand] ? types[data.brand] : DefaultCard
              )
            "
            :alt="data.brand"
          />
        </div>
        <span class="card-number">**** **** **** {{ data.last4 }}</span>
      </div>
      <i class="ic-vi-arr-right"></i>
    </div>
  </div>
</template>

<script>
import VisaLogo from "../../assets/img/visa.svg";
import MastercardLogo from "../../assets/img/mastercard.svg";
import AExpressLogo from "../../assets/img/american-express.svg";
import DiscoverLogo from "../../assets/img/discover.svg";
import DefaultCard from "../../assets/img/card.svg";
import { imageWrapper } from "../../utils/common/common";

export default {
  name: "PaymentMethod",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      DefaultCard,
      types: {
        visa: VisaLogo,
        amex: AExpressLogo,
        discover: DiscoverLogo,
        mastercard: MastercardLogo
      }
    };
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

<template>
  <b-modal
    :id="id"
    :title="title"
    scrollable
    centered
    hide-footer
    no-enforce-focus
    modal-class="vi-main-modal vi-b-modal"
    header-class="vi-b-modal--header"
    body-class="vi-b-modal--body"
    footer-class="vi-b-modal--footer"
    @close="$emit('close')"
  >
    <div :style="styleObject" class="vi-b-modal--wrapper">
      <slot />
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { hexToRgb } from "../../utils/common/common";

export default {
  props: ["id", "title"],
  computed: {
    ...mapGetters(["widgetOptions"]),
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(
          this.widgetOptions.buttonBackground
        ),
        "--vi-main-primary-background": this.widgetOptions.buttonBackground,
        "--vi-main-primary-background-hover":
          this.widgetOptions.buttonBackgroundHover,
        "--vi-main-primary-color": this.widgetOptions.buttonColor
      };
    }
  }
};
</script>

<template>
  <div>
    <div
      class="
        belliata-error-border
        d-flex
        justify-content-end
        pt-3
        pb-3
        pr-2
        mb-3
      "
    >
      <img :src="imgClose" alt="close" @click="removeServiceError" />
    </div>
    <div class="d-flex justify-content-center mb-4">
      <img :src="imgLink" alt="error" />
    </div>
    <div>
      <p class="belliata-error-text text-center">
        {{ $t("service_error_text_1", { getServiceErrorName }) }}
      </p>
    </div>
    <div>
      <p class="belliata-error-warning">
        {{ $t("service_error_text_2") }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgLink from "../../assets/img/error.svg";
import imgClose from "../../assets/img/close-icon.svg";
import { imageWrapper } from "../../utils/common/common.js";
export default {
  data() {
    return {
      imgLink,
      imgClose
    };
  },
  computed: {
    ...mapGetters(["getServiceErrorName"])
  },
  methods: {
    ...mapMutations(["removeServiceError"]),
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

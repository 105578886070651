<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div class="vi-main-placeholder">
        <div v-if="img" class="vi-main-placeholder--image mb-4">
          <img :src="imageUrlWrapper(img)" alt="" />
        </div>
        <div class="vi-main-placeholder--content">
          <h3>{{ $t("new_email") }}</h3>
          <p>{{ responseMessage }}</p>
        </div>
        <div class="vi-main-placeholder--btn text-center">
          <button class="vi-btn btn-primary" type="button" @click="redirect">
            {{ $t("continue") }}
          </button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { imageWrapper, apiUrl } from "../../utils/common/common.js";
import successImgLink from "../../assets/img/success.svg";
import errorImgLink from "../../assets/img/error.svg";

export default {
  name: "NewEmail",
  data() {
    return {
      responseMessage: "",
      img: null
    };
  },
  async created() {
    const email = localStorage.getItem("new-email")
      ? localStorage.getItem("new-email")
      : null;
    if (this.$route.query.token && email) {
      this.$store.commit("isLoading");

      try {
        const token = this.$route.query.token;
        const response = await this.axios.post(apiUrl("booking/reset-email"), {
          token,
          email
        });

        const customer = await this.$store.dispatch("loadCustomer", {
          salon: this.salonId
        });
        localStorage.setItem("customer", JSON.stringify(customer));

        this.responseMessage = response.data.message;
        this.img = successImgLink;
        this.$store.commit("isLoaded");
        localStorage.removeItem("new-email");
      } catch (e) {
        this.$store.commit("isLoaded");
        this.img = errorImgLink;
        this.responseMessage = e.response.data.message;
      }
    }
  },
  methods: {
    redirect() {
      this.$router.push({ name: "your-details" });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

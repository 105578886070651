<template>
  <b-container>
    <div class="vi-main-wrapper--inner p-0">
      <div class="vi-booking-created">
        <div class="vi-booking-created--header">
          <img :src="imageUrlWrapper(imgSuccess)" alt="imgSuccess" />
          <h2>{{ $t("booking_created") }}</h2>
        </div>
        <div class="vi-booking-created--body">
          <BasketCard
            :date="getTimeOrder.date | filterDate"
            :time="getTimeOrder.time_formatted"
            :salon="salonName"
            :address="salonAddress"
          />

          <div class="vi-basket-list">
            <div
              v-for="packageItem in getSavedServices.packages"
              :key="packageItem.id"
              class="vi-card-basket-package"
            >
              <div class="vi-card-basket-package__header">
                <span class="vi-card-basket-package__header-title">
                  {{ packageItem.title }}
                </span>
                <div class="vi-card-basket-package__header-prices">
                  <span class="duration">{{
                    packageItem.total_duration | timeFormat
                  }}</span>
                  <span class="price">{{
                    packageItem.total_price | defaultPriceFormat(getPriceFormat)
                  }}</span>
                </div>
              </div>
              <div class="vi-card-basket-package__body">
                <div
                  v-for="service in packageItem.services"
                  :key="service.id"
                  class="vi-card-basket-finish"
                >
                  <div class="vi-card-basket-finish--caption">
                    <h3>{{ service.title }}</h3>
                    <div class="prices">
                      <span
                        v-if="
                          service.custom_price &&
                          service.custom_price !== service.price
                        "
                        class="old-price"
                        >{{ service.price_formatted }}</span
                      >
                      <span class="price">{{
                        service.custom_price
                          ? service.custom_price
                          : service.price | defaultPriceFormat(getPriceFormat)
                      }}</span>
                    </div>
                  </div>
                  <div class="vi-card-basket-finish--info">
                    <div class="d-flex align-items-center">
                      <span class="vi-card-basket-finish--info-employee">
                        <i class="ic-vi-star-line"></i>
                        {{ service.employee }}
                      </span>
                      <span class="vi-card-basket-finish--info-time">
                        <i class="ic-vi-clock"></i
                        >{{ service.time_formatted }} ({{
                          service.custom_duration
                            ? service.custom_duration
                            : service.duration | timeFormat
                        }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-for="item in getSavedServices.services"
              v-if="!item.package_id"
              :key="item.id"
              class="vi-card-basket-finish"
            >
              <div class="vi-card-basket-finish--caption">
                <h3>{{ item.title }}</h3>
                <span class="price">{{ item.priceFormatted }}</span>
              </div>
              <div class="vi-card-basket-finish--info">
                <div class="d-flex align-items-center">
                  <span class="vi-card-basket-finish--info-employee">
                    <i class="ic-vi-star-line"></i>
                    {{ item.employee }}
                  </span>
                  <span class="vi-card-basket-finish--info-time">
                    <i class="ic-vi-clock"></i>{{ item.time_formatted }} ({{
                      item.duration | timeFormat
                    }})
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="vi-basket-total">
            <span></span>
            <div class="vi-basket-total--label">
              {{ $t("total") }}:
              <strong>{{
                getTotalServicesPrice
                  | priceFormat(getPriceFormat, getChosenServices)
              }}</strong>
            </div>
          </div>
          <div class="vi-typography">
            <ul class="list-check">
              <li>{{ $t("you_can_cancel_via_account") }}</li>
              <li>
                {{
                  $t("we_will_send_you_a_reminder_prior_to_your_appointment")
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="vi-booking-created--footer">
          <button
            class="vi-btn btn-primary btn-full mb-3"
            @click="goToAppointments"
          >
            {{ $t("view_appointments") }}
          </button>
          <button class="vi-btn btn-white btn-full mb-3" @click="logout">
            {{ $t("logout") }}
          </button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgPlus from "../../assets/img/plus-round.svg";
import imgClock from "../../assets/img/clock.svg";
import imgSuccess from "../../assets/img/success.svg";
import imgStar from "../../assets/img/star.svg";
import imgCheck from "../../assets/img/check-grey.svg";
import {
  apiUrl,
  imageWrapper,
  priceFormatter,
  removeAuthData,
  timeFormatter
} from "../../utils/common/common.js";
import moment from "moment";
import BasketCard from "../../components/BasketCard/BasketCard";
export default {
  components: {
    BasketCard
  },
  filters: {
    filterDate(value) {
      return moment(value).format("DD MMM, YYYY");
    },
    priceFormat: function (data, format, containsArray) {
      let arrHide = containsArray.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      let arrBookable = containsArray.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (arrHide.length > 0 && arrBookable.length > 0) {
        return `from ${priceFormatter(data, format)}`;
      } else if (arrHide.length === 0) {
        return priceFormatter(data, format);
      } else {
        return arrHide[0].variants[0].price_formatted;
      }
    },
    totalPrice(data, format) {
      let total = 0;
      data.map((item) => {
        total = total + item.price;
      });
      return priceFormatter(total, format);
    },
    timeFormat(data) {
      return timeFormatter(data);
    },
    defaultPriceFormat(amount, format) {
      return priceFormatter(amount, format);
    }
  },
  data() {
    return {
      imgPlus,
      imgClock,
      imgSuccess,
      imgStar,
      imgCheck,
      salon: "Test Salon"
    };
  },
  computed: {
    ...mapGetters([
      "loading",
      "salonName",
      "salonAddress",
      "getChosenServices",
      "getTotalServicesPrice",
      "getPriceFormat",
      "getTimeOrder",
      "getSavedServices",
      "salonId"
    ]),
    getSavedServices() {
      let services = this.$store.getters.getSavedServices;
      let packagesFiltered = services.filter((i) => i.package_id);
      let packagesId = [...new Set(packagesFiltered.map((i) => i.package_id))];
      const packages = packagesId.map((packageId) => {
        return {
          id: packageId,
          title: services.filter((i) => i.package_id === packageId)[0]
            .titleGroup,
          total_price: services.filter((i) => i.package_id === packageId)[0]
            .total_price,
          total_duration: services.filter((i) => i.package_id === packageId)[0]
            .total_duration,
          services: [...services.filter((i) => i.package_id === packageId)]
        };
      });
      return { services, packages };
    }
  },
  mounted() {
    if (this.getSavedServices.services.length === 0) {
      this.redirect("home");
    }
  },
  methods: {
    ...mapMutations([
      "setTabPastAppointment",
      "clearChosenServices",
      "setLoginFromPath"
    ]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    goToAppointments() {
      this.clearChosenServices();
      this.setTabPastAppointment(false);
      this.redirect("your-appointments");
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    async logout() {
      await this.$store.dispatch("logout");
      await this.setLoginFromPath(null);
      await this.clearChosenServices();
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vi-card-accordion"},[_c('div',{staticClass:"vi-card-accordion--heading",class:_vm.isShow ? null : 'collapsed',attrs:{"aria-expanded":_vm.isShow ? 'true' : 'false',"aria-controls":"collapse-5"},on:{"click":function($event){return _vm.onToggle(_vm.id)}}},[_c('h6',{staticClass:"title-heading"},[_vm._v(_vm._s(_vm.title))]),_c('i',{staticClass:"ic-vi-arr-bottom",class:_vm.isShow ? 'collapsed' : ''})]),_c('div',{staticClass:"vi-card-accordion--body"},[_c('b-collapse',{attrs:{"id":"collapse-5"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},_vm._l((_vm.packages),function(packageItem){return _c('div',{key:packageItem.id,ref:("package" + (packageItem.id)),refInFor:true,staticClass:"vi-card-accordion--item"},[_c('div',{staticClass:"vi-card-accordion--item-inner"},[_c('div',{staticClass:"vi-card-accordion--item-inner--toggle",on:{"click":function($event){return _vm.selectPackage(packageItem, _vm.title, _vm.getChosenServices)}}}),_c('div',{staticClass:"vi-card-accordion--item-caption-btn"},[(
                _vm.getChosenServices.find(
                  function (item) { return item.package_id && item.package_id === packageItem.id; }
                )
              )?_c('span',{staticClass:"vi-btn-add added"}):_c('span',{staticClass:"vi-btn-add"})]),_c('div',{staticClass:"vi-card-accordion--item-caption"},[_c('div',{staticClass:"vi-card-accordion--item-caption--head"},[_c('h5',[_vm._v(_vm._s(packageItem.title))]),_c('span',{staticClass:"vi-price"},[_vm._v("\n                "+_vm._s(_vm._f("formatPrice")(packageItem.total,_vm.getPriceFormat))+"\n              ")])]),_c('div',{staticClass:"vi-card-accordion--item-caption--body"},[_c('div',[_c('p',{staticClass:"vi-card-accordion--item-caption--time"},[_vm._v("\n                  "+_vm._s(packageItem.pricings
                      .map(function (i) { return i.duration; })
                      .reduce(function (a, b) { return a + b; }))+"\n                  "+_vm._s(_vm.$t("minute_short"))+" |\n                  "+_vm._s(packageItem.services ? packageItem.services.length : 0)+"\n                  "+_vm._s(packageItem.services && packageItem.services.length === 1
                      ? _vm.$t("service")
                      : _vm.$t("services"))+"\n                ")]),_c('span',{staticClass:"vi-card-accordion--item-caption--more",on:{"click":function($event){return _vm.showDetails(packageItem)}}},[_vm._v(_vm._s(_vm.$t("show_details")))])])])])])])}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
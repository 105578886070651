<template>
  <div class="vi-payment-method">
    <div class="vi-payment-method--form">
      <div v-if="isLoading" class="vi-loader-content">
        <b-spinner variant="success" class="vi-spinner" />
      </div>
      <div>
        <div class="vi-form-group">
          <div class="vi-form-group--wrap-icon">
            <i class="ic-user-liner" />
            <input
              :class="
                holder && holder.error.status ? 'vi-form-control--error' : ''
              "
              :placeholder="$t('cardholder_name')"
              :value="holder && holder.value"
              type="text"
              class="vi-form-control"
              @input="(e) => handlerChangeHolderName(e.target.value)"
              @blur="validationHolderForm"
            />
            <span
              v-if="holder && holder.error.status"
              class="vi-form-control--text-error"
              >{{ holder && holder.error.message }}</span
            >
          </div>
        </div>
        <div ref="elementCard" class="vi-form-control"></div>
        <div v-if="globalError.status" class="vi-pack-alert alert-danger">
          {{ globalError.message }}
        </div>
        <p class="pt-3 pb-4">
          <span class="vi-link vi-link-primary icon-left no-link"
            ><i class="ic-vi-lock" />{{
              $t("your_transaction_is_secure")
            }}</span
          >
        </p>
        <div class="vi-form-group">
          <Checkbox
            :checked="isSaveCard"
            :text="$t('save_my_credit_card_for_future_bookings')"
            class="checkbox-secondary"
            @change="toggleSaveCard"
          />
        </div>
      </div>
    </div>
    <div v-if="!isLoading" class="vi-payment-method--confirm">
      <vi-button
        :vi-full="true"
        :vi-loader="isLoadingButton"
        @click="handlerSubmit"
      >
        {{ $t("add_payment_method") }}
      </vi-button>
    </div>
  </div>
</template>

<script>
import ViButton from "../common/ViButton";
import { mapGetters, mapMutations } from "vuex";
import { apiUrl } from "../../utils/common/common";
import Checkbox from "../Checkbox/Checkbox";
import {
  StripeElementCard,
  StripeElementPayment
} from "@vue-stripe/vue-stripe";
export default {
  name: "CreatePaymentMethod",
  components: { ViButton, Checkbox, StripeElementPayment, StripeElementCard },
  data() {
    return {
      cardEl: null,
      stripe: null,
      clientSecret: "",

      isSaveCard: true,
      holder: {
        value: "",
        error: {
          message: "",
          status: false
        }
      },
      globalError: {
        status: false,
        message: ""
      },

      isLoading: false,
      isLoadingButton: false
    };
  },
  computed: {
    ...mapGetters(["salonId"])
  },
  mounted() {
    this.cleanHolder();
    this.getPaymentData();
  },
  methods: {
    ...mapMutations(["setPaymentIntent", "setTotalPriceBooking", "runTimer"]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    loader(value) {
      this.isLoading = value;
    },
    buttonLoader(value) {
      this.isLoadingButton = value;
    },
    toggleSaveCard(value) {
      this.isSaveCard = value;
    },
    stripeElement(accountId) {
      const style = {
        base: {
          fontFamily: "Poppins, sans-serif",
          fontSize: "14px",
          color: "#36404A",
          lineHeight: "30px",
          "::placeholder": {
            color: "#979797"
          }
        },
        invalid: {
          color: "#FF6565FF",
          iconColor: "#FF6565FF"
        }
      };

      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_API_PUBLISH_KEY, {
        stripeAccount: accountId
      });
      let elements = this.stripe.elements({
        fonts: [
          {
            cssSrc:
              "https://fonts.googleapis.com/css2?family=Poppins&display=swap"
          }
        ],
        locale: "auto"
      });
      elements = elements.create("card", { style: style });
      elements.mount(this.$refs.elementCard);

      this.cardEl = elements;
    },

    getPaymentData() {
      new Promise(async (resolve, reject) => {
        this.loader(true);

        try {
          let response = await this.axios.post(
            apiUrl(`booking/save-card/${this.salonId}`)
          );

          this.stripeElement(response.data.account_id);

          this.clientSecret = response.data.client_secret;

          this.loader(false);
          resolve();
        } catch (e) {
          this.loader(false);
          console.log(e);

          reject(e);
        }
      });
    },

    async addPaymentMethod() {
      const { setupIntent, error } = await this.stripe.confirmCardSetup(
        this.clientSecret,
        {
          payment_method: {
            card: this.cardEl,
            billing_details: {
              name: this.holder.value
            }
          }
        }
      );

      if (error) {
        this.setPaymentError(true, error.message);
        this.buttonLoader(false);
      } else {
        await this.savePaymentMethod(setupIntent.payment_method);
      }
    },
    setPaymentError(value, message) {
      this.globalError.status = value;
      this.globalError.message = message;
    },
    savePaymentMethod(paymentMethod) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await this.axios.post(
            apiUrl(`booking/payment-method/${this.salonId}`),
            {
              payment_method: paymentMethod,
              save_card: this.isSaveCard
            }
          );
          this.buttonLoader(false);
          this.$emit("success", response.data.payment_method);

          resolve();
        } catch (e) {
          console.log(e);

          reject(e);
        }
      });
    },

    handlerSubmit() {
      if (!this.holder.value) {
        this.holder.error.message = this.$t("this_field_is_required");
        this.holder.error.status = true;

        return;
      }

      this.holder.error.message = "";
      this.holder.error.status = false;
      this.setPaymentError(false, "");

      this.buttonLoader(true);
      this.addPaymentMethod();
    },

    handlerChangeHolderName(value) {
      this.holder.value = value;
      this.validationHolderForm();
    },
    validationHolderForm() {
      if (!this.holder.value) {
        this.holder.error.message = this.$t("this_field_is_required");
        this.holder.error.status = true;

        return false;
      }

      this.holder.error.message = "";
      this.holder.error.status = false;
      return true;
    },
    cleanHolder() {
      this.holder.value = "";
      this.holder.error.message = "";
      this.holder.error.status = false;
    }
  }
};
</script>

<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <Filters :employees="getEmployeesWithType" />
      <w-alert
        v-if="isShowAlertInfo"
        :title="specialInfo"
        variant="warning"
        @close="() => (isShowAlertInfo = false)"
      />
      <div class="vi-main-services">
        <!-- Services Loader -->
        <div v-if="loading" class="vi-loader-static">
          <vi-spinner />
        </div>

        <div v-else class="vi-main-services--list">
          <ServiceError v-if="getServiceError" />
          <div>
            <div
              v-if="widgetOptions.previewVersion"
              class="vi-main-services--item"
            >
              <ServiceItemPreview
                :services="previewInformation.services"
                :is-show="true"
                :id="123"
                :title="$t('popular_services')"
              />
            </div>
            <div v-else>
              <div
                v-for="(item, i) in getStableServices"
                :key="i"
                class="vi-main-services--item"
              >
                <package-item
                  v-if="item.is_package && item.packages.length > 0"
                  :packages="item.packages"
                  :is-show="item.isShow"
                  :id="item.idCollapse"
                  :title="item.group_title"
                  :choose-package="choosePackage"
                  @show-details="serviceDetails"
                />
                <ServiceItem
                  v-else-if="item.services.length > 0"
                  :services="item.services"
                  :is-show="item.isShow"
                  :id="item.idCollapse"
                  :title="item.group_title"
                  :choose-service="chooseService"
                  :choose-variant="chooseVariant"
                  @show-details="serviceDetails"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="getChosenServices.length > 0 && !getServiceError"
          :class="controlsOverlay ? 'inside-controls' : ''"
          :style="{ paddingBottom: widgetOptions.paddingControlsBottom + 'px' }"
          class="vi-appointment-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center vi-main-services--footer">
              <div class="col-auto">
                <span class="vi-main-services--footer-total--services">
                  {{ getChosenServices.length }}
                  {{
                    getChosenServices.length === 1
                      ? $t("service")
                      : $t("services")
                  }}
                </span>
                <p class="vi-main-services--footer-total--price">
                  {{ getTotalServicesPriceFrom ? $t("from") + " " : "" }}

                  {{
                    getTotalServicesPrice
                      | priceFormat(getPriceFormat, getChosenServices)
                  }}
                </p>
              </div>
              <div class="col vi-main-services--footer-action">
                <div>
                  <button
                    type="button"
                    class="vi-btn btn-primary btn-icon-right btn-full"
                    @click="bookNow"
                  >
                    {{ $t("choose_staff") }}
                    <i class="ic-vi-arr-line-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="widgetOptions.previewVersion"
          :style="{ paddingBottom: widgetOptions.paddingControlsBottom + 'px' }"
          class="vi-appointment-controls inside-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center vi-main-services--footer">
              <div class="col-auto">
                <span class="vi-main-services--footer-total--services">
                  1 {{ $t("service") }}
                </span>
                <p class="vi-main-services--footer-total--price">
                  {{ previewInformation.services | previewTotalPrice }}
                </p>
              </div>
              <div class="col vi-main-services--footer-action">
                <div>
                  <button
                    type="button"
                    class="vi-btn btn-primary btn-icon-right btn-full"
                  >
                    {{ $t("choose_staff") }}
                    <i class="ic-vi-arr-line-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="getServiceError"
          :class="`${controlsOverlay ? 'inside-controls' : ''}`"
          :style="{ paddingBottom: widgetOptions.paddingControlsBottom + 'px' }"
          class="vi-appointment-controls"
        >
          <div class="vi-appointment-controls--fix-width">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <button
                  type="button"
                  class="vi-btn btn-danger btn-full"
                  @click="removeServiceError"
                >
                  {{ $t("cancel_appointment") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Modal  -->
    <ModalSpecialInfo :id="idModal" :content="specialInfo" />
    <b-modal
      :id="idModalShowDetails"
      :title="titleModalShowDetails"
      :hide-footer="dataDetails.booking === 'bookable_phone'"
      scrollable
      centered
      no-enforce-focus
      modal-class="vi-main-modal vi-b-modal"
      header-class="vi-b-modal--header"
      body-class="vi-b-modal--body"
      footer-class="vi-b-modal--footer"
    >
      <div :style="styleObject" class="vi-b-modal--wrapper">
        <div class="vi-service-details">
          <div class="vi-service-details--header">
            <h2 class="vi-service-details--header-caption">
              {{ dataDetails.title }}
            </h2>
            <div class="vi-service-details--header-other">
              <span class="vi-service-details--header-other--time">{{
                dataDetails.time
              }}</span>
              <span class="vi-service-details--header-other--price">{{
                dataDetails.price
              }}</span>
            </div>
          </div>
          <div class="vi-service-details--body">
            <div
              v-if="
                dataDetails.service &&
                dataDetails.service.image &&
                dataDetails.service.image.length
              "
              class="vi-service-details--image"
            >
              <img
                :src="dataDetails.service.image[0]"
                :alt="dataDetails.service"
              />
            </div>

            <div v-if="dataDetails.isPackage">
              <h4>{{ $t("services") }}</h4>
              <div class="vi-services-list mb-3">
                <div
                  v-for="item in dataDetails.services"
                  :key="item.id"
                  class="vi-card-basket-finish"
                >
                  <div class="vi-card-basket-finish--caption">
                    <h3>{{ item.title }}</h3>
                    <div class="prices">
                      <span
                        v-if="
                          item.variants[0].custom_price !==
                          item.variants[0].price
                        "
                        class="old-price"
                        >{{ item.variants[0].price_formatted }}</span
                      >
                      <span class="price">{{
                        item.variants[0].custom_price
                          | defaultPriceFormat(getPriceFormat)
                      }}</span>
                    </div>
                  </div>
                  <div class="vi-card-basket-finish--info">
                    <div class="d-flex align-items-center">
                      <span class="vi-card-basket-finish--info-time">
                        <i class="ic-vi-clock"></i
                        >{{ item.variants[0].custom_duration | timeFormat }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="dataDetails.description">
                <h4>{{ $t("about_this_package") }}</h4>
                <div class="vi-content" v-html="dataDetails.description"></div>
              </div>
            </div>
            <div v-else class="vi-service-details--body-scroll">
              <h4 v-if="dataDetails.description">
                {{ $t("about_this_service") }}
              </h4>
              <div
                v-if="dataDetails.description"
                v-html="dataDetails.description"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div :style="styleObject" class="vi-service-details--footer">
          <div v-if="!isShowVariants" class="vi-service-details--total">
            <div class="row">
              <div class="col-auto vi-service-details--total-caption">
                <span
                  v-if="dataDetails.isPackage"
                  class="vi-service-details--total-caption--services"
                >
                  {{ dataDetails.services && dataDetails.services.length }}
                  {{
                    dataDetails.services && dataDetails.services.length === 1
                      ? $t("service")
                      : $t("services")
                  }}
                </span>
                <span
                  v-else
                  class="vi-service-details--total-caption--services"
                >
                  {{ dataDetails.variants && dataDetails.variants.length }}
                  {{
                    dataDetails.variants && dataDetails.variants.length === 1
                      ? $t("service")
                      : $t("services")
                  }}
                </span>
                <span class="vi-service-details--total-caption--price">{{
                  dataDetails.price
                }}</span>
              </div>
              <div
                v-if="dataDetails.isPackage"
                class="col vi-service-details--total-action"
              >
                <div>
                  <button
                    :class="
                      getChosenServices.find(
                        (item) =>
                          item.package_id && item.package_id === dataDetails.id
                      )
                        ? 'btn-selected'
                        : ''
                    "
                    type="button"
                    class="
                      vi-btn
                      btn-primary btn-icon-right btn-select btn-full
                    "
                    @click="
                      choosePackage(
                        dataDetails,
                        dataDetails.title,
                        getChosenServices,
                        0
                      )
                    "
                  >
                    {{ $t("select_package") }}
                    <i class="ic-vi-arr-line-right" />
                  </button>
                </div>
              </div>
              <div v-else class="col vi-service-details--total-action">
                <div
                  v-if="
                    dataDetails.variants && dataDetails.variants.length === 1
                  "
                >
                  <button
                    :class="
                      getChosenServices.find(
                        (item) =>
                          item.id === dataDetails.id &&
                          item.variants[0].id === dataDetails.variants[0].id
                      )
                        ? 'btn-selected'
                        : ''
                    "
                    type="button"
                    class="
                      vi-btn
                      btn-primary btn-icon-right btn-select btn-full
                    "
                    @click="
                      chooseService(
                        dataDetails,
                        dataDetails.title,
                        getChosenServices,
                        0
                      )
                    "
                  >
                    {{ $t("select_service") }}
                    <i class="ic-vi-arr-line-right" />
                  </button>
                </div>
                <div v-else>
                  <button
                    type="button"
                    class="vi-btn btn-primary btn-icon-right btn-full"
                    @click="isShowVariants = true"
                  >
                    {{ $t("select") }}
                    <i class="ic-vi-arr-line-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isShowVariants" class="vi-service-details--list">
            <div
              v-for="variant in dataDetails.variants"
              :key="variant.id"
              class="vi-service-details--list-item"
            >
              <div class="row">
                <div class="col-auto vi-service-details--list-item--caption">
                  <h4>{{ variant.caption }}</h4>
                  <p>
                    {{ variant.duration }} {{ $t("minute_short") }} |
                    {{ variant.price_formatted }}
                  </p>
                </div>
                <div class="col vi-service-details--list-item--action">
                  <div>
                    <button
                      :class="
                        getChosenServices.find(
                          (item) => item.variants[0].id === variant.id
                        )
                          ? 'btn-selected'
                          : ''
                      "
                      type="button"
                      class="
                        vi-btn
                        btn-primary btn-icon-right btn-select btn-full
                      "
                      @click="
                        chooseVariant(
                          dataDetails,
                          variant,
                          dataDetails.caption,
                          getChosenServices,
                          0
                        )
                      "
                    >
                      {{ $t("select") }}
                      <i class="ic-vi-arr-line-right" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Filters from "../../components/Filters/Filters.vue";
import ServiceError from "../../components/ServiceError/ServiceError.vue";
import ServiceItem from "./ServiceItem.vue";
import {
  hexToRgb,
  imageWrapper,
  priceFormatter,
  timeFormatter
} from "../../utils/common/common";
import ModalSpecialInfo from "../../components/Modals/ModalSpecialInfo";
import ServiceItemPreview from "./ServiceItemPreview";
import Modal from "../../components/Modal/Modal";
import ViSpinner from "../../components/common/ViSpinner";
import PackageItem from "./PackageItem";
import WAlert from "../../components/Alert/WAlert";

export default {
  components: {
    WAlert,
    PackageItem,
    ViSpinner,
    Filters,
    Modal,
    ServiceError,
    ModalSpecialInfo,
    ServiceItemPreview,
    ServiceItem
  },
  filters: {
    filteredSmallPrice(from, arr, text, type, format) {
      if (type === "hide_price") {
        return text;
      } else {
        const smaller = arr.reduce((a, b) => {
          if (a.price !== 0 && b.price !== 0) {
            if (a.price < b.price) {
              return a;
            }
            return b;
          }
          return a;
        });

        return `${from} ${priceFormatter(smaller.price, format)}`;
      }
    },
    priceFormat(data, format, containsArray) {
      let arrHide = containsArray.filter((item) => {
        if (Object.values(item).includes("hide_price")) {
          return item;
        }
      });
      let arrBookable = containsArray.filter((item) => {
        if (Object.values(item).includes("bookable")) {
          return item;
        }
      });

      if (arrHide.length > 0 && arrBookable.length > 0) {
        return `from ${priceFormatter(data, format)}`;
      } else if (arrHide.length === 0) {
        return priceFormatter(data, format);
      } else {
        return arrHide[0].variants[0].price_formatted;
      }
    },
    defaultPriceFormat(amount, format) {
      return priceFormatter(amount, format);
    },
    timeFormat(data) {
      return timeFormatter(data);
    },
    previewTotalPrice(data) {
      if (data) {
        return data[4].price_formatted;
      }
    }
  },
  data() {
    return {
      idModal: "specialInfoModal",
      idModalShowDetails: "showDetailsModal",
      titleModalShowDetails: "Service details",
      count: 0,
      toggle: 0,
      dataDetails: {},
      isShowAlertInfo: false,
      isShowVariants: false,
      services: [],
      itemRefs: []
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "salonName",
      "loading",
      "controlsOverlay",
      "getServiceError",
      "getPriceFormat",
      "getChosenServices",
      "getTotalServicesPrice",
      "getTotalServicesPriceString",
      "getTotalServicesPriceFrom",
      "getTotalServicesTime",
      "isSpecialInfo",
      "specialInfo",
      "getChosenServicesData",
      "widgetOptions",
      "previewInformation"
    ]),
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(
          this.widgetOptions.buttonBackground
        ),
        "--vi-main-primary-background": this.widgetOptions.buttonBackground,
        "--vi-main-primary-background-hover":
          this.widgetOptions.buttonBackgroundHover,
        "--vi-main-primary-color": this.widgetOptions.buttonColor
      };
    },
    getStableServices() {
      let reserve = this.$store.getters.getServices;
      let serviceFilters = this.$store.getters.getFilteredServices;
      let employeesFilters = this.$store.getters.getFilteredEmployees;
      let popularServices = { ...this.$store.getters.getServices[0] };
      let packages = {
        ...this.$store.getters.getServices.find((i) => i.is_package)
      };
      let filteredServices = [];

      if (employeesFilters.length > 0) {
        reserve = reserve.map((i) => {
          if (!i.is_package) {
            return {
              ...i,
              services: i.services.filter(
                (k) =>
                  k.employees.filter(
                    (r) => employeesFilters.findIndex((e) => e.id === r.id) > -1
                  ).length !== 0
              )
            };
          } else {
            return {
              ...i,
              packages: i.packages.filter(
                (p) =>
                  p.services.filter(
                    (k) =>
                      k.employees.filter(
                        (r) =>
                          employeesFilters.findIndex((e) => e.id === r.id) > -1
                      ).length !== 0
                  ).length > 0
              )
            };
          }
        });

        popularServices.services = popularServices.services.filter(
          (i) =>
            i.employees.filter(
              (r) => employeesFilters.findIndex((e) => e.id === r.id) > -1
            ).length !== 0
        );

        if (packages.packages) {
          packages.packages = packages.packages.map((p) => {
            return {
              ...p,
              services: p.services
                ? p.services.filter(
                    (i) =>
                      i.employees.filter(
                        (r) =>
                          employeesFilters.findIndex((e) => e.id === r.id) > -1
                      ).length !== 0
                  )
                : []
            };
          });
        }
      }

      filteredServices =
        serviceFilters.length > 0
          ? reserve.filter(
              (i) =>
                serviceFilters.findIndex(
                  (k) => k.group_title === i.group_title
                ) > -1
            )
          : reserve;

      if (serviceFilters.length > 0) {
        popularServices.services = popularServices.services.filter(
          (i) =>
            serviceFilters.findIndex((k) => k.group_title === i.group_title) >
            -1
        );

        if (popularServices.services.length > 0) {
          filteredServices = [popularServices, packages, ...filteredServices];
        }
      }

      return filteredServices
        .map((i) => {
          if (!i.is_package && i.services && i.services.length > 0) {
            return {
              ...i
            };
          }
          return {
            ...i
          };
        })
        .filter((i) => i);
    },
    getEmployeesWithType() {
      let employees = this.$store.getters.getSalonEmployees,
        types = this.$store.getters.getSalonEmployeeTypes;

      let prepared = employees.map((item) => ({
        ...item,
        type: types.filter((i) => i.id === item.employee_type_id)[0].title
      }));

      return prepared;
    }
  },
  mounted() {
    this.setTitleLanguage();
    this.setLoginFromPath("services");
    if (
      this.isSpecialInfo &&
      this.isSpecialInfo !== 0 &&
      this.specialInfo.length > 0
    ) {
      if (!this.widgetOptions.isWidgetModal) {
        this.$bvModal.show(this.idModal);
      } else {
        this.isShowAlertInfo = true;
      }
    }

    // Scroll to chosen item
    if (this.scrollToChoose(this.getChosenServices) > 0) {
      window.scrollTo(0, this.scrollToChoose(this.getChosenServices));
    }
  },
  methods: {
    ...mapMutations([
      "setChosenPackage",
      "removeChosenPackage",
      "setChosenServices",
      "removeChosenServices",
      "removeChosenServicesByVariant",
      "setChosenEmployees",
      "setChosenEmployeesByVariant",
      "offIsSpecialInfo",
      "setLoginFromPath",
      "removeServiceError"
    ]),
    debug(event) {
      console.log("Services", event);
    },
    setTitleLanguage() {
      this.titleModalShowDetails = this.$t("service_details");
    },
    serviceDetails(data) {
      if (data.isPackage) {
        this.titleModalShowDetails = this.$t("package_details");
        this.dataDetails = {
          ...data,
          time: timeFormatter(data.total_duration),
          price: priceFormatter(data.total, this.getPriceFormat)
        };
        this.isShowVariants = false;
        this.$bvModal.show(this.idModalShowDetails);
      } else {
        this.titleModalShowDetails = this.$t("service_details");
        this.dataDetails = data;
        this.isShowVariants = false;
        this.$bvModal.show(this.idModalShowDetails);
      }
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    scrollToChoose(arr) {
      let numArr = [];
      arr.map((item) => {
        if (item.positionY) {
          numArr.push(item.positionY);
        }
      });
      const min = Math.min(...numArr);

      return min - 188;
    },
    addService(item) {
      this.setChosenServices({
        ...item,
        employees: !item.variants[0].employeeIds
          ? item.employees
          : item.employees.filter((i) => {
              let flag = false;
              item.variants[0].employeeIds.map((k) => {
                if (Number(i.id) === k) {
                  flag = true;
                }
              });
              if (flag) {
                return i;
              }
            })
      });
    },
    removeService(item) {
      this.removeChosenServices(item);
    },
    removeVariant(item) {
      this.removeChosenServicesByVariant(item);
    },
    addPackage(item) {
      this.setChosenPackage({
        ...item,
        employees: !item.variants[0].employeeIds
          ? item.employees
          : item.employees.filter((i) => {
              let flag = false;
              item.variants[0].employeeIds.map((k) => {
                if (Number(i.id) === k) {
                  flag = true;
                }
              });
              if (flag) {
                return i;
              }
            })
      });
    },
    removePackage(item) {
      this.removeChosenPackage(item);
    },
    checkAloneEmployees(arr, arrData, setArr, setArrVariant) {
      let flag = false;
      let flagVariant = false;

      arr.map((item) => {
        if (item.employees.length === 1) {
          flag = true;
          arrData.map((k) => {
            if (item.variants[0].id === k.variant_id) {
              flagVariant = true;
            }
          });
          if (flagVariant) {
            setArrVariant({
              variant_id: item.variants[0].id,
              employee_id: item.employees[0].id,
              employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
            });
          } else {
            setArr({
              service_id: item.id,
              employee_id: item.employees[0].id,
              employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
            });
          }
        } else {
          this.$router.push({ name: "staff" });
        }
        if (flag) {
          if (arr.every((i) => i.employees.length === 1)) {
            arrData.map((k) => {
              if (item.variants[0].id === k.variant_id) {
                flagVariant = true;
              }
            });
            if (flagVariant) {
              setArrVariant({
                variant_id: item.variants[0].id,
                employee_id: item.employees[0].id,
                employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
              });
            } else {
              setArr({
                service_id: item.id,
                employee_id: item.employees[0].id,
                employeeName: `${item.employees[0].first_name} ${item.employees[0].last_name}`
              });
            }
            this.$router.push({ name: "slots" });
          } else {
            this.$router.push({ name: "staff" });
          }
        }
      });
    },
    bookNow() {
      this.checkAloneEmployees(
        this.getChosenServices,
        this.getChosenServicesData,
        this.setChosenEmployees,
        this.setChosenEmployeesByVariant
      );
    },
    chooseService(service, title, arr, position) {
      this.$bvModal.hide(this.idModalShowDetails);
      !!arr.find(
        (i) =>
          i.id === service.id &&
          i.variants[0].id === service.variants[0].id &&
          !i.package_id
      )
        ? this.removeService(service)
        : this.addService({
            ...service,
            titleGroup: title,
            positionY: position
          });
    },
    choosePackage(packageItem, title, arr, position) {
      this.$bvModal.hide(this.idModalShowDetails);
      !!arr.find((i) => i.package_id === packageItem.id)
        ? this.removePackage({
            package_id: packageItem.id,
            total_price: packageItem.total,
            total_duration: packageItem.pricings
              .map((i) => i.duration)
              .reduce((a, b) => a + b)
          })
        : packageItem.services.forEach((service) =>
            this.addPackage({
              ...service,
              package_id: packageItem.id,
              total_price: packageItem.total,
              total_duration: packageItem.pricings
                .map((i) => i.duration)
                .reduce((a, b) => a + b),
              titleGroup: packageItem.title,
              positionY: position
            })
          );
    },
    chooseVariant(service, variant, title, arr, position) {
      this.$bvModal.hide(this.idModalShowDetails);
      arr.find((item) => item.variants[0].id === variant.id)
        ? this.removeVariant({
            ...variant,
            type: service.booking
          })
        : this.addService({
            ...service,
            variants: [variant],
            variantDuration: variant.duration,
            variantPrice: variant.price_formatted,
            titleGroup: title,
            positionY: position
          });
    }
  }
};
</script>

<template>
  <div class="vi-main-wrapper--inner">
    <div class="vi-main-placeholder">
      <div class="vi-main-placeholder--image">
        <img :src="imageUrlWrapper(iconOffline)" alt="Offline Booking" />
      </div>
      <div class="vi-main-placeholder--content">
        <h3>{{ $t("bookings_offline") }}</h3>
        <p class="mb-4">
          {{ salonName }} {{ $t("do_not_accept_online_bookings") }}
        </p>
      </div>
      <div v-if="salonPhone" class="vi-main-placeholder--btn pt-3">
        <a :href="`tel:${salonPhone}`" class="vi-btn btn-primary btn-full">
          {{ $t("booking_call_button") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { imageWrapper } from "../../utils/common/common";
import iconOffline from "../../assets/img/icon-offline.svg";
import { mapGetters } from "vuex";

export default {
  name: "BookingOffline",
  data() {
    return {
      iconOffline
    };
  },
  computed: {
    ...mapGetters(["salonName", "salonPhone"])
  },
  mounted() {
    this.$router.push({ name: "home" });
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

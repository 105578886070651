<template>
  <g-signin-button
    :params="googleSignInParams"
    :class="loadingGoogle ? 'btn-loader' : ''"
    :disabled="loadingGoogle"
    class="vi-btn btn-google btn-full"
    @success="onSignInSuccess"
    @error="onSignInError"
  >
    <span class="icon-wrap"><img :src="imageUrlWrapper(img)" /></span>
    {{ btnText }}
    <b-spinner v-if="loadingGoogle" small />
  </g-signin-button>
</template>

<script>
import { imageWrapper } from "../../utils/common/common.js";
import imgLink from "./google.svg";
import { mapGetters } from "vuex";

export default {
  name: "GoogleLogin",
  props: {
    btnText: {},
    onSuccess: {}
  },
  data() {
    return {
      /**
       * The Auth2 parameters, as seen on
       * https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams.
       * As the very least, a valid client_id must present.
       * @type {Object}
       */
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID
      },
      img: imgLink
    };
  },
  computed: {
    ...mapGetters(["salonId", "loadingGoogle", "loginFromPath"])
  },
  methods: {
    debug(event) {
      console.log("google", event);
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    async onSignInSuccess(googleUser) {
      const response = googleUser.getAuthResponse();

      await this.$store.dispatch("loginToGoogle", {
        salon: this.salonId,
        tokenId: response.id_token
      });

      if (this.loginFromPath === "services") {
        this.redirect("home");
      } else if (this.loginFromPath) {
        this.redirect(this.loginFromPath);
      } else {
        this.redirect("home");
      }
    },
    onSignInError(error) {
      console.log(error);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

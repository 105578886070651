<template>
  <div
    v-show="
      isShow ||
      (widgetOptions.previewVersion &&
        widgetOptions.previewVersionPage === 'service')
    "
    :class="
      salonIsPremium ||
      (previewInformation.settings && !!previewInformation.settings.is_premium)
        ? 'vi-step-navigation-top'
        : ''
    "
    class="vi-step-navigation"
  >
    <b-container>
      <div class="row">
        <div class="col-8 vi-step-navigation--tools">
          <button
            v-if="hasPrevious"
            type="button"
            class="vi-step-navigation--tools-btn"
            @click="!widgetOptions.previewVersion && goBack(goToPrev)"
          >
            <b-icon-arrow-left />
          </button>
          <div class="vi-step-navigation--tools-heading">
            <div class="vi-step-navigation--tools-heading-title">
              {{
                widgetOptions.previewVersion &&
                widgetOptions.previewVersionPage === "service"
                  ? $t("select_service")
                  : title
              }}
            </div>
            <span
              v-if="subTitle"
              class="vi-step-navigation--tools-heading-subtitle"
              >{{ subTitle }}</span
            >
            <span
              v-if="subTitle && isTimer"
              class="vi-step-navigation--tools-heading-subtitle"
              >&nbsp;|&nbsp;</span
            >
            <vi-timer
              v-if="isTimer"
              :vi-class="'vi-step-navigation--tools-heading-subtitle'"
            />
          </div>
        </div>
        <div class="col-4 vi-step-navigation--auth">
          <div
            v-if="isLogin || isLogged()"
            :class="isLogged() || widgetOptions.previewVersion ? 'logged' : ''"
            class="vi-filters--btn"
            @click="!widgetOptions.previewVersion && showModalLogin()"
          >
            <span class="vi-filters--btn-text">
              {{ isLogged() ? $t("wl_logged_in") : $t("wl_log_in") }}
            </span>
          </div>
        </div>

        <Modal :id="idModal" :title="titleModal">
          <ul v-if="data" class="vi-nav-list">
            <li
              v-for="(item, index) in data"
              :key="index"
              class="vi-nav-list--item"
            >
              <b-link
                class="d-flex justify-content-between align-items-center"
                @click="handlerNav(item.href)"
              >
                {{ item.title }}
                <i class="ic-vi-arr-right" />
              </b-link>
            </li>
          </ul>
        </Modal>

        <modal-confirm :id="idModalConfirmLose">
          <div class="vi-confirm-message">
            <div class="vi-confirm-message--image">
              <img :src="imageUrlWrapper(error)" alt="Remove Icon" />
            </div>
            <div class="vi-confirm-message--title">
              <h4 class="fw-bold">
                {{ $t("are_you_want_to_cancel_the_payment") }}
              </h4>
            </div>
            <div class="vi-confirm-message--description">
              <p>
                {{ $t("if_you_go_to_another_page_booking_will_be_canceled") }}
              </p>
            </div>
            <div class="vi-confirm-message--btn">
              <vi-button :vi-class="'btn-w-75'" @click="closeAllModal">
                {{ $t("no") }}
              </vi-button>
              <vi-button
                :vi-variant="'danger'"
                :vi-class="'btn-w-75'"
                @click="cancelPayment"
              >
                {{ $t("yes") }}
              </vi-button>
            </div>
          </div>
        </modal-confirm>
      </div>
    </b-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { i18n } from "../../i18n";
import {
  apiUrl,
  getReturningClient,
  getToken,
  imageWrapper
} from "../../utils/common/common.js";
import Modal from "@/components/Modal/Modal.vue";
import ModalConfirm from "../Modal/ModalConfirm";
import error from "../../assets/img/error.svg";
import ViButton from "../common/ViButton";
import ViTimer from "../common/ViTimer";

export default {
  components: {
    ViTimer,
    ViButton,
    Modal,
    ModalConfirm
  },
  data() {
    return {
      idModal: "accountPopup",
      idModalConfirmLose: "ConfirmLoseModal",
      titleModal: "",
      error,
      appointedPath: "",

      isShow: false,
      isLogin: true,
      hasPrevious: true,
      goToPrev: "/",
      title: "App",
      isTimer: false,
      subTitle: null
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "salonName",
      "getCustomer",
      "getServiceError",
      "pastUrl",
      "salonIsPremium",
      "loginFromPath",
      "widgetOptions",
      "previewInformation"
    ]),
    loginData() {
      return [
        {
          title: i18n.t("login"),
          href: "/login"
        },
        {
          title: i18n.t("registration"),
          href: "/registration"
        }
      ];
    },
    loggedData() {
      return [
        {
          title: i18n.t("make_appointment"),
          href: "/services"
        },
        {
          title: i18n.t("your_appointment"),
          href: "/your-appointments"
        },
        {
          title: i18n.t("your_details"),
          href: "/your-details"
        },
        {
          title: i18n.t("data_preferences"),
          href: "/data-preferences"
        },
        {
          title: i18n.t("logout"),
          href: "/logout"
        }
      ];
    },
    data() {
      return this.getCustomer ? this.loggedData : this.loginData;
    }
  },
  watch: {
    $route(to, from) {
      this.routerCheck(to.path, from);
      if (from && from.name === "gratuity") {
        this.subTitle = null;
      }
      if (to.name === "staff" && from.name === "your-appointments") {
        this.setTabPastAppointment(true);
      }
    }
  },
  mounted() {
    this.titleModal = this.$t("account");
    this.routerCheck(this.$route.path);
  },
  methods: {
    ...mapMutations(["runTimer", "setPastUrl", "setTabPastAppointment"]),
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    async redirect(url) {
      this.$bvModal.hide(this.idModal);
      if (this.$route.path !== url) {
        switch (url) {
          case "/":
            this.$router.push(url);
            break;
          case "/registration":
            if (this.isReturnClient) {
              this.$router.push({
                name: "registration",
                params: { isReturnClient: true }
              });
            } else {
              this.$router.push(url);
            }
            break;
          case "/login":
            if (this.isReturnClient) {
              this.$router.push({
                name: "login",
                params: { isReturnClient: true }
              });
            } else {
              this.$router.push(url);
            }
            break;
          case "/logout":
            await this.$store.dispatch("logout");
            break;
          default:
            await this.$router.push(url);
        }
      }
    },
    routerCheck(path, from) {
      this.setPastUrl(from);

      switch (path) {
        case "/":
          this.isShow = false;
          break;

        case "/services":
          this.isShow = true;
          this.isLogin = true;
          this.hasPrevious = true;
          this.isTimer = false;
          this.title = this.$t("select_service");
          this.subTitle = null;
          this.goToPrev = "home";
          break;

        case "/staff":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = true;
          this.isTimer = false;
          this.title = this.$t("choose_staff");
          this.subTitle = null;
          this.goToPrev =
            this.loginFromPath === "returning-client"
              ? this.loginFromPath
              : this.loginFromPath === "your-appointments"
              ? this.loginFromPath
              : "services";
          break;

        case "/slots":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = true;
          this.title = this.$t("select_slot");
          this.subTitle = null;
          this.isTimer = false;

          if (from) {
            if (from.name === "pre-save") {
              this.goToPrev = "services";
            } else {
              this.goToPrev = from.name;
            }
          } else {
            this.goToPrev = "staff";
          }
          break;

        case "/pre-save":
          this.isShow = !getToken() && true;
          this.hasPrevious = true;
          this.isLogin = false;
          this.title = this.$t("create_account");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "slots";
          break;

        case "/add-card":
          this.isShow = true;
          this.hasPrevious = true;
          this.isLogin = false;
          this.title = this.$t("add_card");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "slots";
          break;

        case "/gratuity":
          this.isShow = true;
          this.hasPrevious = true;
          this.title = `${
            this.getCustomer ? this.getCustomer.first_name : ""
          } ${this.getCustomer ? this.getCustomer.last_name : ""}`;
          this.subTitle = `${this.$t("pay")} ${this.salonName}`;
          this.isTimer = true;
          this.goToPrev = "pre-save";
          break;

        case "/confirm-payment":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = false;
          this.title = this.$t("payment");
          // this.subTitle = `${this.$t("pay")} ${this.salonName}`;
          this.isTimer = true;
          this.goToPrev = "pre-save";
          break;

        case "/auth":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = true;
          this.title = this.$t("login_to_your_account");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "home";
          break;

        case "/logged-out":
          this.isShow = false;
          this.hasPrevious = false;
          this.title = this.$t("login_to_your_account");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "home";
          break;

        case "/logged-out-now":
          this.isShow = false;
          this.hasPrevious = false;
          this.title = this.$t("login_to_your_account");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "home";
          break;

        case "/login":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = true;
          this.title = this.$t("login_to_your_account");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev =
            this.loginFromPath === "returning-client"
              ? "home"
              : this.loginFromPath
              ? this.loginFromPath
              : this.pastUrl
              ? this.pastUrl.name === "registration"
                ? "home"
                : this.pastUrl.name
              : "home";
          break;

        case "/registration":
          this.isShow = true;
          this.isLogin = false;
          this.hasPrevious = true;
          this.title = this.$t("reg_sign_up");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = this.pastUrl ? this.pastUrl.name : "home";
          break;

        case "/timeout":
          this.isShow = false;
          break;

        case "/booking-created":
          this.isShow = false;
          break;

        case "/returning-client":
          this.isShow = true;
          this.isLogin = true;
          this.hasPrevious = false;
          this.title = this.$t("welcome_back");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev = "home";
          break;

        case "/your-appointments":
          this.isShow = true;
          this.isLogin = true;
          this.hasPrevious = true;
          this.title = this.$t("your_appointment");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev =
            getReturningClient() === 1 ? "returning-client" : "services";
          break;

        case "/your-details":
          this.isShow = true;
          this.isLogin = true;
          this.hasPrevious = true;
          this.title = this.$t("your_details");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev =
            getReturningClient() === 1 ? "returning-client" : "services";
          break;

        case "/data-preferences":
          this.isShow = true;
          this.isLogin = true;
          this.hasPrevious = true;
          this.title = this.$t("data_preferences");
          this.subTitle = null;
          this.isTimer = false;
          this.goToPrev =
            getReturningClient() === 1 ? "returning-client" : "services";
          break;

        default:
          this.isShow = false;
      }
    },
    goBack(url) {
      this.$router.push({ path: url });

      if (url === "slots") {
        this.runTimer(false);
      }
    },
    isLogged() {
      return getToken();
    },
    handlerNav(url) {
      if (
        this.$route.path !== "/confirm-payment" &&
        this.$route.path !== "/gratuity"
      ) {
        this.redirect(url);
      } else {
        this.$bvModal.show(this.idModalConfirmLose);
        this.appointedPath = url;
      }
    },
    showModalLogin() {
      this.$bvModal.show(this.idModal);
    },
    closeAllModal() {
      this.$bvModal.hide(this.idModalConfirmLose);
      this.$bvModal.hide(this.idModal);
    },
    cancelPayment() {
      this.redirect(this.appointedPath);
      this.runTimer(false);
      this.$bvModal.hide(this.idModalConfirmLose);
      this.$store.dispatch("removePaymentMethod", { id: this.salonId });
    }
  }
};
</script>

<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div v-if="values" class="vi-list-switcher pt-4">
        <div
          v-for="(title, key) in preference"
          :key="key"
          class="vi-switcher-wrapper mb-3"
        >
          <Toggle
            v-model="values[key]"
            :label="title"
            :value="values[key]"
            :name="key"
            :on-update-toggle="onUpdateToggle"
          />
        </div>
      </div>
      <div class="pt-3">
        <span class="vi-link-primary" @click="openPrivacyPolice">{{
          $t("privacy_policy")
        }}</span>
      </div>
    </div>

    <Modal :id="idModalPolice" :title="titleModalPolice">
      <div class="vi-typography pt-2 pb-2">
        <p>{{ privacyPolicy }}</p>
      </div>
    </Modal>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import StepNavigation from "../StepNavigation/StepNavigation";
import Toggle from "../Toggle/Toggle";
import calendar from "../../assets/img/calendar.svg";
import calendarActive from "../../assets/img/calendar-active.svg";
import { apiUrl, getCustomerData } from "../../utils/common/common";
import Modal from "../Modal/Modal";
export default {
  components: {
    StepNavigation,
    Modal,
    Toggle
  },
  props: {
    type: {}
  },
  data() {
    return {
      idModalPolice: "policeModal",
      titleModalPolice: "Privacy Police",
      calendar,
      calendarActive,
      values: null
    };
  },
  computed: {
    ...mapGetters(["salonId", "getCustomer", "privacyPolicy"]),
    preference() {
      return {
        notif_email: this.$t("receive_email_notifications"),
        notif_trans_email: this.$t("receive_transactional_email"),
        notif_sms: this.$t("receive_sms_notifications"),
        notif_trans_sms: this.$t("receive_transactional_sms"),
        nps_survey_email: this.$t("receive_satisfaction_email"),
        medical_consent: this.$t("consent_medical_information")
      };
    }
  },
  watch: {
    getCustomer(customer, oldValue) {
      if (customer && customer !== oldValue) {
        this.initValues();
      }
    }
  },
  created() {
    this.initValues();
  },
  methods: {
    initValues() {
      if (this.getCustomer) {
        this.values = {
          notif_email: !!this.getCustomer.notif_email,
          notif_trans_email: !!this.getCustomer.notif_trans_email,
          notif_sms: !!this.getCustomer.notif_sms,
          notif_trans_sms: !!this.getCustomer.notif_trans_sms,
          nps_survey_email: !!this.getCustomer.nps_survey_email,
          medical_consent: !!this.getCustomer.medical_consent
        };
      }

      if (!getCustomerData()) {
        this.redirect("auth");
      }
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    openPrivacyPolice() {
      this.$bvModal.show(this.idModalPolice);
    },
    goToPrev() {
      this.$router.push({ name: "home" });
    },
    onUpdateToggle(value) {
      this.values[value.name] = value.value;
      this.send();
    },
    async send() {
      this.$store.commit("isLoading");

      try {
        await this.axios.post(
          `${apiUrl("booking/customer-data-preferences")}/${this.salonId}`,
          this.values
        );

        localStorage.setItem(
          "customer",
          JSON.stringify({ ...getCustomerData(), ...this.values })
        );

        this.$store.commit("isLoaded");
      } catch (e) {
        console.log(e);
        this.$store.commit("isLoaded");
      }
    },
    redirect(url) {
      this.$router.push({ name: url });
    }
  }
};
</script>

<template>
  <div>
    <template v-if="appointments && appointments && appointments.length > 0">
      <div v-for="(item, index) in appointments" :key="index">
        <div class="card-appointment-existing">
          <div class="card-appointment-existing--wrap">
            <h5>{{ item.title }}</h5>
            <p class="vi-text-secondary">{{ item.group_title }}</p>
            <p class="card-appointment-existing--wrap-time">
              {{ item.duration }} | {{ item.employee }}
            </p>
            <div class="card-appointment-existing--wrap-date">
              <img :src="imageUrlWrapper(calendarActive)" alt="calendar" />
              {{ item.date }}
            </div>
            <div class="vi-text-secondary">
              {{ item.status }}
            </div>
            <div class="card-appointment-existing--wrap-price">
              <span class="price">{{ item.price || 0 }}</span>
              <span
                v-if="item.cancel_possibility"
                class="vi-link-danger"
                @click="onClickCancel(item)"
                >{{ $t("cancel_appointment") }}</span
              >
              <!--              <span v-else class="vi-text-secondary">{{ $t("canceled") }}</span>-->
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="bellatia-future-noappointment-main pt-5">
      <div
        class="
          d-flex
          align-items-center
          justify-content-center
          flex-column
          bellatia-future-noappointment-wrap
        "
      >
        <img
          :src="imageUrlWrapper(calendar)"
          class="bellatia-future-calendar"
          alt="calendar"
        />
        <h6 class="bellatia-future-noappointment">
          {{
            tab === futureAppointmentTab
              ? $t("no_future_appointments")
              : $t("no_past_appointments")
          }}
        </h6>
      </div>
    </div>

    <ModalConfirm :id="idModal">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(error)" alt="Remove Icon" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>Are you sure you want to cancel the booking?</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <button
            class="vi-btn btn-primary btn-w-75"
            @click="$bvModal.hide(idModal)"
          >
            {{ $t("no") }}
          </button>
          <button class="vi-btn btn-danger btn-w-75" @click="cancelRequest()">
            {{ $t("yes") }}
          </button>
        </div>
      </div>
    </ModalConfirm>
    <ModalConfirm :id="idModalSuccess">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(success)" alt="Remove Icon" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>Appointment has been cancelled</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <button
            class="vi-btn btn-primary btn-w-75"
            @click="$bvModal.hide(idModalSuccess)"
          >
            {{ $t("ok") }}
          </button>
        </div>
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import calendar from "../../assets/img/calendar.svg";
import error from "../../assets/img/error.svg";
import success from "../../assets/img/error.svg";
import calendarActive from "../../assets/img/calendar-active.svg";
import { imageWrapper, apiUrl } from "../../utils/common/common.js";
import ModalConfirm from "@/components/Modal/ModalConfirm.vue";

const FUTURE_APPOINTMENTS = "future";
const PAST_APPOINTMENTS = "past";

export default {
  components: {
    ModalConfirm
  },
  props: {
    tab: {},
    appointments: {}
  },
  data() {
    return {
      idModal: "confirmRemovePopup",
      idModalSuccess: "confirmSuccessPopup",
      calendar,
      error,
      success,
      calendarActive,
      canceledItemId: null
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "getFutureAppointments",
      "getFutureAppointmentsData"
    ]),
    futureAppointmentTab() {
      return FUTURE_APPOINTMENTS;
    },
    pastAppointmentTab() {
      return PAST_APPOINTMENTS;
    }
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    onClickCancel(item) {
      this.$bvModal.show(this.idModal);
      this.canceledItemId = item.id;
    },
    async cancelRequest() {
      return new Promise(async (resolve, reject) => {
        const id = this.canceledItemId;
        this.$store.commit("isLoading");
        try {
          await this.axios.post(
            `${apiUrl("booking/cancel-appointment")}/${this.salonId}`,
            { id }
          );

          this.$emit("remove-item", id);
          this.$store.commit("isLoaded");

          this.$bvModal.hide(this.idModal);
          this.$bvModal.show(this.idModalSuccess);
          resolve();
        } catch (e) {
          this.$store.commit("isLoaded");
          reject(e);
        }
      });
    }
  }
};
</script>

export default {
  // Settings
  widgetOptions(state) {
    return state.widgetOptions;
  },
  getSettings(state) {
    return state.settings;
  },
  controlsOverlay(state) {
    return state.widgetOptions.controlsOverlay;
  },
  previewInformation(state) {
    return state.previewInformation;
  },

  // Global
  timer(state) {
    return state.timer;
  },
  pastUrl(state) {
    return state.pastUrl;
  },
  loginFromPath(state) {
    return state.loginFromPath;
  },
  getPriceFormat(state) {
    return {
      priceDecimalPlaces: state.salon.price_decimal_places,
      decimalSeparator: state.salon.decimal_separator,
      currencySymbol: state.salon.currency_symbol,
      currencySymbolPosition: state.salon.currency_symbol_position
    };
  },
  authHeaderReady(state) {
    return state.authHeaderReady;
  },
  showGoogleLoginButton(state) {
    return state.showGoogleLoginButton;
  },
  limitBooking(state) {
    return state.limitBooking;
  },
  mainLogoIsHide(state) {
    return state.mainLogoIsHide;
  },

  // Loaders
  loading(state) {
    return state.loading;
  },
  loadingApp(state) {
    return state.loadingApp;
  },
  loadingMore(state) {
    return state.loadingMore;
  },
  loadingButton(state) {
    return state.loadingButton;
  },
  loadingGoogle(state) {
    return state.loadingGoogle;
  },

  // Salon
  salon(state) {
    return state.salon;
  },
  salonId(state) {
    return state.salonId;
  },
  salonISOCode(state) {
    return state.salon.country_iso_code;
  },
  salonName(state) {
    return state.salon.title;
  },
  salonAddress(state) {
    return state.salon.address;
  },
  salonPhone(state) {
    return state.salon.phone;
  },
  salonIsPremium(state) {
    return state.salon.is_premium;
  },
  idGoogleAnalytics(state) {
    if (state.salon.ga_widget) {
      return state.salon.ga_widget;
    } else {
      return null;
    }
  },
  isPayments(state) {
    // return false;
    return state.salon.isPayments;
  },
  paymentVariant(state) {
    return state.salon.payment_variant;
  },
  salonLogo(state) {
    return state.salon.logo_url;
  },
  language(state) {
    return state.language;
  },
  startOfWeek(state) {
    return state.salon.start_of_week;
  },
  salonTimeZone(state) {
    return state.salon.timezone;
  },
  privacyPolicy(state) {
    return state.salon.cancellation_policy;
  },
  getSalonError(state) {
    return state.errorSalonLoad;
  },
  specialInfo(state) {
    return state.salon.special_information;
  },
  isSpecialInfo(state) {
    return state.salon.special_information_booking;
  },

  // Customer
  isLoggedCustomer(state) {
    return state.isLoggedCustomer;
  },
  getCustomer(state) {
    return state.customer;
  },
  enablePolicy(state) {
    return state.salon.enable_policy;
  },
  marketEmails(state) {
    return state.salon.market_emails;
  },
  marketSms(state) {
    return state.salon.market_sms;
  },
  medicalConsent(state) {
    return state.salon.medical_consent;
  },
  isShowPasswordForgotModal(state) {
    return state.isShowPasswordForgotModal;
  },
  isCustomerPhoneConfirmed(state) {
    return state.isCustomerPhoneConfirmed;
  },

  // Filters
  getFilteredServices(state) {
    return state.servicesFiltered;
  },
  getFilteredEmployees(state) {
    return state.filteredEmployees;
  },

  // Services
  getServices(state) {
    return state.services;
  },
  getServicesBasket(state) {
    return state.servicesBasket;
  },
  getChosenServices(state) {
    return state.chosenServices;
  },
  getChosenServicesData(state) {
    return state.chosenServicesData;
  },
  getTotalServicesPrice(state) {
    return state.totalServicesPrice;
  },
  getTotalServicesPriceString(state) {
    return state.totalServicesPriceString;
  },
  getTotalServicesPriceFrom(state) {
    return state.totalServicesPriceFrom;
  },
  getTotalServicesTime(state) {
    return state.totalServicesTime;
  },
  getSavedServices(state) {
    return state.savedServices;
  },
  getLastServices(state) {
    return state.lastServices;
  },
  getServiceError(state) {
    return state.serviceError;
  },
  getServiceErrorName(state) {
    return state.serviceErrorName;
  },

  // Employees
  getSalonEmployees(state) {
    return state.venueEmployees;
  },
  getSalonEmployeeTypes(state) {
    return state.venueEmployeeTypes;
  },
  selectedEmployeeByQuery(state) {
    return state.selectedEmployeeByQuery;
  },

  // Slots
  getAvailableSlots(state) {
    return state.availableSlots;
  },
  loadingNextAvailableSlots(state) {
    return state.isLoadingNextAvailableSlots;
  },
  getNextAvailableSlots(state) {
    return state.nextAvailableSlots;
  },
  getNextAvailableSlotsDate(state) {
    return state.nextAvailableSlotsDate;
  },
  getGlobalDateSlots(state) {
    return state.globalDateSlots;
  },
  getSlotsFromBasket(state) {
    return state.chosenSlot.slots;
  },
  getTimeOrder(state) {
    return {
      time_formatted: state.chosenSlot.slots[0]
        ? state.chosenSlot.slots[0].time_formatted
        : "",
      time: state.chosenSlot.time,
      date: state.chosenSlot.date
    };
  },
  getErrorSlot(state) {
    return state.errorSlot;
  },

  // Appointments
  isPastAppointment(state) {
    return state.isPastAppointment;
  },
  getFutureAppointments(state) {
    return state.futureAppointments;
  },
  getPastAppointments(state) {
    return state.pastAppointments;
  },
  getFutureAppointmentsData(state) {
    return state.futureAppointmentsData;
  },

  // Payment
  getTime(state) {
    return state.time;
  },
  getStaff(state) {
    return state.staff;
  },
  getBookingNote(state) {
    return state.bookingNote;
  },
  getPaymentIntent(state) {
    return state.paymentIntent;
  },
  totalPriceBooking(state) {
    return state.totalPriceBooking;
  },
  selectedPaymentVariant(state) {
    return state.selectedPaymentVariant;
  },
  deposit(state) {
    return state.deposit;
  },

  selectedIds(state) {
    return state.selectedIds;
  }
};

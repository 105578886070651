import Vue from "vue";
import store from "./store/index";
import App from "./App.vue";
import router from "./router";
import Router from "vue-router";
import VueGtag from "vue-gtag";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// plugins
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "./assets/css/main.scss";
import GSignInButton from "vue-google-signin-button";

// widget setup
// (optional) 'Custom elements polyfill'
import "document-register-element/build/document-register-element";

import vueCustomElement from "vue-custom-element";
import IdleVue from "idle-vue";
import axios from "axios";
import { i18n } from "./i18n";
import VueAxios from "vue-axios";
import { StripePlugin } from "@vue-stripe/vue-stripe";
const eventsHub = new Vue();

export const eventBus = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 3000, // 300000 5 min
  store,
  startAtIdle: false
});

Vue.use(vueCustomElement);

Vue.use(GSignInButton);
Vue.use(VueAxios, axios);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue, {
  BModal: {
    okTitle: i18n.t("ok"),
    cancelTitle: i18n.t("cancel")
  }
});
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.config.productionTip = false;

// window.stripe init
let stripeInit = document.createElement("script");
stripeInit.setAttribute("src", "https://js.stripe.com/v3");
document.head.appendChild(stripeInit);

let googleBtnScript = document.createElement("script");
googleBtnScript.setAttribute("src", "https://apis.google.com/js/api:client.js");
document.head.appendChild(googleBtnScript);

Vue.use(Router);

Sentry.init({
  Vue,
  dsn: "https://9a9a751029f344f9b92d7bdd38fb06ce@sentry.intelliants.net/5",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "belliata.com", /^\//]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

// use vue-custom-element
App.store = store;
App.router = router;
App.i18n = i18n;

Vue.customElement("vue-widget", App);

Vue.use(VueGtag, {
  bootstrap: false
});

new Vue({ render: (h) => h(App) });

<template>
  <b-modal
    :id="idModal"
    :title="$t('forgot')"
    v-model="isShowPasswordForgotModal"
    scrollable
    centered
    hide-footer
    no-enforce-focus
    modal-class="vi-main-modal vi-b-modal"
    header-class="vi-b-modal--header"
    body-class="vi-b-modal--body"
    footer-class="vi-b-modal--footer"
    @hide="hideModal"
  >
    <div :style="styleObject">
      <form
        v-if="!formValid"
        class="login-form mt-4"
        @submit.prevent="forgotPassword"
      >
        <p class="vi-text-secondary" v-html="$t('forgot_description')"></p>
        <form-input
          v-model="values.email"
          :error="errors.email"
          :last-letter-red="'*'"
          :label="$t('your_email')"
          type="email"
          name="email"
          @validate="validate('email')"
        />
        <button
          :class="loading ? 'btn-loader' : ''"
          :disabled="loading"
          class="vi-btn btn-primary btn-full"
          type="submit"
        >
          {{ $t("send_me_instructions") }}

          <b-spinner v-if="loading" small />
        </button>
      </form>
      <div v-else class="pt-4 text-center">
        <div class="text-center mb-3">
          <img :src="imageUrlWrapper(img)" alt="" />
        </div>
        <h6 class="vi-title">
          {{ $t("check_your_mail") }}
        </h6>
        <p class="vi-text-secondary">
          {{ $t("we_have_sent_password_recover_instructions") }}
        </p>
        <button class="vi-btn btn-primary" type="button" @click="hideModal">
          {{ $t("ok") }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { object, string } from "yup";
import FormInput from "@/components/FormInput/FormInput.vue";
import { imageWrapper, apiUrl } from "@/utils/common/common";
import imgLink from "../../assets/img/mail.svg";
import { i18n } from "@/i18n/index";
import Modal from "../Modal/Modal";
import { mapGetters, mapMutations } from "vuex";
import { hexToRgb } from "../../utils/common/common";

const forgotPasswordFormSchema = object().shape({
  email: string()
    .email(i18n.t("email_must_be_valid"))
    .required(i18n.t("this_field_is_required"))
});
export default {
  name: "ForgotPasswordModal",
  components: {
    Modal,
    FormInput
  },
  data() {
    return {
      idModal: "forgotPassword",
      loading: false,
      values: {
        email: ""
      },
      errors: {
        email: ""
      },
      formValid: false,
      img: imgLink
    };
  },
  computed: {
    ...mapGetters(["isShowPasswordForgotModal", "widgetOptions"]),
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(
          this.widgetOptions.buttonBackground
        ),
        "--vi-main-primary-background": this.widgetOptions.buttonBackground,
        "--vi-main-primary-background-hover":
          this.widgetOptions.buttonBackgroundHover,
        "--vi-main-primary-color": this.widgetOptions.buttonColor
      };
    }
  },
  methods: {
    ...mapMutations(["setShowPasswordForgotModal"]),
    hideModal() {
      this.setShowPasswordForgotModal(false);
    },
    forgotPassword() {
      forgotPasswordFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.loading = true;
          try {
            await this.axios.post(apiUrl("booking/forgot-password"), {
              email: this.values.email,
              url: location.href.split("#")[0]
            });
            this.formValid = true;
            this.loading = false;
          } catch (e) {
            if (e.response) {
              if (e.response.data.error) {
                this.errors.email = e.response.data.error;
              }
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validate(field) {
      forgotPasswordFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

<template>
  <div id="app" ref="appMain" :style="styleObject">
    <div
      :style="{
        paddingBottom: !controlsOverlay ? 80 - marginBottom + 'px' : 0
      }"
      :class="previewVersion ? '' : ''"
      class="vi-main-wrapper"
    >
      <!-- Loader -->
      <div
        v-show="loadingApp"
        :class="!controlsOverlay ? 'fixed-loader' : ''"
        class="vi-main-wrapper--loader"
      >
        <img :src="imageUrlWrapper(imgLogo)" alt="Belliata" />
        <vi-spinner />
      </div>

      <!-- Header -->
      <Header v-if="!loadingApp" />
      <!-- Nav -->
      <StepNavigation />

      <!-- Content -->
      <div v-if="!previewVersion">
        <div v-if="!getSalonError">
          <router-view v-if="!loadingApp" />
        </div>
        <!-- Booking Offline Content -->
        <BookingOffline v-else />
      </div>
      <div v-else>
        <Services v-if="previewVersionPage === 'service'" />
        <Home v-else />
      </div>

      <!-- Modal Confirm Phone -->
      <ModalConfirmPhone
        :id="idModalConfirmPhone"
        :title="titleModalConfirmPhone"
      />

      <modal-confirm :id="idModalDetectWebView" no-close-on-backdrop>
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(iconAttantion)" alt="Attention" />
          </div>
          <div class="vi-confirm-message--title">
            <h4>{{ detectBrowserInfo.text }}</h4>
          </div>
          <div
            v-if="detectBrowserInfo.platform === 'android'"
            class="vi-confirm-message--btn w-100 pt-2"
          >
            <a
              :href="detectBrowserInfo.redirect + '?gbOpenExternal=1'"
              target="_blank"
              rel="noreferrer"
              download
              class="vi-btn btn-primary btn-full"
            >
              {{ $t("open_browser") }}
            </a>
          </div>
        </div>
      </modal-confirm>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgLogo from "./assets/img/logo-dark.svg";
import previewLogo from "./assets/img/icon-preview-belliata.svg";

import Header from "@/components/Header/Header.vue";
import StepNavigation from "@/components/StepNavigation/StepNavigation.vue";
import {
  getCustomerData,
  getToken,
  hexToRgb,
  imageWrapper,
  loadIconfont,
  removeAuthData
} from "./utils/common/common";
import BookingOffline from "./components/BookingOffline/BookingOffline";
import moment from "moment";
import ModalConfirmPhone from "./components/Modal/ModalConfirmPhone";
import { setOptions, bootstrap } from "vue-gtag";
import ViSpinner from "./components/common/ViSpinner";
import Home from "./views/Home";
import Services from "./views/Services/Services";
import ModalConfirm from "./components/Modal/ModalConfirm";
import iconAttantion from "./assets/img/icon-attantion.svg";
import ViButton from "./components/common/ViButton.vue";

export default {
  components: {
    ViButton,
    ModalConfirm,
    ViSpinner,
    Header,
    Home,
    Services,
    BookingOffline,
    ModalConfirmPhone,
    StepNavigation
  },
  props: {
    salon: {
      required: true
    },
    lang: {
      default: "en-gb"
    },
    controlsOverlay: {
      default: false
    },
    paddingControlsBottom: {
      type: Number,
      default: 0
    },
    isPremiumPreview: {
      default: false
    },
    previewVersion: {
      default: false
    },
    previewVersionPage: {
      type: String
    },
    buttonBackground: {
      default: "#45caae"
    },
    buttonBackgroundHover: {
      default: "#3aaf95"
    },
    buttonColor: {
      default: "#ffffff"
    },
    previewLogo: {
      default: null
    },
    hideMainLogo: {
      type: Boolean,
      default: false
    },
    isWidgetModal: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: "widget"
    },
    marginBottom: {
      default: 20
    },
    serviceId: {
      type: [Number, String]
    },
    employeeId: {
      type: [Number, String]
    }
  },
  data() {
    return {
      imgLogo,
      iconAttantion,
      initPreviewLogo: previewLogo,
      activeTab: "",
      idModalDetectWebView: "detectWebviewModal",
      idModalConfirmPhone: "confirmPhoneModal",
      titleModalConfirmPhone: "Please add information",
      detectBrowserInfo: {
        redirect: "",
        platform: "",
        text: ""
      }
    };
  },
  computed: {
    ...mapGetters([
      "timer",
      "salonId",
      "salonName",
      "loadingApp",
      "idGoogleAnalytics",
      "getSalonError",
      "isCustomerPhoneConfirmed",
      "previewInformation"
    ]),
    styleObject() {
      return {
        "--vi-main-primary-background-rgba": hexToRgb(this.buttonBackground),
        "--vi-main-primary-background": this.buttonBackground,
        "--vi-main-primary-background-hover": this.buttonBackgroundHover,
        "--vi-main-primary-color": this.buttonColor
      };
    }
  },
  watch: {
    previewLogo: {
      deep: true,
      handler(newPreviewLogo) {
        if (newPreviewLogo) {
          this.initPreviewLogo = newPreviewLogo;
          this.loadOptions();
        } else {
          this.initPreviewLogo = this.imageUrlWrapper(this.initPreviewLogo);
        }
      }
    },
    isCustomerPhoneConfirmed: {
      handler(newIsCustomerPhoneConfirmed) {
        if (newIsCustomerPhoneConfirmed) {
          this.$bvModal.show(this.idModalConfirmPhone);
        } else {
          this.$bvModal.hide(this.idModalConfirmPhone);
        }
      }
    },
    timer: {
      deep: true,
      handler(newTimer) {
        if (newTimer.countdown === 0) {
          console.log("Finish", moment().format("h:mm:ss"));
          this.$store.dispatch("removePaymentMethod", { id: this.salonId });
          this.$router.push({ name: "timeout" });
          this.runTimer(false);
        }
      }
    },
    $route(to) {
      this.goto();
      this.track(this.salonName, this.salonId, to);
    }
  },
  async created() {
    if (this.previewLogo) {
      this.initPreviewLogo = this.previewLogo;
    } else {
      this.initPreviewLogo = this.imageUrlWrapper(this.initPreviewLogo);
    }

    this.initSourceHeaders();
    this.loadOptions();
    this.setTitleModalConfirmPhone();

    // Obtain salon info
    await this.setId(this.salon);
    await this.setLang(this.lang);
    await this.handlerHideMainLogo(this.hideMainLogo);

    if (this.serviceId || this.employeeId) {
      await this.$store.commit("setSelectedIds", {
        serviceId: this.serviceId,
        employeeId: this.employeeId
      });
    }

    if (!this.previewVersion) {
      await this.initCustomer();
      await this.$store.dispatch("loadSalon", { salon: this.salon });
      this.showGoogleLoginButton(true);
      if (this.idGoogleAnalytics) {
        await this.enablePlugin();
        await this.track(this.salonName, this.salonId, {
          name: "home",
          path: ""
        });
      }
    } else {
      await this.$store.dispatch("previewInformation", { salon: this.salon });
    }
  },
  mounted() {
    this.detectBrowser();
    this.loadFont("belliata", process.env.VUE_APP_ASSET_URL);
    this.detectBrowserInfo.redirect = window.location.href;

    let parent = this.$refs.appMain.parentElement;
    if (!this.previewVersion) {
      while (parent) {
        const hasOverflow = getComputedStyle(parent).overflow;
        if (hasOverflow !== "visible") {
          parent.style.overflow = "visible";
        }
        parent = parent.parentElement;
      }
    }
  },
  methods: {
    ...mapMutations([
      "runTimer",
      "setSalonId",
      "setLocaleLang",
      "authHeaderReady",
      "setWidgetModal",
      "setCustomerData",
      "setWidgetOptions",
      "showGoogleLoginButton",
      "toHideMainLogo"
    ]),
    // Scroll to header
    goto() {
      let top = this.$refs.appMain.offsetTop;
      if (top > 100) {
        window.scrollTo(0, top - 100);
      } else if (top > 200) {
        window.scrollTo(0, top - 200);
      } else {
        window.scrollTo(0, top);
      }
    },

    // Tracking Google analytics
    track(name, id, url) {
      let titleUpdate =
        url.name.charAt(0).toUpperCase() +
        url.name.substring(1).replace("-", " ");
      this.$gtag.pageview({
        page_title: `${url ? titleUpdate + " | " : ""}${name}`,
        page_path:
          url.name === "home"
            ? `online-booking?vi=${id}`
            : `online-booking?vi=${id}%23${url.path}`
      });
    },
    enablePlugin() {
      setOptions({
        config: { id: this.idGoogleAnalytics }
      });
      bootstrap();
    },
    initSourceHeaders() {
      this.axios.defaults.headers.common["Source"] = this.source;
    },
    handlerHideMainLogo(value) {
      this.toHideMainLogo(value);
    },

    setId(id) {
      this.setSalonId(id);
    },
    setLang(lang) {
      this.setLocaleLang(lang);
    },
    setTitleModalConfirmPhone() {
      this.titleModalConfirmPhone = this.$t("wl_add_information");
    },
    loadFont(name, url) {
      loadIconfont(name, url);
    },
    loadOptions() {
      this.setWidgetOptions({
        previewVersionPage: this.previewVersionPage,
        previewVersion: this.previewVersion,
        controlsOverlay: this.controlsOverlay,
        paddingControlsBottom: this.paddingControlsBottom,
        buttonBackground: this.buttonBackground,
        buttonBackgroundHover: this.buttonBackgroundHover,
        buttonColor: this.buttonColor,
        isWidgetModal: this.isWidgetModal,
        source: this.source,
        previewLogo: this.previewLogo
      });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    async initCustomer() {
      const token = localStorage.getItem("access_token");

      this.axios.defaults.headers.common["Content-Language"] = `${this.lang}`;

      if (token) {
        const expiresIn = +localStorage.getItem("expires_in");
        this.$store.commit("setLoggedCustomer", true);

        if (expiresIn <= Math.round(+new Date() / 1000)) {
          removeAuthData();
        } else if (getCustomerData()) {
          this.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${getToken()}`;
          this.authHeaderReady(true);
          this.setCustomerData(getCustomerData());
          // Synchronization of customer data
          const customer = await this.$store.dispatch("loadCustomer", {
            salon: this.salon
          });
          localStorage.setItem("customer", JSON.stringify(customer));
        } else {
          removeAuthData();
        }
      }
    },
    detectBrowser() {
      if (!this.previewVersion) {
        let standalone = window.navigator.standalone,
          userAgent = window.navigator.userAgent.toLowerCase(),
          safari = /safari/.test(userAgent),
          ios = /iphone|ipod|ipad/.test(userAgent);

        if (ios) {
          if (!standalone && !safari) {
            // iOS webview
            this.detectBrowserInfo.platform = "ios";
            this.detectBrowserInfo.text = this.$t(
              "message_open_widget_in_browser_ios"
            );
            this.$bvModal.show(this.idModalDetectWebView);
          }
        } else {
          if (userAgent.includes("wv")) {
            // Android webview
            this.detectBrowserInfo.platform = "android";
            this.detectBrowserInfo.text = this.$t(
              "message_open_widget_in_browser_android"
            );
            this.$bvModal.show(this.idModalDetectWebView);
          }
        }
      }
    }
  }
};
</script>

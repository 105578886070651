import axios from "axios";
import { i18n } from "@/i18n/index";
import { apiUrl } from "@/utils/common/common";
import router from "@/router";
import { removeAuthData } from "../utils/common/common";
import moment from "moment";

export default {
  previewInformation({ commit }, payload) {
    commit("isLoadingApp");
    commit("isLoading");
    axios
      .get(apiUrl(`booking/preview/${payload.salon}`))
      .then((response) => response.data)
      .then((preview) => {
        commit("setPreviewInformation", preview);
        commit("isLoaded");
        commit("isLoadedApp");
      })
      .catch((err) => {
        console.log(err);
        commit("isLoaded");
        commit("isLoadedApp");
      });
  },
  loadSalon({ commit }, payload) {
    commit("isLoadingApp");
    axios
      .get(apiUrl(`booking/venue/${payload.salon}`))
      .then((response) => response.data)
      .then((salon) => {
        commit("setSalon", salon);

        if (salon.booking_enabled > 0) {
          commit("setSalonError", false);
          Promise.all([
            this.dispatch("loadEmployees", { salon: payload.salon }),
            this.dispatch("loadEmployeesTypes", { salon: payload.salon }),
            this.dispatch("loadPricing", { salon: payload.salon })
          ]).then(() => {
            commit("isLoadedApp");
            this.dispatch("initQueryLinks");
          });
          return;
        }

        commit("setSalonError", true);
        commit("isLoadedApp");
      })
      .catch((err) => {
        console.log(err);
        commit("setSalonError", true);
        commit("isLoadedApp");
      });
  },
  loadPricing({ commit }, payload) {
    commit("isLoading");
    return axios
      .post(apiUrl(`booking/pricings/${payload.salon}`))
      .then((response) => response.data)
      .then((pricing) => {
        commit("setServices", pricing);
        commit("isLoaded");
      })
      .catch((err) => {
        console.log(err);
        commit("isLoaded");
      });
  },
  loadEmployees({ commit }, payload) {
    return axios
      .post(apiUrl(`booking/employees`), {
        venue_id: payload.salon
      })
      .then((response) => response.data)
      .then((employees) => {
        commit("setVenueEmployees", employees);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  loadEmployeesTypes({ commit }, payload) {
    return axios
      .post(apiUrl(`booking/employee-types`), {
        venue_id: payload.salon
      })
      .then((response) => response.data)
      .then((types) => {
        commit("setVenueEmployeeTypes", types);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  loadCustomerLastVisit({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      axios
        .post(apiUrl(`booking/last-visit/${payload.salon}`))
        .then((response) => response.data)
        .then((services) => {
          commit("clearChosenServices");
          commit("setLastServices", services);
          commit("isLoaded");

          resolve(services);
        })
        .catch((err) => {
          console.log(err);
          commit("isLoaded");
          if (err.response && err.response.status === 403) {
            removeAuthData();
            commit("setLoggedCustomer", false);
            commit("setCustomerData", null);
            router.push({ path: "/logged-out-now" });
          }
          reject(err);
        });
    });
  },
  loadAvailableSlots({ commit }, payload) {
    commit("isLoading");
    commit("setNextAvailableSlotsErrors", null);
    this.dispatch("loadFirstAvailableSlots", { payload: payload });
    axios
      .post(apiUrl(`booking/available-slots/${payload.salon}`), {
        services: payload.services,
        date: payload.date
      })
      .then((response) => response.data)
      .then((slots) => {
        commit("setAvailableSlots", slots);
        commit("isLoaded");
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          if (err.response.data.status === "error") {
            commit("setAvailableSlotsErrors", err.response.data);
          }
        } else if (err.response && err.response.status === 422) {
          if (err.response.data && err.response.data.date) {
            commit("setAvailableSlotsErrors", {
              message: "date_limited",
              text: err.response.data.date[0]
            });
          }
        }
        commit("isLoaded");
      });
  },
  loadFirstAvailableSlots({ commit }, payload) {
    commit("isLoadingNextAvailableSlots", true);
    commit("setGlobalDateSlots", "");
    axios
      .post(apiUrl(`booking/next-available-slots/${payload.payload.salon}`), {
        services: payload.payload.services,
        date: moment(payload.payload.date, "YYYY-MM-DD")
          .add(1, "days")
          .format("YYYY-MM-DD")
      })
      .then((response) => response.data)
      .then((slots) => {
        commit("setNextAvailableSlots", slots);
        commit("setNextAvailableSlotsDate", slots.date);
        commit("isLoadingNextAvailableSlots", false);
      })
      .catch((err) => {
        if (err.response && err.response.status === 404) {
          if (err.response.data.status === "error") {
            commit("setNextAvailableSlotsErrors", err.response.data);
          }
        } else if (err.response && err.response.status === 422) {
          if (err.response.data && err.response.data.date) {
            commit("setNextAvailableSlotsErrors", {
              message: "date_limited",
              text: err.response.data.date[0]
            });
          }
        }
        commit("isLoadingNextAvailableSlots", false);
      });
  },
  saveServicesInToOnlineBasket({ commit }, payload) {
    commit("isLoading");
    axios
      .post(apiUrl(`booking/appointment/pre-save/${payload.salon}`), {
        services: payload.services,
        date: payload.date
      })
      .then((response) => {
        commit("setDeposit", response.data.deposit);
        commit("setServicesBasket", response.data.services);
        commit("setLoginFromPath", "pre-save");
        commit("isLoaded");
        commit("runTimer", true);
        router.push({ name: "pre-save" });
        // console.log("Start", moment().format("h:mm:ss"));
      })
      .catch((err) => {
        console.log(err);
        commit("setErrorSlot", {
          message: i18n.t("slot_are_not_able_to_book"),
          status: true
        });
        commit("isLoaded");
      });
  },
  removeServicesFromBasket({ commit }, payload) {
    commit("isLoading");
    axios
      .post(apiUrl(`booking/appointment/release-slot/${payload.salon}`), {
        token: payload.token,
        basket_id: payload.basket_id,
        count: payload.count
      })
      .then(() => {
        commit("isLoaded");
      })
      .catch((err) => {
        console.log(err);
        commit("isLoaded");
      });
  },
  saveBooking({ commit }, payload) {
    commit("isLoadingButton");
    axios
      .post(apiUrl(`booking/appointment/save/${payload.salon}`), {
        services: payload.services,
        note: payload.note,
        payment_method_id: payload.payment_method_id
      })
      .then((response) => {
        if (response.data.success) {
          router.push("booking-created");
          commit("runTimer", false);
        }
        commit("isLoadedButton");
      })
      .catch((err) => {
        console.log(err);
        commit("isLoadedButton");
        if (err.response && err.response.status === 400) {
          commit("setLimitBooking", {
            message: err.response.data.message,
            status: true
          });
        }
        if (err.response && err.response.status === 403) {
          router.push({ path: "/logged-out-now" });
          removeAuthData();
          commit("setLoggedCustomer", false);
          commit("setCustomerData", null);
        }
      });
  },
  loadCustomer({ commit }, payload) {
    axios
      .get(apiUrl(`booking/customer-data/${payload.salon}`))
      .then((response) => {
        commit("setCustomerData", response.data);
        localStorage.setItem("customer", JSON.stringify(response.data));
        commit("isLoadedGoogle");

        if (!response.data.phone || response.data.phone.length === 0) {
          commit("setCustomerPhoneConfirmed", true);
        } else {
          commit("setCustomerPhoneConfirmed", false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          router.push({ path: "/logged-out-now" });
          removeAuthData();
          commit("setLoggedCustomer", false);
          commit("setCustomerData", null);
        }
      });
  },
  loadAppointments({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.page > 1) {
        commit("isLoadingMore");
      } else {
        commit("isLoading");
      }

      axios
        .get(
          `${apiUrl("booking/customer-appointments")}/${payload.salon}?moment=${
            payload.tab
          }&page=${payload.page}`
        )
        .then((response) => {
          commit(
            payload.tab === "future"
              ? "setFutureAppointment"
              : "setPastAppointment",
            response.data
          );
          if (payload.page === 1) {
            commit(
              payload.tab === "future"
                ? "setFutureAppointmentData"
                : "setPastAppointmentData",
              response.data.data
            );
          } else {
            const stateData =
              payload.tab === "future"
                ? state.futureAppointmentsData
                : state.pastAppointmentsData;
            commit(
              payload.tab === "future"
                ? "setFutureAppointmentData"
                : "setPastAppointmentData",
              stateData.concat(response.data.data)
            );
          }
          if (payload.page > 1) {
            commit("isLoadedMore");
          } else {
            commit("isLoaded");
          }
          resolve(
            payload.tab === "future"
              ? state.futureAppointmentsData
              : state.pastAppointmentsData
          );
        })
        .catch((err) => {
          console.log(err);
          if (payload.page > 1) {
            commit("isLoadedMore");
          } else {
            commit("isLoaded");
          }
          if (err.response && err.response.status === 403) {
            router.push({ path: "/logged-out-now" });
            removeAuthData();
            commit("setLoggedCustomer", false);
            commit("setCustomerData", null);
          }
          reject(e);
        });
    });
  },
  loginToGoogle({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("isLoadingGoogle");
      axios
        .post(apiUrl(`booking/google/${payload.salon}`), {
          token_id: payload.tokenId
        })
        .then((response) => {
          resolve(response.data);

          commit("setLoggedCustomer", true);
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "expires_in",
            Math.round(+new Date() / 1000 + response.data.expires_in)
          );

          axios.defaults.headers.common = {
            ...axios.defaults.headers.common,
            Authorization: `Bearer ${response.data.access_token}`
          };

          this.dispatch("loadCustomer", { salon: payload.salon });

          commit("authHeaderReady", true);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  loadCustomerPayment({ commit }, payload) {
    axios
      .get(apiUrl(`booking/customer/${payload.salon}`))
      .then((response) => response.data)
      .then((customerPayment) => {
        console.log(customerPayment);
      })
      .catch((err) => {
        console.log(err);
        commit("setSalonError", true);
      });
  },
  removePaymentMethod({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiUrl(`booking/payment-remove/${payload.id}`))
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("isLoading");
      axios
        .post(apiUrl(`booking/logout`))
        .then((response) => {
          commit("isLoaded");
          removeAuthData();
          commit("setLoggedCustomer", false);
          router.push({ path: "/logged-out" });
          commit("setCustomerData", null);
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          commit("isLoaded");
          reject(err);
        });
    });
  },

  initQueryLinks({ getters, commit }) {
    let serviceId = router.history.current.query.service
      ? Number(router.history.current.query.service)
      : getters.selectedIds.serviceId;
    let employeeId = router.history.current.query.employee
      ? Number(router.history.current.query.employee)
      : getters.selectedIds.employeeId;

    //
    if (serviceId) {
      router.push({ path: "/services" });
      commit("clearChosenServices");
      commit("setFilteredEmployees", []);
      commit("selectEmployeeByQuery", null);

      let group = getters.getServices.filter(
        (i) =>
          !i.is_package &&
          i.services.filter((m) =>
            m.variants.some((k) => k.id === serviceId)
          ) &&
          i.services.filter((m) => m.variants.some((k) => k.id === serviceId))
            .length
      );
      let service = group[0].services.filter((i) =>
        i.variants.some((k) => k.id === serviceId)
      )[0];

      commit("setIsShowVariants", service.service_id);
      commit("setChosenServices", {
        ...service,
        employees: !service.variants[0].employeeIds
          ? service.employees
          : service.employees.filter((i) => {
              let flag = false;
              service.variants[0].employeeIds.map((k) => {
                if (Number(i.id) === k) {
                  flag = true;
                }
              });
              if (flag) {
                return i;
              }
            })
      });
    }
    //
    if (employeeId) {
      router.push({ path: "/services" });
      commit("clearChosenServices");
      commit("setFilteredEmployees", []);
      commit("selectEmployeeByQuery", null);

      let employee = getters.getSalonEmployees.find((i) => i.id === employeeId);

      if (!!employee) {
        employee = {
          ...employee,
          type: getters.getSalonEmployeeTypes.find(
            (i) => i.id === employee.employee_type_id
          ).title
        };
        commit("setFilteredEmployees", [employee]);
        commit("selectEmployeeByQuery", employee);
      }
    }
  }
};

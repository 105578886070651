<template>
  <div class="vi-main-wrapper--inner">
    <b-container>
      <div class="vi-finish-payment pt-5">
        <vi-fragment-icon-text
          :vi-icon="iconSuccess"
          :vi-title="$t('thank_you')"
          :vi-content="`<p>${$t('has_received_your_payment', {
            salon: salonName,
            amount: totalPriceBookingRender
          })}</p>`"
        />

        <div class="group-btn pb-3">
          <vi-button :vi-full="true" :vi-class="'mb-3'">
            {{ $t("email_receipt") }}
          </vi-button>
          <vi-button :vi-variant="'outline'" :vi-full="true" :vi-class="'mb-3'">
            {{ $t("no_receipt") }}
          </vi-button>
        </div>
        <div class="group-btn pt-3 pb-3">
          <vi-button
            :vi-full="true"
            :vi-class="'mb-3'"
            @click="goToAppointments"
          >
            {{ $t("view_appointments") }}
          </vi-button>
          <vi-button
            :vi-variant="'white'"
            :vi-full="true"
            :vi-class="'mb-3'"
            @click="logout"
          >
            {{ $t("logout") }}
          </vi-button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import ViFragmentIconText from "../../components/common/ViFragmentIconText";
import iconSuccess from "../../assets/img/success.svg";
import { mapMutations } from "vuex";
import ViButton from "../../components/common/ViButton";
import { priceFormatter } from "../../utils/common/common";

export default {
  name: "Finished",
  components: { ViButton, ViFragmentIconText },
  data() {
    return {
      iconSuccess
    };
  },
  computed: {
    salonName() {
      return this.$store.getters.salonName;
    },
    totalPriceBookingRender() {
      let priceBooking = this.$store.getters.totalPriceBooking;
      if (priceBooking) {
        return priceBooking.total;
      }
      return priceFormatter(0, this.$store.getters.getPriceFormat);
    }
  },
  methods: {
    ...mapMutations([
      "setTabPastAppointment",
      "clearChosenServices",
      "setLoginFromPath"
    ]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    goToAppointments() {
      this.clearChosenServices();
      this.setTabPastAppointment(false);
      this.redirect("your-appointments");
    },
    async logout() {
      await this.$store.dispatch("logout");
      await this.setLoginFromPath(null);
      await this.clearChosenServices();
    }
  }
};
</script>

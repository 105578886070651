<template>
  <span
    v-if="timer.status"
    :class="`${timer.countdown < 20 ? 'vi-timer--ending' : ''} ${ViClass}`"
    class="vi-timer"
  >
    {{ formattedCountdown || "5 min over" }}
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ViTimer",
  props: {
    ViClass: {
      type: String
    }
  },
  computed: {
    ...mapGetters(["timer"]),
    formattedCountdown() {
      return moment
        .duration(this.$store.getters.timer.countdown, "seconds")
        .format("m:ss");
    }
  }
};
</script>
<template>
  <div>
    <form class="vi-form" @submit.prevent="newPassword">
      <p class="vi-text-secondary pt-3">
        {{ $t("change_password_text") }}
      </p>
      <form-input
        v-model="values.password"
        :error="errors.password"
        :last-letter-red="'*'"
        :label="$t('password')"
        type="password"
        name="password"
        @validate="validatePassword('password')"
      />
      <form-input
        v-model="values.newPassword"
        :error="errors.newPassword"
        :last-letter-red="'*'"
        :label="$t('new_password')"
        type="password"
        name="password"
        @validate="validatePassword('newPassword')"
      />
      <form-input
        v-model="values.confirmPassword"
        :error="errors.confirmPassword"
        :last-letter-red="'*'"
        :label="$t('confirm_password')"
        type="password"
        name="confirmPassword"
        @validate="validatePassword('confirmPassword')"
      />
      <button
        :class="loadingBtn ? 'btn-loader' : ''"
        :disabled="loadingBtn"
        class="vi-btn btn-primary-outline btn-full mt-2"
        type="submit"
      >
        {{ $t("send_me_instructions") }}
        <b-spinner v-if="loadingBtn" small />
      </button>
    </form>

    <ModalConfirm :id="idModal">
      <div class="vi-confirm-message">
        <div class="vi-confirm-message--image">
          <img :src="imageUrlWrapper(imgLink)" alt="success" />
        </div>
        <div class="vi-confirm-message--title">
          <h4>{{ $t("we_have_sent_password_recover_instructions") }}</h4>
        </div>
        <div class="vi-confirm-message--btn">
          <button class="vi-btn btn-primary" type="button" @click="closeModal">
            {{ $t("ok") }}
          </button>
        </div>
      </div>
    </ModalConfirm>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput/FormInput.vue";
import * as Yup from "yup";
import imgLink from "../../assets/img/success.svg";
import { imageWrapper, apiUrl } from "@/utils/common/common";
import { mapGetters } from "vuex";
import ModalConfirm from "../Modal/ModalConfirm";

export default {
  components: {
    ModalConfirm,
    "form-input": FormInput
  },
  data() {
    return {
      loadingBtn: false,
      idModal: "changedPasswordModal",
      values: {
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      errors: {
        password: "",
        newPassword: "",
        confirmPassword: ""
      },
      imgLink
    };
  },
  computed: {
    ...mapGetters(["salonId", "loading"]),
    passwordFormSchema() {
      return Yup.object().shape({
        password: Yup.string()
          .min(
            8,
            this.$t("password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        newPassword: Yup.string()
          .min(
            8,
            this.$t("new_password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        // confirmPassword: Yup.string()
        //     .test('passwords-match', 'Passwords must match', function(value){
        //       return this.parent.newPassword === value
        //     })
        confirmPassword: Yup.string().when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("newPassword")],
            this.$t("both_passwords_must_match")
          )
        })
      });
    }
  },
  methods: {
    newPassword() {
      this.passwordFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.formValid = true;

          this.loadingBtn = true;

          try {
            const data = {
              current_password: this.values.password,
              password: this.values.newPassword,
              password_confirmation: this.values.confirmPassword
            };

            await this.axios.post(
              apiUrl(`booking/customer-password/${this.salonId}`),
              data
            );

            this.$bvModal.show(this.idModal);
            this.loadingBtn = false;

            this.values = {
              password: "",
              newPassword: "",
              confirmPassword: ""
            };
          } catch (e) {
            console.log(e);
            this.loadingBtn = false;
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validatePassword(field) {
      this.passwordFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    closeModal() {
      this.$bvModal.hide(this.idModal);
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

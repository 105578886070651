<template>
  <div
    :class="variants[variant] ? variants[variant].class : ''"
    class="w-alert"
  >
    <div v-if="variants[variant]" class="w-alert__icon">
      <img :src="imageUrlWrapper(variants[variant].icon)" alt="Alert icons" />
    </div>
    <div
      :class="{ 'items-center': !title || !description }"
      class="w-alert__info"
    >
      <h5 v-if="title" class="w-alert__info-title">{{ title }}</h5>
      <p v-if="description" class="w-alert__info-description">
        {{ description }}
      </p>
    </div>

    <button type="button" class="w-alert__close-btn" @click="$emit('close')">
      <i class="ic-close"></i>
    </button>
  </div>
</template>

<script>
import Success from "../../assets/img/success.svg";
import Warning from "../../assets/img/icon-attantion.svg";
import Error from "../../assets/img/error.svg";
import { imageWrapper } from "../../utils/common/common";

export default {
  name: "WAlert",
  props: {
    variant: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    }
  },
  data() {
    return {
      variants: {
        error: {
          class: "w-alert_error",
          icon: Error
        },
        warning: {
          class: "w-alert_warning",
          icon: Warning
        },
        attention: {
          class: "w-alert_warning",
          icon: Warning
        },
        success: {
          class: "w-alert_success",
          icon: Success
        }
      }
    };
  },
  methods: {
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

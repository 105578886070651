import { i18n } from "../../i18n";

export const imageWrapper = (url) => {
  const baseUrl = process.env.VUE_APP_ASSET_URL;
  return `${baseUrl}${url}`;
};

export const isKeepServices = () => {
  return Number(process.env.VUE_APP_KEEP_SERVICES_AFTER_TIMEOUT);
};

export const priceFormatter = (price, settings) => {
  price = Number(price).toFixed(settings.priceDecimalPlaces);
  if (settings.decimalSeparator === "comma") {
    price = price.replace(".", ",");
  }
  if (settings.currencySymbolPosition === "before") {
    return settings.currencySymbol + " " + price;
  }
  return price + " " + settings.currencySymbol;
};

export const timeFormatter = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = Math.round((num / 60 - hours) * 60);

  if (hours === 0) {
    return `${minutes} ${i18n.t("minute_short")}`;
  } else if (minutes === 0) {
    return `${hours} ${i18n.t("hour_short")}`;
  } else {
    return `${hours} ${i18n.t("hour_short")} ${minutes} ${i18n.t(
      "minute_short"
    )}`;
  }
};

export const getToken = () => {
  return localStorage.getItem("access_token");
};

export const removeAuthData = () => {
  localStorage.removeItem("returning_client");
  localStorage.removeItem("access_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("customer");
};

export const getReturningClient = () => {
  if (localStorage.getItem("returning_client")) {
    return JSON.parse(localStorage.getItem("returning_client"));
  }
};

export const getCustomerData = () => {
  if (
    localStorage.getItem("customer") &&
    localStorage.getItem("customer") !== "undefined"
  ) {
    return JSON.parse(localStorage.getItem("customer"));
  }

  return null;
};

export const imageUrl = () => {
  return "https://belliata.dev/";
};

export const apiUrl = (url) => {
  return `${process.env.VUE_APP_API_URL}/${url}`;
};

export const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    return r + "," + g + "," + b;
  }
  return null;
};

export const loadIconfont = (name, url) => {
  document.head.insertAdjacentHTML(
    "afterbegin",
    '<style type="text/css"> @font-face{' +
      "font-family: " +
      name +
      "; " +
      "src: url(" +
      url +
      "/fonts/" +
      name +
      ".eot?3wjfqj);" +
      "src: url(" +
      url +
      "/fonts/" +
      name +
      '.eot?3wjfqj#iefix) format("embedded-opentype"),' +
      "url(" +
      url +
      "/fonts/" +
      name +
      '.ttf?3wjfqj) format("truetype"),' +
      "url(" +
      url +
      "/fonts/" +
      name +
      '.woff?3wjfqj) format("woff"),' +
      "url(" +
      url +
      "/fonts/" +
      name +
      '.svg?3wjfqj#belliata) format("svg");' +
      "font-weight: normal;" +
      "font-style: normal;" +
      "font-display: block;" +
      "} </style>"
  );
};

 <template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div v-if="formValid" class="vi-main-placeholder">
        <div class="vi-main-placeholder--image">
          <img :src="imageUrlWrapper(img)" alt="" />
        </div>
        <div class="vi-main-placeholder--content">
          <h3>{{ $t("password_updated") }}</h3>
          <p>{{ responseMessage }}</p>
        </div>
        <button
          v-if="formValid !== null"
          class="vi-btn btn-primary btn-full"
          type="button"
          @click="redirectToLogin"
        >
          {{ $t("login") }}
        </button>
      </div>

      <b-modal
        :id="idModal"
        :title="$t('create_new_password')"
        :visible="visibleModal"
        scrollable
        centered
        no-close-on-backdrop
        no-enforce-focus
        hide-footer
        modal-class="vi-main-modal vi-b-modal"
        header-class="vi-b-modal--header"
        body-class="vi-b-modal--body"
        footer-class="vi-b-modal--footer"
        @close="redirect"
      >
        <form class="vi-form pt-3" @submit.prevent="newPassword">
          <p class="vi-text-secondary mb-4">
            {{ $t("create_new_password_description") }}
          </p>
          <form-input
            v-model="values.password"
            :error="errors.password"
            :last-letter-red="'*'"
            :label="$t('new_password')"
            type="password"
            name="password"
            @validate="validate('password')"
          />
          <form-input
            v-model="values.confirmPassword"
            :error="errors.confirmPassword"
            :last-letter-red="'*'"
            :label="$t('confirm_password')"
            type="password"
            name="confirmPassword"
            @validate="validate('confirmPassword')"
          />
          <div>
            <button
              :class="loading ? 'btn-loader' : ''"
              :disabled="loading"
              class="vi-btn btn-primary btn-full"
              type="submit"
            >
              {{ $t("wl_reset_password") }}
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import * as Yup from "yup";
import FormInput from "@/components/FormInput/FormInput.vue";
import { imageWrapper, apiUrl } from "../../utils/common/common.js";
import successImgLink from "../../assets/img/success.svg";
import errorImgLink from "../../assets/img/error.svg";
import { mapGetters } from "vuex";

export default {
  name: "NewPassword",
  components: {
    FormInput
  },
  data() {
    return {
      visibleModal: true,
      img: successImgLink,
      idModal: "resetPasswordModal",
      values: {
        password: "",
        confirmPassword: "",
        token: ""
      },
      errors: {
        password: "",
        confirmPassword: ""
      },
      responseMessage: "",
      formValid: false
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    passwordFormSchema() {
      return Yup.object().shape({
        password: Yup.string()
          .min(
            8,
            this.$t("password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          this.$t("both_passwords_must_match")
        )
      });
    }
  },
  created() {
    if (this.$route.query.token) {
      this.values.token = this.$route.query.token;
    }
  },
  methods: {
    redirect() {
      this.$router.push({ name: "home" });
    },
    redirectToLogin() {
      this.$router.push({ name: "login", params: { isReturnClient: true } });
    },
    newPassword() {
      this.passwordFormSchema
        .validate(this.values, { abortEarly: false })
        .then(async () => {
          this.$store.commit("isLoading");
          try {
            const data = {
              password: this.values.password,
              password_confirmation: this.values.confirmPassword,
              token: this.values.token
            };
            const response = await this.axios.post(
              apiUrl("booking/reset-password"),
              data
            );
            this.img = successImgLink;
            this.responseMessage = response.data.message;
            this.$store.commit("isLoaded");
            this.formValid = true;
            this.$bvModal.hide(this.idModal);
          } catch (e) {
            this.img = errorImgLink;
            this.responseMessage = e.response.data.message;
            this.$store.commit("isLoaded");
            this.formValid = null;
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    validate(field) {
      this.passwordFormSchema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    }
  }
};
</script>

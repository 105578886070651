<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <div class="d-flex justify-content-center">
        <CalendarPicker v-model="date" @input="setDate" />
      </div>
      <div class="d-flex justify-content-end pt-3 pb-2">
        <span class="vi-link-primary icon-left" @click="addAnotherService">
          <i class="ic-vi-plus-circle" />
          {{ $t("add_another_service") }}
        </span>
      </div>

      <div v-if="loading" class="vi-loader-static">
        <b-spinner variant="success" class="vi-spinner" />
      </div>
      <div v-else>
        <div v-if="getAvailableSlots.error" class="vi-main-placeholder">
          <div
            v-if="!getNextAvailableSlots.error"
            class="vi-main-placeholder--image"
          >
            <img
              v-if="getAvailableSlots.error.message === 'Not available slots'"
              :src="imageUrlWrapper(calendar)"
              alt="calendar"
            />
            <img
              v-if="getAvailableSlots.error.message === 'Non-working day'"
              :src="imageUrlWrapper(calendarClose)"
              alt="calendar"
            />
          </div>
          <div v-else class="vi-main-placeholder--image">
            <img
              v-if="
                getAvailableSlots.error.message === 'Non-working day' &&
                getNextAvailableSlots.error.message === 'Not available slots'
              "
              :src="imageUrlWrapper(calendarClose)"
              alt="calendar"
            />
            <img v-else :src="imageUrlWrapper(calendar)" alt="calendar" />
          </div>
          <div class="vi-main-placeholder--content">
            <div v-if="!getNextAvailableSlots.error">
              <h3
                v-if="getAvailableSlots.error.message === 'Not available slots'"
              >
                {{ $t("no_slots_available") }}
              </h3>
              <h3 v-if="getAvailableSlots.error.message === 'Non-working day'">
                {{ salonName }} <br />
                <small>{{ $t("is_closed_day") }}</small>
              </h3>
            </div>
            <div v-else>
              <h3
                v-if="
                  getAvailableSlots.error.message === 'Non-working day' &&
                  getNextAvailableSlots.error.message === 'Not available slots'
                "
              >
                {{ salonName }} <br />
                <small>{{ $t("is_closed_day") }}</small>
              </h3>
              <h3 v-else>{{ $t("no_slots_available") }}</h3>
            </div>

            <div v-if="!getNextAvailableSlots.error">
              <p v-if="loadingNextAvailableSlots">
                {{ $t("please_wait_searching_slots") }}...
                <span class="text-center">
                  <b-spinner small />
                </span>
              </p>
              <div v-else>
                <p>
                  {{ $t("but_you_can_book_for") }}
                  {{
                    getNextAvailableSlotsDate
                      | convertToStringDateSlots($t("tomorrow"), getShortMonth)
                  }}
                </p>
                <button class="vi-btn btn-primary mt-2" @click="goToNextDate">
                  {{ $t("go_to") }}
                  {{
                    getNextAvailableSlotsDate
                      | convertDateSlots(getWeekday, getShortMonth)
                  }}
                </button>
              </div>
              <span
                v-if="
                  !isAloneEmployees &&
                  getAvailableSlots.error.message !== 'Non-working day' &&
                  !checkOtherEmployee
                "
                class="fz-12 vi-link-primary mt-3"
                @click="backToEmployees"
                >{{ $t("or_try_with_other_staff") }}</span
              >
            </div>
            <div v-else>
              <div
                v-if="getNextAvailableSlots.error.message === 'date_limited'"
              >
                <p>
                  {{ getNextAvailableSlots.error.text }}
                </p>
              </div>
              <div v-else>
                <p>
                  {{
                    $t("wl_unfortunately_no_free_time_until", {
                      day: nextFreeDay,
                      month: nextFreeMonth
                    })
                  }}
                </p>
                <span
                  v-if="
                    !isAloneEmployees &&
                    getAvailableSlots.error.message !== 'Non-working day' &&
                    !checkOtherEmployee
                  "
                  class="fz-12 vi-link-primary vi-link-primary"
                  @click="backToEmployees"
                >
                  {{ $t("try_with_other_staff") }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="vi-slots-list">
          <div v-if="getAvailableSlots.time.morning.length !== 0" class="row">
            <h6 class="item-col col-12">{{ $t("morning") }}</h6>
            <div
              v-for="i in getAvailableSlots.time.morning"
              :key="i.value"
              class="item-col col-3 mt-2 mb-2"
            >
              <div
                :class="getSlotsFromBasket.name === i.name ? 'active' : ''"
                class="vi-slots-list--item"
                @click="chooseSlot(i.value)"
              >
                {{ i.name }}
              </div>
            </div>
          </div>
          <div v-if="getAvailableSlots.time.afternoon.length !== 0" class="row">
            <h6 class="item-col col-12">{{ $t("afternoon") }}</h6>
            <div
              v-for="i in getAvailableSlots.time.afternoon"
              :key="i.value"
              class="item-col col-3 mt-2 mb-2"
            >
              <div
                :class="getSlotsFromBasket.name === i.name ? 'active' : ''"
                class="vi-slots-list--item"
                @click="chooseSlot(i.value)"
              >
                {{ i.name }}
              </div>
            </div>
          </div>
          <div v-if="getAvailableSlots.time.evening.length !== 0" class="row">
            <h6 class="item-col col-12">{{ $t("evening") }}</h6>
            <div
              v-for="i in getAvailableSlots.time.evening"
              :key="i.value"
              class="item-col col-3 mt-2 mb-2"
            >
              <div
                :class="getSlotsFromBasket.name === i.name ? 'active' : ''"
                class="vi-slots-list--item"
                @click="chooseSlot(i.value)"
              >
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  Modal  -->
      <ModalConfirm :id="idModal">
        <div class="vi-confirm-message">
          <div class="vi-confirm-message--image">
            <img :src="imageUrlWrapper(errorIcon)" alt="Danger" />
          </div>
          <div class="vi-confirm-message--title">
            <h4>{{ getErrorSlot.message }}</h4>
          </div>
          <div class="vi-confirm-message--btn w-100">
            <button
              class="vi-btn btn-primary btn-full"
              @click="closeErrorModal"
            >
              {{ $t("ok") }}
            </button>
          </div>
        </div>
      </ModalConfirm>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import imgLink from "../../assets/img/salon.svg";
import ErrorIcon from "../../assets/img/alert-error-icon.svg";
import { imageWrapper } from "../../utils/common/common.js";
import CalendarPicker from "../../components/CalendarPicker/CalendarPicker.vue";
import calendar from "../../assets/img/calendar.svg";
import calendarClose from "../../assets/img/calendar-close.svg";
import moment from "moment-timezone";
import ModalConfirm from "../../components/Modal/ModalConfirm";

export default {
  components: {
    CalendarPicker,
    ModalConfirm
  },
  filters: {
    convertDateSlots(date, weekday, shortMonth) {
      let day = weekday(
        Number(moment(date).format("d")) === 0 ? 7 : moment(date).format("d")
      );
      let dayNumber = moment(date).format("DD");
      let month = shortMonth(moment(date).format("M"));

      return `${day} ${dayNumber} ${month}`;
    },
    convertToStringDateSlots(date, tomorrow, shortMonth) {
      if (moment().add(1, "days").format("YYYY-MM-DD") === date) {
        return `${tomorrow}, ${moment(date).format("DD")} ${shortMonth(
          moment(date).format("M")
        )}`;
      }
      return `${moment(date).format("DD")} ${shortMonth(
        moment(date).format("M")
      )}`;
    }
  },
  data() {
    return {
      img: imgLink,
      idModal: "errorSlots",
      errorIcon: ErrorIcon,
      value: "",
      calendar,
      calendarClose,
      date: "",
      nextFreeDay: "",
      nextFreeMonth: "",
      date2month: "",
      isAloneEmployees: false
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "loading",
      "salonName",
      "isPayments",
      "getAvailableSlots",
      "getChosenServices",
      "getChosenServicesData",
      "getSlotsFromBasket",
      "loadingNextAvailableSlots",
      "getNextAvailableSlots",
      "getNextAvailableSlotsDate",
      "salonTimeZone",
      "getErrorSlot"
    ]),
    checkOtherEmployee() {
      let isValue = (item) => {
        return item.isOtherEmployeeBlock === true;
      };
      return this.getChosenServices.every(
        (item) => item.isOtherEmployeeBlock === true
      );
    }
  },
  watch: {
    date: {
      deep: true,
      handler(newStateDate) {
        if (newStateDate) {
          this.nextFreeDay = moment(this.date).add(2, "M").format("DD");
          this.nextFreeMonth = this.$t(
            `month_short${moment(this.date).add(2, "M").format("M")}`
          );
          this.$store.dispatch("loadAvailableSlots", {
            salon: this.salonId,
            services: this.getChosenServicesData,
            date: this.date
          });
        }
      }
    },
    getErrorSlot: {
      deep: true,
      handler(newGetErrorSlot) {
        if (newGetErrorSlot.status) {
          this.$bvModal.show(this.idModal);
        }
        this.$bvModal.hide(this.idModal);
      }
    }
  },
  created() {
    this.setDate();
    this.nextFreeDay = moment(this.date).add(2, "M").format("DD");
    this.nextFreeMonth = this.$t(
      `month_short${moment(this.date).add(2, "M").format("M")}`
    );
    this.checkAloneEmployees(this.getChosenServices);
  },
  mounted() {
    this.selectEmployeeByQuery(null);
    if (this.getChosenServicesData.length === 0) {
      this.redirect("services");
    }
  },
  methods: {
    ...mapMutations([
      "setErrorSlot",
      "setGlobalDateSlots",
      "addSlotsToBasket",
      "selectEmployeeByQuery"
    ]),
    debug(event) {
      console.log("Slots", event);
    },
    getWeekday(num) {
      return this.$t(`day${num}`);
    },
    getShortMonth(num) {
      return this.$t(`month_short${num}`);
    },
    setDate(date) {
      this.date = date;
    },
    redirect(url) {
      this.$router.push({ name: url });
    },
    closeErrorModal() {
      this.setErrorSlot({
        message: "",
        status: false
      });
      this.$store.dispatch("loadAvailableSlots", {
        salon: this.salonId,
        services: this.getChosenServicesData,
        date: this.date
      });
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    chooseSlot(time) {
      this.addSlotsToBasket({
        date: this.date,
        time: time,
        slots: this.getAvailableSlots.slotsData[time]
          ? this.getAvailableSlots.slotsData[time]
          : []
      });
      this.$store.dispatch("saveServicesInToOnlineBasket", {
        salon: this.salonId,
        services: this.getAvailableSlots.slotsData[time],
        date: this.date,
        isPayment: this.isPayments
      });
    },
    goToNextDate() {
      this.setGlobalDateSlots(this.getNextAvailableSlotsDate);
    },
    backToEmployees() {
      this.redirect("staff");
    },
    addAnotherService() {
      this.redirect("services");
    },

    checkAloneEmployees(arr) {
      let flag = false;

      arr.map((item) => {
        if (item.employees.length === 1) {
          flag = true;
        } else {
          this.isAloneEmployees = false;
        }
      });
      if (flag) {
        this.isAloneEmployees = !!arr.every((i) => i.employees.length === 1);
      }
    }
  }
};
</script>

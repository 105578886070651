<template>
  <b-container>
    <div class="vi-main-wrapper--inner">
      <BasketCard
        v-if="timer.status"
        :date="getTimeOrder.date | filterDate"
        :time="getTimeOrder.time"
        :salon="salonName"
        :address="salonAddress"
      />

      <form class="vi-form mt-4" @submit.prevent="createUser">
        <form-input
          v-model="values.firstname"
          :error="errors.firstname"
          :last-letter-red="'*'"
          :label="$t('first_name')"
          type="text"
          name="firstname"
          @validate="validate('firstname')"
        />
        <form-input
          v-model="values.lastname"
          :error="errors.lastname"
          :last-letter-red="'*'"
          :label="$t('last_name')"
          type="text"
          name="lastname"
          @validate="validate('lastname')"
        />
        <form-input
          v-if="!isGoogle"
          v-model="values.email"
          :error="errors.email"
          :last-letter-red="'*'"
          :label="$t('email_address')"
          type="email"
          name="email"
          @validate="validate('email')"
        ></form-input>
        <div class="form-group">
          <span class="vi-form-label" data-end="*">{{
            $t("reg_customer_phone")
          }}</span>
          <vue-tel-input
            v-model="values.phone"
            :default-country="salonISOCode"
            :auto-format="true"
            :valid-characters-only="true"
            :auto-default-country="true"
            :input-options="{ placeholder: $t('phone') }"
            :class="`vi-form-control ${
              !!errors.phoneIsNotValid && ' vi-form-control--error'
            }`"
            mode="international"
            name="phone"
            @input="validatePhone"
            @blur="validatePhoneBlur"
          />
          <p
            v-if="!!errors.phoneIsNotValid"
            class="vi-form-control--text-error"
          >
            {{ errors.phoneIsNotValid }}
          </p>
        </div>
        <form-input
          v-if="!isGoogle"
          v-model="values.password"
          :type="passwordFieldType"
          :error="errors.password"
          :last-letter-red="'*'"
          :password-switch="switchVisibility"
          :label="$t('password')"
          name="password"
          @validate="validatePassword('password')"
        />

        <div v-if="enablePolicy" class="pt-2 pb-2">
          <div class="vi-form-group">
            <Checkbox
              id="checkboxLeagalAge"
              v-model="values.checkboxLeagalAge"
              :text="$t('confirm_age')"
              :class="`${
                !!errors.checkboxLeagalAge && ' vi-checkbox-default-error'
              }`"
              class="checkbox-secondary"
              @validate="validate('checkboxLeagalAge')"
            />
          </div>

          <span class="vi-link-primary mb-3" @click="openPrivacyPolice">{{
            $t("privacy_policy")
          }}</span>

          <div class="vi-form-group">
            <Checkbox
              id="checkboxEmailContact"
              v-model="checkboxEmailContact"
              :text="$t('i_agree_contact_by_email')"
              class="checkbox-secondary"
            />
          </div>
          <div class="vi-form-group">
            <Checkbox
              v-if="marketSms"
              id="checkboxSmsContact"
              v-model="checkboxSmsContact"
              :text="$t('i_agree_contact_by_sms')"
              class="checkbox-secondary"
            />
          </div>
          <div class="vi-form-group">
            <Checkbox
              v-if="medicalConsent"
              id="checkboxMedicalContact"
              v-model="checkboxMedicalContact"
              :text="$t('i_agree_to_medical_related_data_to_be_stored')"
              class="checkbox-secondary"
            />
          </div>
        </div>

        <div class="vi-form--footer">
          <div>
            <button
              :class="loading ? 'btn-loader' : ''"
              :disabled="loading"
              type="submit"
              class="vi-btn btn-primary btn-full mt-3"
            >
              {{ $t("reg_sign_up") }}

              <b-spinner v-if="loading" small />
            </button>
          </div>
          <!--          <div v-if="isGoogle" class="mt-3 text-center vi-text-primary mb-4">-->
          <!--            {{ $t("this_site_is_protected_by_recaptcha_and_google") }} <br />-->
          <!--            <span class="vi-link-primary" @click="openPrivacyPolice">-->
          <!--              {{ $t("privacy_policy") }}-->
          <!--            </span>-->
          <!--            {{ $t("and") }}-->
          <!--            <span class="vi-link-primary" @click="openTermsOfService">{{-->
          <!--              $t("terms_of_service")-->
          <!--            }}</span>-->
          <!--            {{ $t("apply") }}-->
          <!--          </div>-->
        </div>

        <Modal :id="idModalPolice" :title="titleModalPolice">
          <div class="vi-typography pt-2 pb-2">
            <p>{{ privacyPolicy }}</p>
          </div>
        </Modal>
        <modal-confirm :id="idModalForgotPassword">
          <div v-if="isSentSuccess" class="vi-confirm-message">
            <div class="vi-confirm-message--image">
              <img :src="imageUrlWrapper(mailIcon)" alt="Mail Icon" />
            </div>
            <div class="vi-confirm-message--title">
              <h4 class="fw-bold">{{ $t("check_your_mail") }}</h4>
            </div>
            <div class="vi-confirm-message--description">
              <p>{{ $t("we_have_sent_password_recover_instructions") }}</p>
            </div>
            <div class="vi-confirm-message--btn justify-content-center">
              <vi-button @click="closeForgotPasswordModal">
                {{ $t("ok") }}
              </vi-button>
            </div>
          </div>
          <div v-else class="vi-confirm-message">
            <div class="vi-confirm-message--image">
              <img :src="imageUrlWrapper(lockIcon)" alt="Remove Icon" />
            </div>
            <div class="vi-confirm-message--title">
              <h4 class="fw-bold">Restore password</h4>
            </div>
            <div class="vi-confirm-message--description">
              <p>We will send a password reset email to {{ values.email }}</p>
            </div>
            <div class="vi-confirm-message--btn flex-column">
              <vi-button
                :vi-loader="isSendingInstruction"
                class="mb-3"
                @click="forgotPasswordAction"
              >
                {{ $t("send_me_instructions") }}
              </vi-button>
              <vi-button
                vi-variant="white"
                @click="$bvModal.hide(idModalForgotPassword)"
              >
                {{ $t("cancel") }}
              </vi-button>
            </div>
          </div>
        </modal-confirm>
      </form>
    </div>
  </b-container>
</template>

<script>
import { object, string, email, boolean } from "yup";
import { mapGetters, mapMutations } from "vuex";
import Modal from "@/components/Modal/Modal.vue";
import BasketCard from "@/components/BasketCard/BasketCard.vue";
import StepNavigation from "@/components/StepNavigation/StepNavigation.vue";
import FormInput from "@/components/FormInput/FormInput.vue";
import GoogleLogin from "@/components/GoogleLogin/GoogleLogin.vue";
import Checkbox from "@/components/Checkbox/Checkbox.vue";
import { TheMask } from "vue-the-mask";
import { VueTelInput } from "vue-tel-input";
import { apiUrl } from "@/utils/common/common";
import moment from "moment";
import "moment-duration-format";
import { getCustomerData, imageWrapper } from "../../utils/common/common";
import ModalConfirm from "../../components/Modal/ModalConfirm";
import ViButton from "../../components/common/ViButton";
import lockIcon from "../../assets/img/logged-auth.svg";
import mailIcon from "../../assets/img/mail.svg";

export default {
  name: "Registration",
  components: {
    ViButton,
    ModalConfirm,
    "form-input": FormInput,
    StepNavigation,
    GoogleLogin,
    BasketCard,
    TheMask,
    Modal,
    VueTelInput,
    Checkbox
  },
  filters: {
    filterDate(value) {
      return moment(value).format("DD MMM, YYYY");
    }
  },
  props: {
    isGoogle: {},
    isReturnClient: {}
  },
  data() {
    return {
      lockIcon,
      mailIcon,
      idModalForgotPassword: "forgotPasswordModal",
      idModalPolice: "policeModal",
      titleModalPolice: "Privacy Police",
      values: {
        email: "",
        password: "",
        phone: "",
        phoneIsNotValid: true,
        checkboxLeagalAge: false
      },
      errors: {
        email: "",
        password: "",
        phone: "",
        phoneIsNotValid: "",
        checkboxLeagalAge: ""
      },
      hasPrevious: true,
      isLastStep: false,
      passwordFieldType: "password",
      checkboxLeagalAge: false,
      checkboxEmailContact: false,
      checkboxSmsContact: false,
      checkboxMedicalContact: false,
      isSendingInstruction: false,
      isSentSuccess: false
    };
  },
  computed: {
    ...mapGetters([
      "salonId",
      "salonISOCode",
      "timer",
      "loading",
      "salonName",
      "salonAddress",
      "getTimeOrder",
      "loginFromPath",
      "privacyPolicy",
      "enablePolicy",
      "marketEmails",
      "marketSms",
      "getChosenServices",
      "medicalConsent"
    ]),
    RegistrationFormSchema() {
      const params = {
        firstname: string().required(this.$t("this_field_is_required")),
        lastname: string().required(this.$t("this_field_is_required")),
        email: string()
          .email(this.$t("email_must_be_valid"))
          .required(this.$t("this_field_is_required")),
        password: string()
          .min(
            8,
            this.$t("password_must_be_at_least_characters_long", { num: 8 })
          )
          .required(this.$t("this_field_is_required")),
        phone: string().required(this.$t("this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("phone_is_not_valid"))
      };

      if (this.enablePolicy) {
        params.checkboxLeagalAge = boolean()
          .oneOf([true], this.$t("field_is_required"))
          .required();
      }

      return object().shape(params);
    },
    RegistrationGoogleSchema() {
      const params = {
        firstname: string().required(this.$t("this_field_is_required")),
        lastname: string().required(this.$t("this_field_is_required")),
        phone: string().required(this.$t("this_field_is_required")),
        phoneIsNotValid: boolean().oneOf([false], this.$t("phone_is_not_valid"))
      };

      if (this.enablePolicy) {
        params.checkboxLeagalAge = boolean()
          .oneOf([true], this.$t("field_is_required"))
          .required();
      }

      return object().shape(params);
    }
  },
  created() {
    if (getCustomerData()) {
      if (this.loginFromPath) {
        this.redirect(this.loginFromPath);
      } else {
        this.redirect("home");
      }
    }
  },
  mounted() {
    this.titleModalPolice = this.$t("privacy_policy");
  },
  methods: {
    ...mapMutations(["isLoading", "isLoaded", "setLoggedCustomer"]),
    redirect(url) {
      this.$router.push({ name: url });
    },
    createUser() {
      if (this.isGoogle) {
        this.RegistrationGoogleSchema.validate(this.values, {
          abortEarly: false
        })
          .then(() => {
            if (this.isReturnClient) {
              this.redirect("returning-client");
              return;
            }
            this.redirect("gratuity");
          })
          .catch((err) => {
            if (err.inner) {
              err.inner.forEach((error) => {
                this.errors = { ...this.errors, [error.path]: error.message };
              });
            }
          });
        return;
      }
      this.RegistrationFormSchema.validate(this.values, { abortEarly: false })
        .then(async () => {
          // console.log(1111, this.values.phone)
          // if (!this.values.phone) {
          //   this.values.phoneIsNotValid = obj.valid;
          //   this.validate("phoneIsNotValid");
          // }

          await this.registration();
          if (this.loginFromPath === "services") {
            this.redirect("home");
          } else if (this.loginFromPath) {
            this.redirect(this.loginFromPath);
          } else {
            this.redirect("home");
          }
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              this.errors = { ...this.errors, [error.path]: error.message };
            });
          }
        });
    },
    registration() {
      return new Promise(async (resolve, reject) => {
        this.isLoading();

        try {
          const data = {
            first_name: this.values.firstname || "",
            last_name: this.values.lastname || "",
            phone: this.values.phone,
            email: this.values.email,
            password: this.values.password,
            age: this.values.checkboxLeagalAge ? 1 : 0
          };
          const response = await this.axios.post(
            apiUrl(`booking/register/${this.salonId}`),
            data
          );

          this.setLoggedCustomer(true);
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "expires_in",
            Math.round(+new Date() / 1000 + response.data.expires_in)
          );

          this.axios.defaults.headers.common = {
            ...this.axios.defaults.headers.common,
            Authorization: `Bearer ${response.data.access_token}`
          };

          this.$store.commit("authHeaderReady", true);

          await this.$store.dispatch("loadCustomer", {
            salon: this.salonId
          });

          this.isLoaded();

          resolve();
        } catch (e) {
          if (e.response) {
            if (e.response.data.email) {
              this.errors.email = e.response.data.email[0];
            }
            if (
              e.response.data.error &&
              e.response.data.error === "email_already_exists"
            ) {
              this.$bvModal.show(this.idModalForgotPassword);
            }
          }

          this.isLoaded();
          reject(e);
        }
      });
    },
    validate(field) {
      if (this.isGoogle) {
        this.RegistrationFormSchema.validateAt(field, this.values)
          .then(() => {
            this.errors[field] = "";
          })
          .catch((err) => {
            this.errors[err.path] = err.message;
          });
        return;
      }
      this.RegistrationFormSchema.validateAt(field, this.values)
        .then(() => {
          this.errors[field] = "";
        })
        .catch((err) => {
          this.errors[err.path] = err.message;
        });
    },
    validatePassword(value) {
      if (this.values[value].length === 0) {
        this.errors[value] = this.$t("this_field_is_required");
      } else if (
        this.values[value].length > 0 &&
        this.values[value].length < 9
      ) {
        this.errors[value] = this.$t(
          "password_must_be_at_least_characters_long",
          { num: 8 }
        );
      } else {
        return (this.errors[value] = "");
      }
    },
    goToPrev() {
      if (this.isReturnClient) {
        this.$router.push({
          name: "login",
          params: { isReturnClient: true }
        });
        return;
      }

      this.redirect("home");
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    openPrivacyPolice() {
      this.$bvModal.show(this.idModalPolice);
    },
    validatePhone(_number, obj) {
      if (obj.valid) {
        this.values.phone = obj.number;
      }

      if (String(_number).length > 4) {
        this.values.phoneIsNotValid = !obj.valid;
        this.validate("phoneIsNotValid");
      } else {
        this.values.phoneIsNotValid = obj.valid;
        this.validate("phoneIsNotValid");
      }
    },
    validatePhoneBlur() {
      if (this.values.phone.length === 0) {
        this.errors.phoneIsNotValid = this.$t("this_field_is_required");
        this.errors.phone = this.$t("this_field_is_required");
      }
    },
    async forgotPasswordAction() {
      this.isSendingInstruction = true;
      try {
        await this.axios.post(apiUrl("booking/forgot-password"), {
          email: this.values.email,
          url: location.href.split("#")[0]
        });
        this.isSentSuccess = true;
        this.isSendingInstruction = false;
      } catch (e) {
        if (e.response) {
          if (e.response.data.error) {
            this.errors.email = e.response.data.error;
          }
        }

        this.isSendingInstruction = false;
        this.$bvModal.hide(this.idModalForgotPassword);
      }
    },
    closeForgotPasswordModal() {
      this.redirect("login");
      this.$bvModal.hide(this.idModalForgotPassword);
    }
  }
};
</script>
<style src="../../../node_modules/vue-tel-input/dist/vue-tel-input.css"></style>

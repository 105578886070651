<template>
  <ModalConfirm :id="id">
    <div class="vi-confirm-message">
      <div class="vi-confirm-message--image">
        <img :src="imageUrlWrapper(iconAttantion)" alt="Danger" />
      </div>
      <div class="vi-confirm-message--title">
        <h4>{{ content }}</h4>
      </div>
      <div class="vi-confirm-message--btn w-100">
        <button class="vi-btn btn-primary btn-full" @click="gotIt">
          {{ $t("got_it") }}
        </button>
      </div>
    </div>
  </ModalConfirm>
</template>

<script>
import ModalConfirm from "../Modal/ModalConfirm";
import { imageWrapper } from "../../utils/common/common";
import iconAttantion from "../../assets/img/icon-attantion.svg";
import { mapMutations } from "vuex";

export default {
  name: "ModalSpecialInfo",
  components: { ModalConfirm },
  props: ["id", "content"],
  data() {
    return {
      iconAttantion
    };
  },
  methods: {
    ...mapMutations(["offIsSpecialInfo"]),
    imageUrlWrapper(url) {
      return imageWrapper(url);
    },
    gotIt() {
      this.$bvModal.hide(this.id);
      this.offIsSpecialInfo();
    }
  }
};
</script>

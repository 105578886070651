export default {
  // Settings
  setWidgetOptions(state, payload) {
    state.widgetOptions = payload;
  },
  setPreviewInformation(state, payload) {
    state.previewInformation = payload;
  },

  // Global
  authHeaderReady(state, value) {
    state.authHeaderReady = value;
  },
  runTimer(state, value) {
    state.timer.status = value;

    const stopCountdown = setInterval(() => {
      if (state.timer.status) {
        state.timer.countdown -= 1;
        if (!state.timer.countdown) {
          clearInterval(stopCountdown);
        }
      } else {
        state.timer.countdown = 300;
        clearInterval(stopCountdown);
      }
    }, 1000);
  },
  setPastUrl(state, value) {
    state.pastUrl = value;
  },
  setLoginFromPath(state, value) {
    state.loginFromPath = value;
  },
  showGoogleLoginButton(state, value) {
    state.showGoogleLoginButton = value;
  },
  setLimitBooking(state, { message, status }) {
    state.limitBooking.message = message;
    state.limitBooking.status = status;
  },
  offIsSpecialInfo(state) {
    state.salon.special_information_booking = 0;
  },
  toHideMainLogo(state, value) {
    state.mainLogoIsHide = value;
  },

  // Loaders
  isLoading(state) {
    state.loading = true;
  },
  isLoaded(state) {
    state.loading = false;
  },
  isLoadingApp(state) {
    state.loadingApp = true;
  },
  isLoadedApp(state) {
    state.loadingApp = false;
  },
  isLoadingMore(state) {
    state.loadingMore = true;
  },
  isLoadedMore(state) {
    state.loadingMore = false;
  },
  isLoadingButton(state) {
    state.loadingButton = true;
  },
  isLoadedButton(state) {
    state.loadingButton = false;
  },
  isLoadingGoogle(state) {
    state.loadingGoogle = true;
  },
  isLoadedGoogle(state) {
    state.loadingGoogle = false;
  },

  // Salon
  setSalonId(state, id) {
    state.salonId = id;
  },
  setLocaleLang(state, lang) {
    state.language = lang;
  },
  setSalon(state, salon) {
    state.salon = {
      ...salon,
      isPayments: salon.payments_enabled
    };
  },
  setSalonError(state, value) {
    state.errorSalonLoad = value;
  },

  // Customer
  setLoggedCustomer(state, value) {
    state.isLoggedCustomer = value;
  },
  setCustomerData(state, data) {
    state.customer = data;
  },
  setShowPasswordForgotModal(state, value) {
    state.isShowPasswordForgotModal = value;
  },
  setCustomerPhoneConfirmed(state, value) {
    state.isCustomerPhoneConfirmed = value;
  },

  // Filters
  setFilteredServices(state, data) {
    state.servicesFiltered = data;
  },
  setFilteredEmployees(state, data) {
    state.filteredEmployees = data;
  },
  resetFilterServices(state) {
    state.servicesFiltered = [];
  },
  resetFilterEmployees(state) {
    state.filteredEmployees = [];
  },

  // Services
  setServices(state, value) {
    state.services = value.map((item, index) => {
      if (item.is_package) {
        return {
          ...item,
          packages: item.packages.filter(
            (i) => i.services && i.services.length > 0
          ),
          services: [],
          isShow: true,
          idCollapse: `${item.group_title}-${index + 1}`
        };
      } else {
        return {
          ...item,
          services: item.services.map((k) => {
            return {
              ...k,
              serviceType: k.variants.length > 1 ? "multiple" : "single",
              isShowVariants: false
            };
          }),
          isShow: true,
          idCollapse: `${item.group_title}-${index + 1}`
        };
      }
    });
  },
  removeServiceError(state) {
    state.serviceError = false;
    state.serviceErrorName = "";
  },
  setServicesBasket(state, data) {
    state.servicesBasket = data;
  },
  setSavedServices(state, data) {
    state.savedServices = data;
  },
  setLastServices(state, data) {
    state.lastServices = { ...data };
  },
  setIsShowCollapse(state, id) {
    state.services = state.services.map((item) => {
      return {
        ...item,
        isShow: id === item.idCollapse ? !item.isShow : item.isShow
      };
    });
    if (state.servicesFiltered.length > 0) {
      state.servicesFiltered = state.servicesFiltered.map((item) => {
        return {
          ...item,
          isShow: id === item.idCollapse ? !item.isShow : item.isShow
        };
      });
    }
  },
  setIsShowVariants(state, id) {
    state.services = state.services.map((item) => {
      if (!item.is_package) {
        return {
          ...item,
          services: item.services.map((k) => {
            return {
              ...k,
              isShowVariants:
                id === k.service_id ? !k.isShowVariants : k.isShowVariants
            };
          })
        };
      }
      return { ...item };
    });
    if (state.servicesFiltered.length > 0) {
      state.servicesFiltered = state.servicesFiltered.map((item) => {
        if (!item.is_package) {
          return {
            ...item,
            services: item.services.map((k) => {
              return {
                ...k,
                isShowVariants:
                  id === k.service_id ? !k.isShowVariants : k.isShowVariants
              };
            })
          };
        }
        return { ...item };
      });
    }
  },
  setChosenServices(state, value) {
    state.chosenServices.push({
      ...value,
      selectedEmployee: null
    });
    state.chosenServicesData.push({
      service_id: value.id,
      variant_id: value.variants[0].id
    });

    // Set Price
    if (value.booking !== "hide_price") {
      state.totalServicesPrice =
        state.totalServicesPrice + Number(value.variants[0].price);
    }
    // Set Time
    state.totalServicesTime = state.totalServicesTime
      ? 0
      : Number(value.variants[0].duration);
  },
  setChosenReturningServices(state, data) {
    let servicePrice = 0;
    let serviceTime = 0;
    let flag = false;
    data.map((item) => {
      state.chosenServices.push({
        ...item,
        selectedEmployee: null,
        pricingType: "single",
        booking: item.pricing_booking,
        title: item.service_title,
        titleGroup: item.group_title,
        id: item.service_id,
        variants: [
          {
            id: item.variant_id,
            price: item.price,
            duration: item.duration,
            price_formatted: item.price_formatted
          }
        ]
      });
      state.chosenServicesData.push({
        service_id: item.service_id,
        variant_id: item.variant_id
      });

      // Set Price
      if (item.pricing_booking !== "hide_price") {
        flag = true;
        servicePrice = servicePrice + item.price;
      }

      serviceTime = serviceTime + item.duration;
    });
    // Set Price
    if (flag) {
      state.totalServicesPrice = servicePrice;
    }
    // Set Time
    state.totalServicesTime = serviceTime;
  },
  removeChosenServices(state, value) {
    state.chosenServices = state.chosenServices.filter((i) => {
      if (!i.package_id) {
        return i.id !== value.id;
      }
      return i;
    });
    state.chosenServicesData = state.chosenServicesData.filter((i) => {
      if (!i.package_id) {
        return i.service_id !== value.id;
      }
      return i;
    });
    state.chosenSlot.slots = state.chosenSlot.slots.filter(
      (i) => i.service_id !== value.id
    );

    // Set Price
    if (value.booking === "bookable") {
      state.totalServicesPrice =
        state.totalServicesPrice - Number(value.variants[0].price);
    }
    // Set Time
    state.totalServicesTime =
      state.totalServicesTime - Number(value.variants[0].duration);
  },
  removeChosenServicesByVariant(state, value) {
    state.chosenServices = state.chosenServices.filter(
      (item) => item.variants[0].id !== value.id
    );
    state.chosenServicesData = state.chosenServicesData.filter(
      (item) => item.variant_id !== value.id
    );

    // Set Price
    if (value.type === "bookable") {
      state.totalServicesPrice = state.totalServicesPrice - Number(value.price);
    }

    // Set Time
    state.totalServicesTime = state.totalServicesTime - Number(value.duration);
  },
  removeFromBasketServicesByVariant(state, value) {
    state.chosenServices = state.chosenServices.filter(
      (item) => item.variants[0].id !== value.variants[0].id
    );
    state.chosenServicesData = state.chosenServicesData.filter(
      (item) => item.variant_id !== value.variants[0].id
    );

    // Set Price
    if (value.booking === "bookable") {
      state.totalServicesPrice =
        state.totalServicesPrice - Number(value.variants[0].price);
    }

    // Set Time
    state.totalServicesTime =
      state.totalServicesTime - Number(value.variants[0].duration);
  },

  setChosenReturningPackage(state, value) {
    state.chosenServices.push({
      ...value,
      package_id: value.package_id,
      selectedEmployee: null,
      pricingType: "single",
      booking: value.pricing_booking,
      title: value.service_title,
      titleGroup: value.package_title,
      id: value.service_id,
      variants: [
        {
          id: value.variant_id,
          price: value.price,
          duration: value.duration,
          price_formatted: value.price_formatted
        }
      ]
    });
    state.chosenServicesData.push({
      package_id: value.package_id,
      service_id: value.service_id,
      variant_id: value.variant_id
    });

    state.totalServicesPrice = state.totalServicesPrice + value.price;
    state.totalServicesTime = state.totalServicesTime + value.duration;
  },
  setChosenPackage(state, value) {
    state.chosenServices.push({
      ...value,
      package_id: value.package_id,
      selectedEmployee: null
    });
    state.chosenServicesData.push({
      package_id: value.package_id,
      service_id: value.id,
      variant_id: value.variants[0].id
    });

    state.totalServicesPrice =
      state.totalServicesPrice + value.variants[0].custom_price;
    state.totalServicesTime =
      state.totalServicesTime + value.variants[0].custom_duration;
  },
  removeChosenPackage(state, value) {
    state.chosenServices = state.chosenServices.filter(
      (i) => i.package_id !== value.package_id
    );
    state.chosenServicesData = state.chosenServicesData.filter(
      (i) => i.package_id !== value.package_id
    );
    state.totalServicesPrice = state.totalServicesPrice - value.total_price;
    state.totalServicesTime = state.totalServicesTime - value.total_duration;
  },
  clearChosenServices(state) {
    state.chosenServices = [];
    state.chosenServicesData = [];
    state.totalServicesPrice = 0;
    state.totalServicesTime = 0;
  },

  // Employees
  setVenueEmployees(state, value) {
    state.venueEmployees = value;
  },
  setVenueEmployeeTypes(state, value) {
    state.venueEmployeeTypes = value;
  },
  setChosenEmployees(state, ids) {
    state.chosenServices = state.chosenServices.map((item) => ({
      ...item,
      isOtherEmployeeBlock:
        ids.service_id === item.id || ids.service_id === 0
          ? ids.employee_id === 0
          : item.isOtherEmployeeBlock,
      selectedEmployee:
        ids.service_id === item.id || ids.service_id === 0
          ? ids.employeeName
          : item.selectedEmployee
    }));
    state.chosenServicesData = state.chosenServicesData.map((item) => ({
      ...item,
      employee_id:
        ids.service_id === item.service_id || ids.service_id === 0
          ? ids.employee_id
          : item.employee_id
    }));
  },
  removeChosenEmployees(state, ids) {
    state.chosenServices = state.chosenServices.map((item) => ({
      ...item,
      selectedEmployee:
        ids.service_id === item.id || ids.service_id === 0
          ? null
          : item.selectedEmployee
    }));
    state.chosenServicesData = state.chosenServicesData.map((item) => ({
      ...item,
      employee_id:
        ids.service_id === item.service_id || ids.service_id === 0
          ? undefined
          : item.employee_id
    }));
  },
  setChosenEmployeesByVariant(state, ids) {
    state.chosenServices = state.chosenServices.map((item) => ({
      ...item,
      isOtherEmployeeBlock:
        ids.variant_id === item.variants[0].id
          ? ids.employee_id === 0
          : item.isOtherEmployeeBlock,
      selectedEmployee:
        ids.variant_id === item.variants[0].id
          ? ids.employeeName
          : item.selectedEmployee
    }));
    state.chosenServicesData = state.chosenServicesData.map((item) => ({
      ...item,
      employee_id:
        ids.variant_id === item.variant_id ? ids.employee_id : item.employee_id
    }));
  },
  removeChosenEmployeesByVariant(state, ids) {
    state.chosenServices = state.chosenServices.map((item) => ({
      ...item,
      selectedEmployee:
        ids.variant_id === item.variants[0].id || ids.variant_id === 0
          ? null
          : item.selectedEmployee
    }));
    state.chosenServicesData = state.chosenServicesData.map((item) => ({
      ...item,
      employee_id:
        ids.variant_id === item.variant_id || ids.variant_id === 0
          ? undefined
          : item.employee_id
    }));
  },
  selectEmployeeByQuery(state, payload) {
    state.selectedEmployeeByQuery = payload;
  },

  // Slots
  setAvailableSlots(state, value) {
    let keys = Object.values(value).map((i) => ({ ...i[0] }));
    state.availableSlots.time.morning = [];
    state.availableSlots.time.afternoon = [];
    state.availableSlots.time.evening = [];
    state.availableSlots.error = null;

    state.availableSlots.slotsData = value;
    keys.map((item) => {
      if (item.time.split(":")[0] < 12) {
        state.availableSlots.time.morning.push({
          name: item.time_formatted,
          value: item.time
        });
      }
    });
    keys.map((item) => {
      if (item.time.split(":")[0] >= 12 && item.time.split(":")[0] < 17) {
        state.availableSlots.time.afternoon.push({
          name: item.time_formatted,
          value: item.time
        });
      }
    });
    keys.filter((item) => {
      if (item.time.split(":")[0] >= 17) {
        state.availableSlots.time.evening.push({
          name: item.time_formatted,
          value: item.time
        });
      }
    });
  },
  setAvailableSlotsErrors(state, value) {
    state.availableSlots.error = value;
  },
  setNextAvailableSlots(state, value) {
    let keys = Object.keys(value);
    state.nextAvailableSlots.time.morning = [];
    state.nextAvailableSlots.time.afternoon = [];
    state.nextAvailableSlots.time.evening = [];
    state.nextAvailableSlots.error = null;

    state.nextAvailableSlots.slotsData = value;
    keys.map((item) => {
      if (item.split(":")[0] < 12) {
        state.nextAvailableSlots.time.morning.push({ name: item });
      }
    });
    keys.map((item) => {
      if (item.split(":")[0] >= 12 && item.split(":")[0] < 17) {
        state.nextAvailableSlots.time.afternoon.push({ name: item });
      }
    });
    keys.filter((item) => {
      if (item.split(":")[0] >= 17) {
        state.nextAvailableSlots.time.evening.push({ name: item });
      }
    });
  },
  setNextAvailableSlotsErrors(state, value) {
    state.nextAvailableSlots.error = value;
  },
  setNextAvailableSlotsDate(state, value) {
    state.nextAvailableSlotsDate = value;
  },
  isLoadingNextAvailableSlots(state, value) {
    state.isLoadingNextAvailableSlots = value;
  },
  addSlotsToBasket(state, data) {
    state.chosenSlot.date = data.date;
    state.chosenSlot.time = data.time;
    state.chosenSlot.slots = data.slots;
  },
  setErrorSlot(state, { message, status }) {
    state.errorSlot.status = status;
    state.errorSlot.message = message;
  },
  setGlobalDateSlots(state, date) {
    state.globalDateSlots = date;
  },

  // Appointments
  setFutureAppointment(state, payload) {
    state.futureAppointments = payload;
  },
  setPastAppointment(state, payload) {
    state.pastAppointments = payload;
  },
  setFutureAppointmentData(state, payload) {
    state.futureAppointmentsData = payload;
  },
  setPastAppointmentData(state, payload) {
    state.pastAppointmentsData = payload;
  },
  setTabPastAppointment(state, value) {
    state.isPastAppointment = value;
  },

  // Payment
  setNote(state, note) {
    state.bookingNote = note;
  },
  setPaymentIntent(state, data) {
    state.paymentIntent = data;
  },
  setTotalPriceBooking(state, data) {
    state.totalPriceBooking = data;
  },
  setSelectedPaymentVariant(state, value) {
    state.selectedPaymentVariant = value;
  },
  setDeposit(state, payload) {
    state.deposit = payload;
  },

  setSelectedIds(state, payload) {
    state.selectedIds = {
      serviceId: payload.serviceId ? Number(payload.serviceId) : null,
      employeeId: payload.employeeId ? Number(payload.employeeId) : null
    };
  }

  // addService(state, { item, type }) {
  //   if (item.title === "Service error") {
  //     state.serviceError = true;
  //     state.serviceErrorName = item.title;
  //     return;
  //   }
  //   if (state.serviceError) {
  //     return;
  //   }
  //   if (type === 0) {
  //     state.services = [item];
  //     return;
  //   }
  //   state.services.push(item);
  // },
  // removeService(state, { item }) {
  //   state.services = state.services.filter((elem) => elem !== item);
  // },
  // updateStepCounter(state, step) {
  //   state.stepCounter = step;
  // },
  // setOtherEmployees(state, value) {
  //   state.isOtherEmployees = value;
  // }
};

<template>
  <button
    :type="ViType"
    :disabled="ViDisable"
    :class="`${variants[ViVariant]} ${ViClass} ${
      ViLoader ? 'btn-loader' : ''
    } ${ViFull ? 'btn-full' : ''} ${ViDisable ? 'btn-disabled' : ''}`"
    class="vi-btn"
    @click="click"
  >
    <slot></slot>
    <b-spinner v-if="ViLoader" small />
  </button>
</template>

<script>
import ViSpinner from "./ViSpinner";

export default {
  name: "ViButton",
  components: { ViSpinner },
  props: {
    ViType: {
      type: String,
      default: "button"
    },
    ViVariant: {
      type: String,
      required: false,
      default: "primary"
    },
    ViClass: {
      type: String,
      required: false,
      default: ""
    },
    ViDisable: {
      type: Boolean,
      required: false,
      default: false
    },
    ViFull: {
      type: Boolean,
      required: false,
      default: false
    },
    ViLoader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      variants: {
        primary: "btn-primary",
        default: "btn-primary",

        outline: "btn-primary-outline",
        "default-outline": "btn-primary-outline",
        "primary-outline": "btn-primary-outline",

        danger: "btn-danger",
        error: "btn-danger",

        white: "btn-white",
        light: "btn-white"
      }
    };
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>
<template>
  <div
    :class="[
      'form-group vi-form-group',
      !!error && !!isKeyPress && 'has-error'
    ]"
  >
    <label :data-end="lastLetterRed" :for="name" class="vi-form-label">{{
      label
    }}</label>
    <input
      :disabled="disabled"
      :id="name"
      :name="name"
      :type="type"
      :value="value"
      :class="`form-control vi-form-control ${
        !!error && !!isKeyPress && ' vi-form-control--error'
      }`"
      @input="onInput($event.target.value)"
      @blur="onBlur"
      @keypress="!!error && $emit('validate')"
    />
    <span
      v-if="passwordSwitch"
      :class="type === 'text' ? 'password-close' : ''"
      class="vi-form--password-switch"
      @click="passwordSwitch"
    />
    <p v-if="!!error && !!isKeyPress" class="vi-form-control--text-error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    type: { required: true },
    label: { required: true },
    name: { required: true },
    value: { required: true },
    error: { required: true },
    disabled: { default: false },
    lastLetterRed: {},
    passwordSwitch: {}
  },
  data() {
    return {
      isKeyPress: false
    };
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
      if (this.isKeyPress) {
        this.isKeyPress = false;
      }
    },
    onBlur() {
      this.isKeyPress = true;
      this.$emit("validate");
    }
  }
};
</script>
